import { Injectable, Injector } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth'

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private authService: NbAuthService
    ) {}

    addBearer(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        return this.authService.getToken()
            .pipe(
                switchMap( ( token: NbAuthJWTToken ): Observable<HttpRequest<any>> => {
                    if ( token ) {
                        let reqAuth
                        const tokenValue = token.getValue()
                        if ( token.isValid() ) {
                            if ( !req.url.match( 'world.json' ) ) {
                                reqAuth = req.clone({ setHeaders: { 'Authorization': 'Bearer ' + tokenValue } })
                            } else {
                                reqAuth = req.clone({ setHeaders: {
                                    'Access-Control-Allow-Origin': '*'
                                }})
                            }
                            return of( reqAuth )
                        } else if ( req.url.match( '/auth/logout' ) ) {
                            reqAuth = req.clone({ setHeaders: { 'Authorization': 'Bearer ' + tokenValue } })
                            return of( reqAuth )
                        } else {
                            return of( req )
                        }
                    } else {
                        return of( req )
                    }
                })
            )
    }

    /*checkToken( url, res: HttpEvent<any> ): Observable<HttpEvent<any>> {
        if ( url.match(/appauth\/api\/v2\/oauth\/token$/) ) {
            // @ts-ignore
            if ( res && res.body && res.body.access_token && res.body.refresh_token ) {
                // @ts-ignore
                res.body.token = {
                    // @ts-ignore
                    access_token: res.body.access_token,
                    // @ts-ignore
                    refresh_token: res.body.refresh_token
                }
            }
        }
        return of( res )
    }*/

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.addBearer( req ).pipe(
            switchMap( ( req2: HttpRequest<any> ) => {
                return next.handle(req2)
            })/*,
            switchMap( ( res: HttpEvent<any> ) => {
                return this.checkToken( req.url, res )
            })*/
        )
    }
}
