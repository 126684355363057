import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core'
import { NbSidebarService, NbSidebarState } from '@nebular/theme'
import { LayoutService } from '../../../@core/utils'
import { forkJoin } from 'rxjs'
import { take } from 'rxjs/operators'

@Component( {
    selector: 'ngx-one-column-two-menus-layout',
    styleUrls: ['./one-column-two-menus.layout.scss'],
    template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>
      <nb-sidebar [state]="menuFormat" [ngClass]="{'absolute-side-bar': menuFormat === 'compacted'}" class="menu-sidebar" [tag]="sideBar1" responsive (stateChange)="updateStateSizeBar($event, sideBar1)">
        <ng-content select="[menu1]"></ng-content>
      </nb-sidebar>

      <nb-sidebar *ngIf="!showContainerOnMenu1 && subMenu && subMenu.length > 0" #sideBar2 class="menu-sidebar menu-sidebar2" [ngClass]="{'compacted': sideBar2State === 'compacted'}" [tag]="sideBar2" responsive [compactedBreakpoints]="['xs', 'is', 'sm', 'md']" (stateChange)="updateBar2StateChange($event)">
         <div *ngIf="subMenuTitle && sideBar2State !== 'compacted'" class="subMenuTitle">{{ subMenuTitle }}</div>
         <ng-content select="[menu2]"></ng-content>
      </nb-sidebar>

      <nb-layout-column [ngClass]="{'nb-layout-collapsed': sideBar2State}">
        <div #containerOnMenu1 *ngIf="menuFormat === 'compacted' && showContainerOnMenu1" (click)="toggleSidebar()" class="containerOnMenu1"></div>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `
} )
export class OneColumnTwoMenusLayoutComponent implements OnInit, AfterViewInit, AfterViewChecked {
    menuFormat: NbSidebarState
    showContainerOnMenu1 = false
    loaded = false
    sideBar1: string = 'menu-sidebar'
    sideBar2: string = 'menu-sidebar2'
    sideBar2State: string = 'expanded'

    @ViewChild('containerOnMenu1') containerOnMenu1: ElementRef
    @Input() set showMenu2 ( val: boolean ) {
        this.menuFormat = val ? 'compacted' : 'expanded'
        this.showContainerOnMenu1 = !val
    }
    @Input() subMenuTitle: string
    @Input() subMenu: any[]

    constructor (
        private sidebarService: NbSidebarService,
        private layoutService: LayoutService,
        private renderer: Renderer2,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit () {
        this.sidebarService.onToggle().subscribe( ( data: { tag: string } ) => {
            if ( this.loaded ) {
                if (data.tag === this.sideBar1) {
                    if ( typeof this.showContainerOnMenu1 === 'undefined' ) { this.showContainerOnMenu1 = false}
                    this.showContainerOnMenu1 = !this.showContainerOnMenu1
                }
            } else {
                this.loaded = true
            }
        })

        this.layoutService.onChangeLayoutSize().subscribe(() => {
            forkJoin([
                this.sidebarService.getSidebarState(this.sideBar1).pipe(take(1)),
                this.sidebarService.getSidebarResponsiveState(this.sideBar1).pipe(take(1))
            ]).subscribe(([state, responsiveState]) => {
                if (state === 'expanded' && this.containerOnMenu1) {
                    this.renderer.addClass(this.containerOnMenu1.nativeElement, 'shrinked')
                }
            })
        })
    }

    ngAfterViewInit () {
        setTimeout( () => {
            if ( this.menuFormat === 'compacted' ) {
                this.toggleSidebar()
            }
        }, 1)
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, this.sideBar1)
        this.layoutService.changeLayoutSize()
        return false
    }

    updateStateSizeBar(state: 'expanded' | 'collapsed' | 'compacted', sideBarName: string): any {
        if (sideBarName === this.sideBar1 && state === 'expanded' && !this.showContainerOnMenu1) {
            this.sidebarService.compact(this.sideBar1)
        }
    }

    updateBar2StateChange(state: 'expanded' | 'collapsed' | 'compacted'): void {
        this.sideBar2State = state
    }
}
