import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { LocalStorageService } from '../../../services/utils/local-storage.service'
import { LoginService } from '../../../services/utils/login.service'
import { NbAuthJWTToken, NbTokenService } from '@nebular/auth'

@Component({
  selector: 'ngx-sso-redirect',
  templateUrl: './sso-redirect.component.html',
  styleUrls: ['./sso-redirect.component.scss']
})
export class NgxSsoRedirectComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private nbTokenService: NbTokenService,
    ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((query) => {
      const ssoInfo = this.localStorageService.get('ssoInfo')
      this.localStorageService.set('ssoInfo', null)

      if (ssoInfo
        && ssoInfo.subDomain
        && ssoInfo.data
        && ssoInfo.data.ssoLogin
        && ssoInfo.data.ssoLogin.ssoAccessTokenQueryName
        && ssoInfo.data.ssoLogin.redirectUrl
        && query[ssoInfo.data.ssoLogin.ssoAccessTokenQueryName]
      ) {
        this.loginService.login(query[ssoInfo.data.ssoLogin.ssoAccessTokenQueryName], ssoInfo.subDomain, ssoInfo.data.ssoLogin.redirectUrl).subscribe((response: any) => {
          if (response && response.jwtToken) {
            this.nbTokenService.set(new NbAuthJWTToken(response.jwtToken, 'sso', new Date()))
            this.router.navigate(['/auth/after-login'])
          }
          else {
            //console.log('Error on login', response)
            this.router.navigate(['/auth/login'])
          }
        })
      } else {
        console.log('Error on ssoAccessTokenQueryName', query)
        this.router.navigate( ['/auth/login'] )
      }
    })
  }
}
