import { Component, Inject, Input, OnInit } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { timeDisplayConfig } from '../../services/utils/time-config.service'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { timeDisplayConfigs } from './time-config-data.component'
import { Langs } from 'lb-types/dist'

export type TimeConfigType = 'DATE' | 'TIME_LEFT' | 'NB_GLOBAL_PEOPLE' | 'NB_GLOBAL_TICKET' | 'NB_ENDPOINT_PEOPLE' | 'NB_ENDPOINT_TICKET' | 'DATE-TIME_LEFT' | 'DATE-NB_GLOBAL_PEOPLE' | 'DATE-NB_GLOBAL_TICKET' | 'DATE-NB_ENDPOINT_PEOPLE' | 'DATE-NB_ENDPOINT_TICKET' | 'TIME_LEFT-NB_GLOBAL_PEOPLE' | 'TIME_LEFT-NB_GLOBAL_TICKET' | 'TIME_LEFT-NB_ENDPOINT_PEOPLE' | 'TIME_LEFT-NB_ENDPOINT_TICKET'

@Component({
  selector: 'ngx-time-config',
  templateUrl: './time-config.component.html',
  styleUrls: ['./time-config.component.scss']
})
export class TimeConfigComponent implements OnInit {

  @Input() timeConfig: timeDisplayConfig
  @Input() availableTypeConditions: TimeConfigType[]
  @Input() textsToShow: {name: string, title: Langs}[] = []
  @Input() showDateConfiguration: boolean = true
  @Input() openConditionsByDefault: boolean
  @Input() dataType: 'viewSwitch' | 'firstAvailability' | 'availabilities' | 'ticket' | 'ticketOnlyBooked'
  public configType: TimeConfigType
  public conditionsOpened: boolean = false

  constructor(
      protected ref: NbDialogRef<TimeConfigComponent>,
      @Inject(L10N_LOCALE) public locale: L10nLocale,
  ) { }

  ngOnInit () {
    if ( typeof ( this.openConditionsByDefault ) === 'boolean' ) {
      this.conditionsOpened = this.openConditionsByDefault
    }
    for ( const cond of this.timeConfig.cond ) {
      if ( cond.type === 'DATE' ) {
        if ( !this.configType || this.configType === 'DATE' ) { this.configType = 'DATE' }
        if ( this.configType === 'TIME_LEFT' ) { this.configType = 'DATE-TIME_LEFT' }
        if ( this.configType === 'NB_GLOBAL_PEOPLE' ) { this.configType = 'DATE-NB_GLOBAL_PEOPLE' }
        if ( this.configType === 'NB_GLOBAL_TICKET' ) { this.configType = 'DATE-NB_GLOBAL_TICKET' }
        if ( this.configType === 'NB_ENDPOINT_PEOPLE' ) { this.configType = 'DATE-NB_ENDPOINT_PEOPLE' }
        if ( this.configType === 'NB_ENDPOINT_TICKET' ) { this.configType = 'DATE-NB_ENDPOINT_TICKET' }

      } if ( cond.type === 'TIME_LEFT' ) {
        if ( !this.configType || this.configType === 'TIME_LEFT' ) { this.configType = 'TIME_LEFT' }
        if ( this.configType === 'NB_GLOBAL_PEOPLE' ) { this.configType = 'TIME_LEFT-NB_GLOBAL_PEOPLE' }
        if ( this.configType === 'NB_GLOBAL_TICKET' ) { this.configType = 'TIME_LEFT-NB_GLOBAL_TICKET' }
        if ( this.configType === 'NB_ENDPOINT_PEOPLE' ) { this.configType = 'TIME_LEFT-NB_ENDPOINT_PEOPLE' }
        if ( this.configType === 'NB_ENDPOINT_TICKET' ) { this.configType = 'TIME_LEFT-NB_ENDPOINT_TICKET' }

      } else if ( cond.type === 'NB_GLOBAL_PEOPLE' ) {
        if ( !this.configType || this.configType === 'NB_GLOBAL_PEOPLE' ) { this.configType = 'NB_GLOBAL_PEOPLE' }
      } else if ( cond.type === 'NB_GLOBAL_TICKET' ) {
        if ( !this.configType || this.configType === 'NB_GLOBAL_TICKET' ) { this.configType = 'NB_GLOBAL_TICKET' }
      } else if ( cond.type === 'NB_ENDPOINT_PEOPLE' ) {
        if ( !this.configType || this.configType === 'NB_ENDPOINT_PEOPLE' ) { this.configType = 'NB_ENDPOINT_PEOPLE' }
      } else if ( cond.type === 'NB_ENDPOINT_TICKET' ) {
        if ( !this.configType || this.configType === 'NB_ENDPOINT_TICKET' ) { this.configType = 'NB_ENDPOINT_TICKET' }
      }
    }
  }

  selectConfigType( type ) {
    if ( type !== this.configType ) {
      this.configType = type
      if ( type === 'DATE' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configDate_' + this.dataType].cond ) ) }
      if ( type === 'TIME_LEFT' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configTimeLeft_'  + this.dataType].cond ) ) }
      if ( type === 'NB_GLOBAL_PEOPLE' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configNbGlobalPeople_'  + this.dataType].cond ) ) }
      if ( type === 'NB_GLOBAL_TICKET' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configNbGlobalTickets_'  + this.dataType].cond ) ) }
      if ( type === 'NB_ENDPOINT_PEOPLE' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configNbEndpointPeople_'  + this.dataType].cond ) ) }
      if ( type === 'NB_ENDPOINT_TICKET' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configNbEndpointTickets_'  + this.dataType].cond ) ) }
      if ( type === 'TIME_LEFT-NB_GLOBAL_PEOPLE' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configTimeLeftAndNbGlobalPeople_'  + this.dataType].cond ) ) }
      if ( type === 'TIME_LEFT-NB_GLOBAL_TICKET' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configTimeLeftAndNbGlobalTickets_'  + this.dataType].cond ) ) }
      if ( type === 'TIME_LEFT-NB_ENDPOINT_PEOPLE' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configTimeLeftAndNbEndpointPeople_'  + this.dataType].cond ) ) }
      if ( type === 'TIME_LEFT-NB_ENDPOINT_TICKET' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configTimeLeftAndNbEndpointTickets_'  + this.dataType].cond ) ) }
      if ( type === 'DATE-TIME_LEFT' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configDateAndTimeLeft_'  + this.dataType].cond ) ) }
      if ( type === 'DATE-NB_GLOBAL_PEOPLE' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configDateAndNbGlobalPeople_'  + this.dataType].cond ) ) }
      if ( type === 'DATE-NB_GLOBAL_TICKET' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configDateAndNbGlobalTickets_'  + this.dataType].cond ) ) }
      if ( type === 'DATE-NB_ENDPOINT_PEOPLE' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configDateAndNbEndpointPeople_'  + this.dataType].cond ) ) }
      if ( type === 'DATE-NB_ENDPOINT_TICKET' ) { this.timeConfig.cond = JSON.parse( JSON.stringify( timeDisplayConfigs['configDateAndNbEndpointTickets_'  + this.dataType].cond ) ) }

      if ( this.dataType === 'viewSwitch' ) {
        for ( const i in this.timeConfig.cond ) {
          this.timeConfig.cond[i].texts = null
        }
      }
    }
  }

  setTime ( t ) {
    console.log( t )
  }

  cancel() {
    this.ref.close()
  }

  submit() {
    this.ref.close( this.timeConfig )
  }

}
