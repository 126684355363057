import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { LbTimeService } from 'lb-utils-front/dist'

@Component({
  selector: 'ngx-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {

  @Input() set duration (nb: number) {
    if (typeof(nb) !== 'undefined') {
      this.tmpDuration = nb
      this.initVal()
    }
  }
  @Input() display: 'portrait' | 'landscape' = 'portrait'
  @Input() format: string[] = [ 'hour', 'minute', 'second' ]
  @Input() textFormat: 'full' | 'short' = 'full'
  @Input() size: 'small' | 'normal' | 'big' = 'normal'
  @Input() addText: string = ''
  @Output() onChange: EventEmitter<number> = new EventEmitter<number>()

  public tmpDuration: any
  public splittedDuration: any

  constructor( private lbTimeService: LbTimeService ) { }

  ngOnInit(): void {
    this.initVal()
  }

  initVal(): void {
    this.splittedDuration = this.lbTimeService.msToTime( this.tmpDuration )
    this.splittedDuration = JSON.parse( JSON.stringify( this.splittedDuration ) )
  }

  durationValueChange() {
    const ticketDuration = this.lbTimeService.timeToMs( this.splittedDuration )
    this.splittedDuration = this.lbTimeService.msToTime( ticketDuration )
    this.onChange.emit( ticketDuration )
  }

}
