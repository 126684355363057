import { Component, Inject, Input } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'ngx-qrcode-popup',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent {

  @Input() url: string

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      public ref: NbDialogRef<QrcodeComponent>
  ) { }

  cancel() {
    this.ref.close()
  }

  submit() {
    this.ref.close( true )
  }

}
