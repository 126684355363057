import { Component, EventEmitter, Inject, Input, Output, TemplateRef } from '@angular/core'
import { LbTranslationService, LbUtilsService } from 'lb-utils-front/dist'
import { NbDialogService } from '@nebular/theme'
import { SelectDialogComponent } from '../../modals/select/select-dialog.component'
import * as _ from 'lodash'
import { LocalStorageService } from '../../services/utils/local-storage.service'
import { SelectMultiComponent } from '../../modals/select-multi/select-multi.component'
import { RoleAndAccessRoles, UserService } from '../../services/user.service'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { Langs } from 'lb-types/dist'

const GLOBAL_LOCAL_KEY = 'lb_datatable_config_'

export interface RoleAndAccessRolesDatatable extends RoleAndAccessRoles {
  idFieldName?: string
}

export interface DatatableActionConfiguration {
  title?: string, // the name of the action
  security?: RoleAndAccessRolesDatatable, // filter the display with the role of the user
  id: string, // uniq action ID for the datatable
  icon?: string, // the icon from eva icons lib
  disabled?: boolean, // disable the action (the action is shown but disabled)
  show?: Function // a function that take the line data and wait a boolean in return to know if we must display or not the action. Works only on inline more action
}

export interface DatatableSettings {
  id?: string, // The uniq ID : used to save user pref in local storage
  title?: string, // The title displayed in the card title
  dataTableInNbCard?: boolean, // Indicate if the datatable must be an nbCard (TRUE) or if the table must be alone (FALSE)
  defaultNumberElementDisplayed?: number, // Number by default of elements to display on each page
  availableNumberOfElementsToDisplay?: number[], // The list of available number possibilities of elements displayed by page
  columns: { // Each columns correspond to one column of the result
    id: string | null, // an uniq id for the table
    type?: 'number' | 'boolean' | 'string' | 'langs', // The type is necessary to sort and filter. Langs is an object {fr_FR: '', en_US: ''}
    field?: string, // the virtual link to the value of the field for sort and filter. Example : "data.name.value" for object {data: {name: {value: 'toto'}}}
    valueTransformation?: Function // If you want to transformate the value before sort and filter you can extract the data that you want
    width: number, // By default the display used col of bootstrap. The total size is 12. You must dispatch your data to have a total of 12
    title?: string, // The title of the column
    textAlign?: 'center' | 'left' | 'right',
    sortAllowed?: boolean, // Indicate if the column can be sorted
    search?: boolean, // Indicate if we can search values in the column
    searchType?: 'text' | 'select' | 'textAndSelect' | 'selectState', // Indicate witch type of search we can have : text = free input field ; select = a select auto generated with the column values ; textAndSelect = an input text and the possibility to display a select
    selectLangs?: {[value: string] : Langs} // Overide the default langs value for the select search
    selectSearch?: {text: string, value: any}[], // You can force the select search with your values
    columnSum?: string, // only internal use
    searchValue?: string, // only internal use
    indexPosition?: string // only internal use
  }[],
  groupOfColumns?: { // If you want to add another group of title line you can add a second header line that group columns
    title?: string, // The title of the group of column
    width: number, // By default the display used col of bootstrap. The total size is 12. You must dispatch your data to have a total of 12
    numberOfColumn: number, // The number of column that the group of column must cover
    textAlign?: 'center' | 'left' | 'right',
    columns?: any[] // only internal use
  }[],
  defaultExpanded?: boolean, // You can show only the title without the data if tou don't want to display all the data everyTime. This field indicate if the table must be expanded by default (TRUE) or not (FALSE)
  defaultSort?: {id: string, sort: 'ASC' | 'DESC'}[], // An array of id of column in the id field and the sort direction
  onlyOneColumnSort?: boolean, // If you want to allow only one column sort
  messageOnEmptyData?: string, // The text displayed when the data are empty
  messageOnEmptySearchResult?: string, // The text displayed when there are no results on an search
  rowClickable?: boolean, // If you want that the user can click on a row and fire an onRowClick event with the item inside
  showHeader?: boolean, // If you want to show the header line or directly the data
  showPagination?: boolean, // If you want to show the pagination
  showNumberOfElementsToDisplay?: boolean, // If you want to show the number of element actually displayed and the total number of elements
  showTotalNumberOfElements?: boolean, // Show the number total of elements
  showTotalNumberOfElementsInTitle?: boolean // Show the total number of element near the title of the datatable
  showMultipleSelection?: boolean, // If you want to show a checkbox before each line to make multiple line actions
  showMoreActions?: boolean, // If you want to show an icon at the end of each line to make line actions
  showSumLine?: boolean // If you want to show a final line at the end of the table to sum all the number type columns
  showMinimiseButton?: boolean // If you want to show an icone on the line of the global title to expand or hide the data
  multipleSelectionKey?: string, // The data key used to differenciate line (for line action and multiple line action). Example : data.id
  multipleLineAction?: DatatableActionConfiguration[], // The list of possible actions for multiple line action. The security field is the list of roles than can access the option
  selectedItems?: string[], // The list of keys of the selecctedLine
  moreAction?: DatatableActionConfiguration[] // the list of possible actions for one line. The security field is the list of roles than can access the option
}

@Component({
  selector: 'ngx-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent {

  public allData: any
  public globalSettings: DatatableSettings = {
    id : null,
    title : 'Title',
    dataTableInNbCard: true,
    defaultNumberElementDisplayed : 25,
    availableNumberOfElementsToDisplay : [ 10, 25, 50, 100 ],
    rowClickable: false,
    showHeader: true,
    columns : [],
    groupOfColumns : [],
    defaultExpanded : true,
    defaultSort : [],
    onlyOneColumnSort: false,
    messageOnEmptyData : 'No Data',
    messageOnEmptySearchResult : 'No result',
    multipleSelectionKey: 'id',
    showPagination : true,
    showNumberOfElementsToDisplay : false,
    showTotalNumberOfElements : true,
    showTotalNumberOfElementsInTitle: false,
    showMultipleSelection : true,
    showMoreActions : false,
    showSumLine : false,
    showMinimiseButton : false,
    multipleLineAction : [],
    selectedItems : [],
    moreAction : []
  }
  public dataToDisplay: []
  public pageIndex: { min: number, max: number, current: number} = { min: 1, max: 1, current: 1}
  public elemsToDisplay: number = 25
  public showPagination: boolean = true
  public paginationPages: { name: string, value: number | null, disabled: boolean, selected: boolean }[] = []
  public elemenDisplayedNumber: {start: number, end: number, total: number} = {start: 0, end: 0, total: 0}
  public selectedSort: {id: string, sort: 'ASC' | 'DESC'}[] = []
  public isExpanded: boolean = true
  public allSelected: boolean = false
  public selectedItem: string = ''
  public selectedItems: string[] = []
  public selectedItemLineActions: DatatableActionConfiguration[] = []
  public dataFiltered: boolean = false
  public globalSettingsData: DatatableSettings
  private settingsInit = false

  @Input() columnContent: TemplateRef<{item: any}>[]
  @Output() onRowClick: EventEmitter<object> = new EventEmitter<object>() // Event on a line
  @Output() onRowAction: EventEmitter<object> = new EventEmitter<object>() // Event on a line
  @Output() onMultipleRowAction: EventEmitter<object> = new EventEmitter<object>() // Event on multiple rows
  @Output() onItemsSelected: EventEmitter<object> = new EventEmitter<object>() // Event each time an item is selected or unselected : { action: 'add' | 'remove', actionIds: string[], selectedItems: string[] }, actionIds = the list of IDs that has been added or removed, selectedItems = the current liste of selected items

  @Input( 'data' )
  set data ( value: any ) {
    this.allData = value
    this.reloadDisplay()
  }
  @Input( 'settings' )
  set settings ( value: DatatableSettings ) {
    if ( value && !this.settingsInit ) {
      this.settingsInit = true
      if ( typeof ( value.id ) !== 'undefined' ) { this.globalSettings.id = value.id }
      if ( typeof ( value.title ) !== 'undefined' ) { this.globalSettings.title = value.title }
      if ( typeof ( value.defaultNumberElementDisplayed ) === 'number' && value.defaultNumberElementDisplayed > 0 ) {
        this.globalSettings.defaultNumberElementDisplayed = value.defaultNumberElementDisplayed
        this.elemsToDisplay = value.defaultNumberElementDisplayed
      }
      if ( typeof ( value.availableNumberOfElementsToDisplay ) !== 'undefined' ) { this.globalSettings.availableNumberOfElementsToDisplay = value.availableNumberOfElementsToDisplay }
      if ( typeof ( value.showHeader ) !== 'undefined' ) { this.globalSettings.showHeader = value.showHeader }
      if ( typeof ( value.rowClickable ) !== 'undefined' ) { this.globalSettings.rowClickable = value.rowClickable }
      if ( typeof ( value.dataTableInNbCard ) !== 'undefined' ) { this.globalSettings.dataTableInNbCard = value.dataTableInNbCard }
      if ( typeof ( value.columns ) !== 'undefined' ) {
        this.globalSettings.columns = value.columns
        for ( const k in this.globalSettings.columns ) {
          if ( typeof ( this.globalSettings.columns[k][ 'type' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'type' ] = 'string' }
          if ( typeof ( this.globalSettings.columns[k][ 'width' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'width' ] = 1 }
          if ( typeof ( this.globalSettings.columns[k][ 'title' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'title' ] = '-' }
          if ( typeof ( this.globalSettings.columns[k][ 'search' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'search' ] = true }
          if ( typeof ( this.globalSettings.columns[k][ 'searchType' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'searchType' ] = 'text' }
          if ( typeof ( this.globalSettings.columns[k][ 'valueTransformation' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'valueTransformation' ] = null }
          if ( typeof ( this.globalSettings.columns[k][ 'textAlign' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'textAlign' ] = 'left' }
          if ( typeof ( this.globalSettings.columns[k][ 'sortAllowed' ] ) === 'undefined' ) { this.globalSettings.columns[k][ 'sortAllowed' ] = true }

          if ( this.globalSettings.columns[k][ 'type' ] === 'boolean' || (this.globalSettings.columns[k].selectSearch && this.globalSettings.columns[k].selectSearch.length > 0) ) {
            this.globalSettings.columns[k][ 'searchValue' ] = 'all'
          } else {
            this.globalSettings.columns[k][ 'searchValue' ] = ''
          }
          this.globalSettings.columns[k][ 'indexPosition' ] = k
        }
      }
      if ( typeof ( value.groupOfColumns ) !== 'undefined' ) {
        this.globalSettings.groupOfColumns = value.groupOfColumns
        for ( const k in this.globalSettings.groupOfColumns ) {
          if ( typeof ( this.globalSettings.groupOfColumns[k][ 'width' ] ) === 'undefined' ) { this.globalSettings.groupOfColumns[k][ 'width' ] = 1 }
          if ( typeof ( this.globalSettings.groupOfColumns[k][ 'numberOfColumn' ] ) === 'undefined' ) { this.globalSettings.groupOfColumns[k][ 'numberOfColumn' ] = 1 }
          if ( typeof ( this.globalSettings.groupOfColumns[k][ 'title' ] ) === 'undefined' ) { this.globalSettings.groupOfColumns[k][ 'title' ] = '-' }
          if ( typeof ( this.globalSettings.groupOfColumns[k][ 'textAlign' ] ) === 'undefined' ) { this.globalSettings.groupOfColumns[k][ 'textAlign' ] = 'left' }
        }
        this.initGLobalColumn('global')
      }
      if ( typeof ( value.defaultExpanded) !== 'undefined' ) {
        this.globalSettings.defaultExpanded = value.defaultExpanded
        this.isExpanded = value.defaultExpanded
      }
      if ( typeof ( value.defaultSort ) !== 'undefined' ) {
        this.globalSettings.defaultSort = value.defaultSort
        this.selectedSort = value.defaultSort
      }
      if ( typeof ( value.onlyOneColumnSort ) !== 'undefined' ) {
        this.globalSettings.onlyOneColumnSort = value.onlyOneColumnSort
      }
      if ( typeof ( value.showPagination ) !== 'undefined' ) {
        this.globalSettings.showPagination = value.showPagination
        this.showPagination = value.showPagination
      }
      if ( typeof ( value.multipleSelectionKey ) !== 'undefined' ) { this.globalSettings.multipleSelectionKey = value.multipleSelectionKey }
      if ( typeof ( value.messageOnEmptyData ) !== 'undefined' ) { this.globalSettings.messageOnEmptyData = value.messageOnEmptyData }
      if ( typeof ( value.messageOnEmptySearchResult ) !== 'undefined' ) { this.globalSettings.messageOnEmptySearchResult = value.messageOnEmptySearchResult }
      if ( typeof ( value.showNumberOfElementsToDisplay ) !== 'undefined' ) { this.globalSettings.showNumberOfElementsToDisplay = value.showNumberOfElementsToDisplay }
      if ( typeof ( value.showTotalNumberOfElements ) !== 'undefined' ) { this.globalSettings.showTotalNumberOfElements = value.showTotalNumberOfElements }
      if ( typeof ( value.showTotalNumberOfElementsInTitle ) !== 'undefined' ) { this.globalSettings.showTotalNumberOfElementsInTitle = value.showTotalNumberOfElementsInTitle }
      if ( typeof ( value.showMultipleSelection ) !== 'undefined' ) { this.globalSettings.showMultipleSelection = value.showMultipleSelection }
      if ( typeof ( value.showMoreActions ) !== 'undefined' ) { this.globalSettings.showMoreActions = value.showMoreActions }
      if ( typeof ( value.showSumLine ) !== 'undefined' ) { this.globalSettings.showSumLine = value.showSumLine }
      if ( typeof ( value.showMinimiseButton ) !== 'undefined' ) { this.globalSettings.showMinimiseButton = value.showMinimiseButton }
      if ( typeof ( value.multipleLineAction ) !== 'undefined' ) {
        this.globalSettings.multipleLineAction = []
        for ( const action of value.multipleLineAction ) {
          const secyrityList = action.security && action.security.roles && action.security.roles.length > 0 ? action.security.roles : ['all']
          const allAccessRoles = action.security && action.security.allAccessRoles && action.security.allAccessRoles.length > 0 ? action.security.allAccessRoles : null
          if ( this.userService.operatorHasAccessFromObjectAndUrl( secyrityList, allAccessRoles ) ) {
            this.globalSettings.multipleLineAction.push( action )
          }
        }
        if ( this.globalSettings.multipleLineAction.length === 0 ) {
          this.globalSettings.showMultipleSelection = false
        }
      }
      if ( typeof ( value.selectedItems ) !== 'undefined' ) { 
        this.globalSettings.selectedItems = value.selectedItems 
      }
      if ( typeof ( value.moreAction ) !== 'undefined' ) {
        this.globalSettings.moreAction = []
        for ( const action of value.moreAction ) {
          const secyrityList = action.security && action.security.roles && action.security.roles.length > 0 ? action.security.roles : ['all']
          const allAccessRoles = action.security && action.security.allAccessRoles && action.security.allAccessRoles.length > 0 ? action.security.allAccessRoles : null
          if ( this.userService.operatorHasAccessFromObjectAndUrl( secyrityList, allAccessRoles ) ) {
            this.globalSettings.moreAction.push( action )
          }
        }
        if ( this.globalSettings.moreAction.length === 0 ) {
          this.globalSettings.showMoreActions = false
        }
      }

      this.selectedItems = this.globalSettings.selectedItems
      this.onSelectedItemChange()

      const localStorageSettings = this.localStorageService.get( GLOBAL_LOCAL_KEY + this.globalSettings.id )
      if ( localStorageSettings ) {
        this.isExpanded = localStorageSettings.isExpanded
        this.selectedSort = localStorageSettings.selectedSort
        this.selectedItems = localStorageSettings.selectedItems
        this.elemsToDisplay = localStorageSettings.elemsToDisplay
        this.pageIndex = localStorageSettings.pageIndex
        for ( const k in this.globalSettings.columns) {
          if ( this.globalSettings.columns[k].id && typeof( localStorageSettings.columns[ this.globalSettings.columns[k].id ] ) !== 'undefined' ) {
            this.globalSettings.columns[k].searchValue = localStorageSettings.columns[ this.globalSettings.columns[k].id ]
          }
        }
      }
    } else {
      this.selectedItems = []
      this.onSelectedItemChange()
    }

    this.globalSettingsData = _.cloneDeep( this.globalSettings )
    this.reloadDisplay()
    this.initGLobalColumn('data')
  }

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private lbTranslationService: LbTranslationService,
      private lbUtilsService: LbUtilsService,
      private dialogService: NbDialogService,
      private userService: UserService,
      private localStorageService: LocalStorageService
  ) {
    this.globalSettingsData = _.cloneDeep( this.globalSettings ) 
  }

  saveTableConfig () {
    if ( this.globalSettings && this.globalSettings.id && this.globalSettings.id.length > 0 ) {
      const settings = {
        selectedSort: this.selectedSort,
        selectedItems: this.selectedItems,
        elemsToDisplay: this.elemsToDisplay,
        pageIndex: this.pageIndex,
        isExpanded: this.isExpanded,
        columns: {}
      }
      for ( const k in this.globalSettings.columns) {
        settings.columns[ this.globalSettings.columns[k].id ] = this.globalSettings.columns[k].searchValue
      }
      this.localStorageService.set( GLOBAL_LOCAL_KEY + this.globalSettings.id, settings )
    }
  }

  initGLobalColumn( name ) {
    if ( name === 'global' ) {
      const tmpColumns = _.cloneDeep( this.globalSettings.columns )
      let tmpIndex: number = 0
      for ( const k in this.globalSettings.groupOfColumns ) {
        const tmpColumnsToAdd = tmpColumns.slice( tmpIndex, (tmpIndex + this.globalSettings.groupOfColumns[k][ 'numberOfColumn' ]) )
        this.globalSettings.groupOfColumns[k].columns = tmpColumnsToAdd
        tmpIndex += this.globalSettings.groupOfColumns[k][ 'numberOfColumn' ]
      }
    } else if ( name === 'data' ) {
      const tmpColumns = _.cloneDeep( this.globalSettingsData.columns )
      let tmpIndex: number = 0
      for ( const k in this.globalSettingsData.groupOfColumns ) {
        const tmpColumnsToAdd = tmpColumns.slice( tmpIndex, (tmpIndex + this.globalSettingsData.groupOfColumns[k][ 'numberOfColumn' ]) )
        this.globalSettingsData.groupOfColumns[k].columns = tmpColumnsToAdd
        tmpIndex += this.globalSettingsData.groupOfColumns[k][ 'numberOfColumn' ]
      }
    }
  }

  setPage( nb: number ) {
    if ( nb >= this.pageIndex.min && nb <= this.pageIndex.max && nb !== this.pageIndex.current ) {
      this.pageIndex.current = nb
      this.reloadDisplay()
    }
  }

  setElemsToDisplay() {
    const possibilities = this.globalSettings.availableNumberOfElementsToDisplay.map( (obj) => { return { 'value': obj, 'langs': { 'fr_FR': obj + ''} } } )
    this.dialogService.open(SelectDialogComponent, { context: { possibilities: possibilities, title: 'Nombre d\'éléments à afficher', showSearch: false } })
        .onClose.subscribe(nb => { if ( nb ) { this.elemsToDisplay = parseInt(nb, 10); this.reloadDisplay() } } )
  }

  getObjectValue( item, key ) {
    return this.lbUtilsService.getObjectValue( item, key )
  }

  clickOnRow(item, $event ) {
    if ( this.globalSettings.rowClickable ) {
      const clickNotInACheckbox = ! $event.target.closest('.nb-checkbox-container')
      if( clickNotInACheckbox ){
        const key = this.lbUtilsService.getObjectValue( item, this.globalSettings.multipleSelectionKey )
        const tmpIndex = this.selectedItems.findIndex( ( elem ) => elem === key )
        if ( tmpIndex >= 0 ) {
          this.selectedItems.splice( tmpIndex, 1 )
          this.onRowClick.emit(item)
        } else {
          this.selectedItems.push( key )
          this.onRowClick.emit(item)
        }
        $event.stopPropagation()
      }
    }
    /* this.selectedItems = JSON.parse( JSON.stringify( this.selectedItems ) )
    this.onSelectedItemChange() */
  }

  itemSelected( item ) {
    const key = this.lbUtilsService.getObjectValue( item, this.globalSettings.multipleSelectionKey )
    return (this.selectedItems.findIndex( ( elem ) => elem === key ) >= 0)
  }

  toggleSelectedItem( item ) {
    const key = this.lbUtilsService.getObjectValue( item, this.globalSettings.multipleSelectionKey )
    const tmpIndex = this.selectedItems.findIndex( ( elem ) => elem === key )
    if ( tmpIndex >= 0 ) {
      this.selectedItems.splice( tmpIndex, 1 )
      this.emitSelectedItems( 'remove', [ key ]  )
    } else {
      this.selectedItems.push( key )
      this.emitSelectedItems( 'add', [ key ]  )
    }
    this.selectedItems = JSON.parse( JSON.stringify( this.selectedItems ) )
    this.onSelectedItemChange()
  }

  onSelectedItemChange () {
    if ( this.selectedItems && this.allData ) {
      this.allSelected = this.selectedItems.length === Object.keys( this.allData ).length
      this.saveTableConfig()
    }
  }

  selectAll() {
    this.selectedItems = []
    const keys = []
    if ( !this.allSelected ) {

      let data = JSON.parse( JSON.stringify( this.allData ) )
      data = this.filterArray( data )
      data = this.sortArray( data )

      for ( const k in data ) {
        if ( k in data ) {
          const id = data[k][this.globalSettings.multipleSelectionKey]
          this.selectedItems.push( id )
          keys.push( id )
        }
      }
    }
    this.emitSelectedItems( 'add', keys  )
    this.allSelected = this.selectedItems.length === Object.keys( this.allData ).length
  }

  emitSelectedItems( action: string, ids: any[]) {
    this.onItemsSelected.emit( { action: action, actionIds: ids, selectedItems: this.selectedItems } )
  }

  selectItem( item: any ) {
    this.selectedItem = this.getObjectValue( item, this.globalSettings.multipleSelectionKey )
    this.selectedItemLineActions = this.getLineActions( item )
  }

  getLineActions( item ) {
    const res = []
    for( const action of this.globalSettings.moreAction ) {
      const secyrityList = action.security && action.security.roles && action.security.roles.length > 0 ? action.security.roles : ['all']
      const allAccessRoles = action.security && action.security.allAccessRoles && action.security.allAccessRoles.length > 0 ? action.security.allAccessRoles : null
      const id = action.security && action.security.idFieldName && action.security.idFieldName.length > 0 ? this.getObjectValue( this.selectedItem, action.security.idFieldName ) : null
      if ( this.userService.operatorHasAccessFromObjectAndUrl( secyrityList, allAccessRoles, id ) ) {
        if ( typeof action.show === 'function' ) {
          if ( action.show( JSON.parse( JSON.stringify( item ) ) ) ) {
            res.push( action )
          }
        } else {
          res.push( action )
        }
      }
    }
    return res
  }

  doAction( id: string, disabled: boolean ) {
    if ( !disabled ) {
      this.onRowAction.emit( { actionId: id, idItem: this.selectedItem } )
    }
  }

  doMultipleAction( id: string, disabled: boolean ) {
    if ( !disabled ) {
      this.onMultipleRowAction.emit( { actionId: id, idItems: this.selectedItems } )
    }
  }

  setSearch( id, event, type ) {
    let value = event
    if ( type === 'input') {
      value = event.target.value
    }
    for ( const c of this.globalSettings.columns) {
      if ( c.id === id ) {
        c.searchValue = value
        this.initGLobalColumn('data')
        this.reloadDisplay()
        break
      }
    }
  }

  searchSelect( id ) {
    let selected = []
    let column = null
    for ( const c of this.globalSettings.columns) {
      if ( c.id === id ) {
        selected = c.searchValue.split('|')
        selected = _.uniqWith( _.filter( selected, (o) => { return o && o.length && o.length > 0 } ), _.isEqual )

        if (c.type === 'number') { 
          selected = selected.map( (o) => { return parseInt(o, 10) } )
        }

        column = c
        break
      }
    }
    const possibilities = this.getColumnValues( id )
    if (column.selectLangs) {
      for (const p of possibilities) {
        if (column.selectLangs[p.value]) {
          p.langs = column.selectLangs[p.value]
        }
      }
    }
    
    this.dialogService.open(SelectMultiComponent, { context: { possibilities: possibilities, title: 'Choisir des valeurs à afficher', selected: selected } })
        .onClose.subscribe(res => { if ( res ) { 
          this.setSearch( id, res.join('|'), 'value' ) } } )
  }

  setExpanded() {
    this.isExpanded = !this.isExpanded
    this.saveTableConfig()
  }

  changeSort( id ) {
    let sortAllowed: boolean = false
    for ( const k in this.globalSettings.columns ) {
      if ( this.globalSettings.columns[k].id === id ) {
        sortAllowed = this.globalSettings.columns[k].sortAllowed
      }
    }
    if ( sortAllowed ) {
      let found = false
      let needToDelete: boolean = false
      let index: any = null
      for ( const k in this.selectedSort ) {
        const s = this.selectedSort[k]
        if ( s.id === id ) {
          index = k
          found = true
          if ( s.sort === 'ASC') {
            s.sort = 'DESC'
          } else {
            needToDelete = true
          }
          break
        }
      }



      if (this.globalSettings.onlyOneColumnSort) {
        if (found) {
          this.selectedSort = [this.selectedSort[index]]
        }
        else {
          this.selectedSort = [ {id: id, sort: 'ASC'} ]
        }
        if (needToDelete) {
          this.selectedSort = []
        }
        
      }
      else {
        if ( !found ) {
          this.selectedSort.push( {id: id, sort: 'ASC'} )
        } else if ( needToDelete ) {
          this.selectedSort.splice(index, 1 )
        }
      }
     
      this.reloadDisplay()
    }
  }

  isSorted( id, sort ) {
    for ( const s of this.selectedSort ) {
      if ( s.id === id ) {
        return sort === s.sort
      }
    }
    return false
  }

  reloadDisplay () {
    const numberElemToDisplay = this.globalSettings.showPagination ? this.elemsToDisplay : 99999999
    this.dataToDisplay = []
    this.paginationPages = []
    let data
    if ( this.allData ) {
      data = JSON.parse( JSON.stringify( this.allData ) )
    }

    if ( data ) {
      data = this.filterArray( data )
      data = this.sortArray( data )
      this.calculSum( data )
      this.generatePagination( data, numberElemToDisplay )

      const startIndex = (this.pageIndex.current - 1) * numberElemToDisplay
      let endIndex = ((this.pageIndex.current) * numberElemToDisplay) - 1
      endIndex = endIndex > (data.length - 1) ? (data.length - 1) : endIndex
      this.elemenDisplayedNumber.start = (startIndex + 1) <= data.length ? (startIndex + 1) : data.length
      this.elemenDisplayedNumber.end = endIndex + 1
      this.elemenDisplayedNumber.total = data.length
      this.dataToDisplay = data.slice( startIndex, (endIndex + 1) )
      this.saveTableConfig()
    }
  }

  filterArray( data ) {
    const filters = []
    for ( const c of this.globalSettings.columns ) {
      if ( c.search && (
          (
              (
                  c.type === 'boolean'
                  || (c.selectSearch && c.selectSearch.length > 0)
              )
              && c.searchValue !== 'all'
              && (
                  typeof( c.searchValue ) !== 'string'
                || (typeof( c.searchValue ) === 'string' && c.searchValue.length > 0)
              )
          )
          || ( ( c.type !== 'boolean' && (!c.selectSearch || c.selectSearch.length <= 0) ) && c.searchValue.length > 0) )
      ) {
        filters.push( {field: c.field, type: c.type, value: (c.searchValue + '').toLowerCase(), valueTransformation: c.valueTransformation} )
      }
    }

    if ( filters.length > 0 ) {
      this.dataFiltered = true
      const res = []
      for ( const obj of data ) {
        let allMatch = true
        for ( const f of filters ) {
          const value: any = this.getColumnValue( obj, f.field, f.type, f.valueTransformation )
          if ( f.type === 'boolean' ) {
            if ( (f.value === 'yes' && value !== true) || (f.value === 'no' && value !== false) ) {
              allMatch = false
              break
            }
          } else if ( f.type === 'number' ) {
            const filterValue = f.value.split('|')
            allMatch = false
            for ( const v of filterValue ) {
              if ( parseInt( v, 10 ) === value ) {
                allMatch = true
                break
              }
            }
            if ( !allMatch ) {
              break
            }
          } else {
            if ( typeof( value ) === 'string' ) {
              if ( !value.toLowerCase().match( f.value ) ) {
                allMatch = false
                break
              }
            } else {
              if ( value !== f.value ) {
                allMatch = false
                break
              }
            }
          }
        }
        if ( allMatch ) {
          res.push( obj )
        }
      }
      return res
    } else {
      this.dataFiltered = false
      return data
    }
  }

  getColumnValues( columnId ) {
    const res = []
    for ( const c of this.globalSettings.columns ) {
      if ( c.id === columnId ) {
        const data = JSON.parse( JSON.stringify( this.allData ) )
        for ( const obj of data ) {
          let value: any = this.getColumnValue( obj, c.field, c.type, c.valueTransformation )
          res.push( { 'value': value, 'langs': value } )
        }
        break
      }
    }
    const uniqRes = _.uniqWith( res, _.isEqual )
    return uniqRes.sort( (a, b) => {
      const textA: string = this.lbTranslationService.translateFromObject( a.langs, {lang: this.locale.language} )
      const textB: string = this.lbTranslationService.translateFromObject( b.langs, {lang: this.locale.language} )
      return this.lbUtilsService.compareStrings(textA, textB)
    })
  }

  getColumnValue( obj, field, type, valueTransformation ) {
    // 'number' | 'boolean' | 'string' | 'langs'
    let value = this.lbUtilsService.getObjectValue( obj, field )
    if ( type === 'boolean' ) {
      value = !!value
    } else if ( type === 'number' ) {
      value = parseInt( value, 10 )
    } else if ( type === 'langs' ) {
      value = value['fr_FR'] ? value['fr_FR'] : value['en_US'] ? value['en_US'] : '-'
      value = this.lbUtilsService.toString( value )
    } else {
      value = this.lbUtilsService.toString( value )
    }
    if ( valueTransformation ) {
      value = valueTransformation( value )
    }
    if ( typeof( value ) === 'string' ) {
      value = value.toLowerCase()
    }
    return value
  }

  sortArray( data ) {
    if ( data ) {
      if ( this.selectedSort.length > 0 ) {
        const sortKeys = []
        for ( const s of this.selectedSort ) {
          for ( const c of this.globalSettings.columns ) {
            if ( s.id === c.id ) {
              sortKeys.push( {field: c.field, sort: s.sort, type: c.type} )
            }
          }
        }
        if ( sortKeys.length > 0 ) {
          data = data.sort( ( a, b ) => {
            for ( const k of sortKeys ) {
              let aValue = this.lbUtilsService.getObjectValue( a, k.field )
              let bValue = this.lbUtilsService.getObjectValue( b, k.field )

              let sortRes
              if ( k.type === 'string' ) {
                aValue = this.lbUtilsService.toString( aValue )
                bValue = this.lbUtilsService.toString( bValue )
                sortRes = this.lbUtilsService.compareStrings(aValue, bValue)
              } else if ( k.type === 'number' ) {
                aValue = parseInt( aValue, 10 )
                bValue = parseInt( bValue, 10 )
                sortRes = aValue - bValue
              } else if ( k.type === 'boolean' ) {
                aValue = aValue === true || aValue === 'true'
                bValue = bValue === true || bValue === 'true'
                sortRes = aValue - bValue
              } else if ( k.type === 'langs' ) {
                aValue = aValue['fr_FR'] ? aValue['fr_FR'] : aValue['en_US'] ? aValue['en_US'] : '-'
                bValue = bValue['fr_FR'] ? bValue['fr_FR'] : bValue['en_US'] ? bValue['en_US'] : '-'
                aValue = aValue.toLowerCase()
                bValue = bValue.toLowerCase()
                sortRes = this.lbUtilsService.compareStrings(aValue, bValue)
              }

              if ( sortRes >= 1 ) {
                return !k.sort || k.sort === 'ASC' ? 1 : -1
              } else if (sortRes <= -1 ) {
                return !k.sort || k.sort === 'ASC' ? -1 : 1
              }
            }
            return 0
          } )
        }
      }
    }
    return data
  }

  calculSum (data) {
    if ( this.globalSettingsData.showSumLine && this.globalSettingsData.columns && this.globalSettingsData.columns.length > 0 ) {
      for ( const k in this.globalSettingsData.columns ) {
        if ( this.globalSettingsData.columns[k].type === 'number' ) {
          let sum = 0
          if ( data ) {
            const field = this.globalSettingsData.columns[k].field
            for ( const j in data ) {
              sum += parseInt( this.lbUtilsService.getObjectValue( data[j], field ), 10 )
            }
          }
          this.globalSettingsData.columns[k].columnSum = sum + ''
        } else if ( this.globalSettingsData.columns[k].type === 'boolean' ) {
          let valid = 0
          let total = 0
          if ( data ) {
            for ( const j in data ) {
              total += 1
              const tmpValue = this.lbUtilsService.getObjectValue( data[j], this.globalSettingsData.columns[k].field )
              valid += tmpValue === true || tmpValue === 'true' ? 1 : 0
            }
          }
          this.globalSettingsData.columns[k].columnSum = valid + '/' + total
        } else {
          this.globalSettingsData.columns[k].columnSum = '-'
        }
      }
      this.initGLobalColumn('data')
    }
  }

  generatePagination (data, numberElemToDisplay) {
    if ( this.globalSettings.showPagination ) {
      if ( data.length > 0 ) {
        this.pageIndex.max = Math.floor( (data.length - 1) / numberElemToDisplay ) + 1
      } else {
        this.pageIndex.max = 1
      }

      if ( this.pageIndex.current > this.pageIndex.max ) {
        this.pageIndex.current = this.pageIndex.max
      }

      if ( this.pageIndex.max === 1 ) {
        this.showPagination = false
      } else {
        this.showPagination = true
      }

      this.paginationPages.push( { name: '«', value: this.pageIndex.current > this.pageIndex.min ? this.pageIndex.min : null, disabled: this.pageIndex.current <= this.pageIndex.min, selected: false })
      this.paginationPages.push( { name: '<', value: this.pageIndex.current > this.pageIndex.min ? this.pageIndex.current - 1 : null, disabled: this.pageIndex.current <= this.pageIndex.min, selected: false })

      if ( this.pageIndex.current === this.pageIndex.max && (this.pageIndex.current - 3) >= this.pageIndex.min ) {
        this.paginationPages.push( { name: (this.pageIndex.current - 3) + '', value: (this.pageIndex.current - 3), disabled: false, selected: false })
      }
      if ( (this.pageIndex.current + 1) >= this.pageIndex.max && (this.pageIndex.current - 2) >= this.pageIndex.min ) {
        this.paginationPages.push( { name: (this.pageIndex.current - 2) + '', value: (this.pageIndex.current - 2), disabled: false, selected: false })
      }
      if ( this.pageIndex.current - 1 >= this.pageIndex.min ) {
        this.paginationPages.push( { name: (this.pageIndex.current - 1) + '', value: (this.pageIndex.current - 1), disabled: false, selected: false })
      }
      this.paginationPages.push( { name: this.pageIndex.current + '', value: this.pageIndex.current, disabled: false, selected: true })
      if ( this.pageIndex.current + 1 <= this.pageIndex.max ) {
        this.paginationPages.push( { name: (this.pageIndex.current + 1) + '', value: (this.pageIndex.current + 1), disabled: false, selected: false })
      }
      if ( this.pageIndex.current + 2 <= this.pageIndex.max ) {
        this.paginationPages.push( { name: (this.pageIndex.current + 2) + '', value: (this.pageIndex.current + 2), disabled: false, selected: false })
      }
      if ( this.pageIndex.current === this.pageIndex.min && this.pageIndex.current + 3 <= this.pageIndex.max ) {
        this.paginationPages.push( { name: (this.pageIndex.current + 3) + '', value: (this.pageIndex.current + 3), disabled: false, selected: false })
      }

      this.paginationPages.push( { name: '>', value: this.pageIndex.current < this.pageIndex.max ? this.pageIndex.current + 1 : null, disabled: this.pageIndex.current >= this.pageIndex.max, selected: false })
      this.paginationPages.push( { name: '»', value: this.pageIndex.current < this.pageIndex.max ? this.pageIndex.max : null, disabled: this.pageIndex.current >= this.pageIndex.max, selected: false })
    }
  }

}
