import { Component, Inject, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import * as _ from 'lodash'
import * as ms from 'ms'
import { CompaniesPlacesQueuesService } from '../../services/default-resources/companies-places-queues.service'
import { QueuesConfigurationService } from '../../services/default-resources/queues-configuration.service'
import { LbUtilsService } from 'lb-utils-front/dist'
import { QueuesService } from '../../services/default-resources/queues.service'
import { NbDialogRef, NbDialogService } from '@nebular/theme'
import { LoadingService } from '../../services/utils/loading.service'
import { AppBookingQueueConfiguration, AppTimetableQueueConfiguration, Company, Place, Queue } from 'lb-types'
import { DeveloperAccountService } from '../../services/developer-resources/developer-account.service'
import { CacheService } from '../../services/utils/cache.service'
import { PlacesService } from '../../services/default-resources/places.service'
import { Langs, PlaceConfiguration } from 'lb-types/dist'
import { LocalCRUDRes } from '../../interfaces/http/http'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'ngx-mdals-migration-to-v2',
  templateUrl: './migration-to-v2.component.html',
  styleUrls: ['./migration-to-v2.component.scss']
})
export class MigrationToV2Component implements OnInit {

  @Input() queuesId: string[]
  public loaded: boolean = false
  public queueId: string
  public placesEdited: { [placeId: string]: Boolean} = {}
  public queues: { [queueId: string]: Queue} = {}
  public tmpQueues: { [queueId: string]: Queue} = {}
  public places: { [placeId: string]: Place} = {}
  public tmpPlaces: { [placeId: string]: Place} = {}
  public companies: { [companyId: string]: Company} = {}
  public placeConfigurations: { [placeId: string]: PlaceConfiguration} = {}
  public tmpPlaceConfigurations: { [placeId: string]: PlaceConfiguration} = {}
  public queueConfiguration: AppBookingQueueConfiguration
  public tmpQueueConfiguration: AppBookingQueueConfiguration
  public preBookingDelay: number
  public isAlgoV2: boolean = true
  public errors = {
    'emptyQueues': false,
    'manyCompanies': false
  }
  public currentMigrateQueue: Langs | null = null
  migrationPercent: number = 0
  migrationAllDone: boolean = false
  queuesErrors: Langs[] = []

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
      private placesService: PlacesService,
      private queuesConfigurationService: QueuesConfigurationService,
      private developerAccountService: DeveloperAccountService,
      private lbUtilsService: LbUtilsService,
      private cacheService: CacheService,
      private queuesService: QueuesService,
      private dialogService: NbDialogService,
      private route: ActivatedRoute,
      private router: Router,
      private loadingService: LoadingService,
      protected ref: NbDialogRef<MigrationToV2Component>
  ) {}

  async ngOnInit (): Promise<void> {
    if ( this.queuesId && this.queuesId.length > 0 ) {
      this.loadingService.show()
      for ( const queueId of this.queuesId ) {
        this.queues[ queueId ] = this.companiesPlacesQueuesService.getQueueById( queueId )
        this.tmpQueues[ queueId ] = _.cloneDeep( this.queues[ queueId ] )
        const placeId = this.queues[ queueId ].placeId
        this.places[ placeId ] = this.companiesPlacesQueuesService.getPlaceById( placeId )
        this.tmpPlaces[ placeId  ] = _.cloneDeep( this.places[ placeId  ] )
        this.placeConfigurations[ placeId ] = await this.placesService.getPlaceConfiguration( placeId ).toPromise()
        this.tmpPlaceConfigurations[ placeId  ] = _.cloneDeep( this.placeConfigurations[ placeId  ] )
        const companyId = this.places[ placeId ].companyId
        this.companies[ companyId ] = this.companiesPlacesQueuesService.getCompanyById( companyId )
      }

      this.queuesConfigurationService.getAppBookingQueueConfiguration( this.queuesId[ 0 ] ).subscribe( ( queueConfig: AppBookingQueueConfiguration | null ) => {
        this.loadingService.hide()
        if ( queueConfig ) {
          this.queueConfiguration = _.cloneDeep( queueConfig )
          this.tmpQueueConfiguration = _.cloneDeep( queueConfig )
          this.initNewQueueConfig()

          this.isAlgoV2 = false // this.queueConfiguration && this.queueConfiguration.algoVersion && this.queueConfiguration.algoVersion === 2
          this.errors.manyCompanies = Object.keys( this.companies ).length > 1
          this.loaded = true
        }
        else {
          this.router.navigate( ['/config/miscellaneous/404'] )
        }
      }, ( err ) => {
        this.loadingService.hide()
        console.log( 'Error while getting queue and queue config of the queue' )
        console.log( err )
      } )

    } else {
      this.errors.emptyQueues = true
    }
  }

  initNewQueueConfig () {
    const conf = this.queueConfiguration
    // @ts-ignore
    const bookingType = conf && conf.queueType && conf.queueType.bookingType ? conf.queueType.bookingType : 'FIRST_AVAILABLE_TIMESLOT'
    // @ts-ignore
    const bookingWithoutBookedForType = conf && conf.queueType && conf.queueType.bookingWithoutBookedForType ? conf.queueType.bookingWithoutBookedForType : 'FIFO'
    // @ts-ignore
    const fixedSearch = conf && conf.queueType && conf.queueType.params && conf.queueType.params.fixedSearch ? conf.queueType.params.fixedSearch : 0
    // @ts-ignore
    const floatingSearch = conf && conf.queueType && conf.queueType.params && conf.queueType.params.floatingSearch ? conf.queueType.params.floatingSearch : 0
    // @ts-ignore
    const maxBookingDelay = conf && conf.maxBookingDelay ? conf.maxBookingDelay : 0
    const maxSearch = Math.max(fixedSearch, floatingSearch, maxBookingDelay)
    const numberOfPeriod = Math.floor(maxSearch / ms('24h')) + 1
    // @ts-ignore
    const infiniteAvailabilityOnCurrentPeriod = conf && conf.tForceOnFullQueue ? 'FIFO' : 'NONE'
    // @ts-ignore
    const fifoOnlyOnCurrentPeriod = conf && conf.ticketOnNextDays ? false : true
    // @ts-ignore
    this.preBookingDelay = conf && conf.preBookingDelay ? conf.preBookingDelay : 0
    // @ts-ignore
    this.tmpQueueConfiguration.pushes.sendClientSms = 'useTicket'

    this.tmpQueueConfiguration.queueType = {
      booking: {
        request: {
          type: 'FIFO'
        },
        type: bookingType,
        serverBookingType: bookingWithoutBookedForType
      },
      availabilities: {
        type: 'LINE',
        calculate: {
          period: {
            type: '24H',
            numberOfPeriod: numberOfPeriod,
            shiftValue: ''
          },
          feature: {
            minimumDelayBeforeNextAvailability: '0m',
            infiniteAvailabilityOnCurrentPeriod: infiniteAvailabilityOnCurrentPeriod
          }
        },
        display: {
          visibility: {
            type: 'FULL_DAYS',
            on: ''
          },
          feature: {
            fifoOnlyOnCurrentPeriod: fifoOnlyOnCurrentPeriod,
            appointmentOnlyOnNextPeriods: false,
            delayToBookedTicket: '0m'
          }
        }
      }
    }
    this.tmpQueueConfiguration.actions = {
      cleanTickets: 'PLACE_CLOSING',
      resetBookingClosedForToday: 'PLACE_CLOSING'
    }
    this.tmpQueueConfiguration.logger = {
      'level' : 'warn'
    }
    this.tmpQueueConfiguration.algoVersion = 2

    if ( !this.tmpQueueConfiguration.labelGeneratorVersion ) {
      this.tmpQueueConfiguration.labelGeneratorVersion = 1
      this.tmpQueueConfiguration.labelGeneratorConfig = {
        'updateLabelOnReport' : false,
        'step' : 86400000,
        'skippedNumbers' : [],
        'skippedLetters' : []
      }
    }
  }

  goTo ( name ) {
    if ( name === 'queues' ) {
      this.router.navigate( ['/config/settings/queues/'] )
    }
  }

  setQueueTypeDelayToBookedTicket ( val: number ) {
    this.tmpQueueConfiguration.queueType.availabilities.display.feature.delayToBookedTicket = ms( val )
  }

  setBookingType ( value ) {
    this.tmpQueueConfiguration.queueType.booking.type = value
    if ( value === 'USER_DEFINED_TIMESLOT' || value === 'MIXED' ) {
      this.tmpQueueConfiguration.queueType.availabilities.display.feature.delayToBookedTicket = '2m'
    } else {
      this.tmpQueueConfiguration.queueType.availabilities.display.feature.delayToBookedTicket = '0m'
    }
  }

  async migrate() {
    const nbSteps = this.queuesId.length * 7
    let success: boolean = true
    let nbQueue = 0

    for ( const queueId of this.queuesId ) {
      this.currentMigrateQueue = this.queues[ queueId ].name
      const placeId = this.queues[ queueId ].placeId

      let queueSuccess: boolean
      let res1: boolean
      let res2: LocalCRUDRes
      let res3: LocalCRUDRes
      let res4: LocalCRUDRes
      let res5: LocalCRUDRes
      let res6: LocalCRUDRes
      let res7: LocalCRUDRes
      try {
        this.migrationPercent = Math.floor( (( (nbQueue * 7) + 1) / nbSteps) * 100 )
        res1 = await this.developerAccountService.addOrRemoveQueueToLinebertyDeveloperAccount( 'add', [ 'terminal', 'endpoint' ], [{ companyId: this.tmpPlaces[ placeId ].companyId, placeId: placeId, queueId: queueId}] ).toPromise()

        this.migrationPercent = Math.floor( (( (nbQueue * 7) + 2) / nbSteps) * 100 )
        if ( typeof( this.placesEdited[ placeId ] ) === 'undefined' || this.placesEdited[ placeId ] === false ) {
          this.tmpPlaces[ placeId ].excludeTriggers = false
          res2 = await this.placesService.editPlaces([{placeId: placeId, placeSet: this.tmpPlaces[ placeId ]}]).toPromise()

          this.tmpPlaceConfigurations[ placeId ].bot.triggers = { margin: { before: 0, after: 0 }, activate: true, everyTime: true }
          this.migrationPercent = Math.floor( (( (nbQueue * 7) + 3) / nbSteps) * 100 )
          res3 = await this.placesService.editPlaceConfiguration([{placeId: placeId, placeConfigurationSet: this.tmpPlaceConfigurations[ placeId ]}]).toPromise()
          this.placesEdited[ placeId ] = true
        } else {
          res2 = { success: true }
          res3 = { success: true }
        }

        this.tmpQueues[ queueId ].excludeTriggers = false
        this.migrationPercent = Math.floor( (( (nbQueue * 7) + 4) / nbSteps) * 100 )
        res4 = await this.queuesService.editQueues([{queueId: queueId, queueSet: this.tmpQueues[ queueId ]}]).toPromise()

        this.migrationPercent = Math.floor( (( (nbQueue * 7) + 5) / nbSteps) * 100 )
        res5 = await this.queuesConfigurationService.editAppBookingQueueConfiguration([{queueId: queueId, queueConfigurationSet: this.tmpQueueConfiguration}]).toPromise()

        const data: Partial<AppTimetableQueueConfiguration> = { bookingOpening: { type: 'endpoints', openBeforeEndpointOf: ms( this.preBookingDelay ) } }
        this.migrationPercent = Math.floor( (( (nbQueue * 7) + 6) / nbSteps) * 100 )
        res6 = await this.queuesConfigurationService.editAppTimetableQueueConfiguration([{queueId: queueId, queueConfigurationSet: data}]).toPromise()

        this.migrationPercent = Math.floor( (( (nbQueue * 7) + 7) / nbSteps) * 100 )
        res7 = await this.cacheService.deleteKeys([queueId, placeId], true).toPromise()

        queueSuccess = res1 && res2.success && res3.success && res4.success && res5.success && res6.success && res7.success
      } catch (err) {
        console.log('Unknown error while migrating the queue : ', {queueId, placeId, queue: this.queues[queueId]})
        console.log(err)
        queueSuccess = false
      }

      if ( !queueSuccess ) {
        console.log('Error on migration for the queue : ', {queueId, placeId, queue: this.queues[queueId]})
        this.queuesErrors.push( this.queues[queueId].name )
      }
      success = success && queueSuccess

      nbQueue++
    }
    this.migrationAllDone = true

    if ( success ) {
      this.loadingService.hide( { success: true, title: 'Migration des files', text: 'Les files ont bien été migré' } )
    } else {
      this.loadingService.hide( { success: false, title: 'Migration des files', text: 'Une erreur est survenue. Au moins une file n\'a pas été migré correctement'  } )
    }
  }

  close() {
    this.ref.close( true )
  }
}
