<div class="row">
    <div class="col-12" *ngIf="tree && tree.length > 0">

        <div class="treeTitle"> {{title}} </div>

        <ng-container *ngFor="let row of tree">
            <div (click)="expand(row.id)" *ngIf="isVisible(row.kind, row.ids)" [ngClass]="{companyRow: row.kind === 'company', placeRow: row.kind === 'place', queueRow: row.kind === 'queue', addCompanyRow: row.kind === 'add-company', addPlaceRow: row.kind === 'add-place', addQueueRow: row.kind === 'add-queue' }" class="rowTree">
                <div class="clickable" *ngIf="row.kind === 'company'">
                    <nb-icon class="text-success big-icon" icon="building" pack="font-awesome"></nb-icon>
                    <span class="text-link" (click)="goTo('company', row.ids.companyId)">Entreprise : {{row.name}}</span>
                    <nb-icon [ngxCopyToClipBoardTitle]="'ID de l\'entreprise'"[ngxCopyToClipBoard]="row.ids.companyId" class="iconCopyData" icon="copy" pack="font-awesome"></nb-icon>
                    <nb-icon *ngIf="isExpended(row.kind, row.id).show && !isExpended(row.kind, row.id).expended" class="chevronIcon" icon="chevron-up" pack="font-awesome"></nb-icon>
                    <nb-icon *ngIf="isExpended(row.kind, row.id).show && isExpended(row.kind, row.id).expended" class="chevronIcon" icon="chevron-down" pack="font-awesome"></nb-icon>
                    <nb-icon class="text-danger" *ngIf="edition" (click)="deleteRessource('company', row.ids.companyId)" icon="trash-2-outline"></nb-icon>
                </div>

                <div class="clickable" *ngIf="row.kind === 'place'">
                    <nb-icon class="text-info big-icon" icon="home" pack="font-awesome"></nb-icon>
                    <span class="text-link" (click)="goTo('place', row.ids.placeId)">Lieu : {{row.name}}</span>
                    <nb-icon [ngxCopyToClipBoardTitle]="'ID du lieu'"[ngxCopyToClipBoard]="row.ids.placeId" class="iconCopyData" icon="copy" pack="font-awesome"></nb-icon>
                    <nb-icon *ngIf="isExpended(row.kind, row.id).show && !isExpended(row.kind, row.id).expended" class="chevronIcon" icon="chevron-up" pack="font-awesome"></nb-icon>
                    <nb-icon *ngIf="isExpended(row.kind, row.id).show && isExpended(row.kind, row.id).expended" class="chevronIcon" icon="chevron-down" pack="font-awesome"></nb-icon>
                    <nb-icon class="text-danger" *ngIf="edition" (click)="deleteRessource('place', row.ids.companyId, row.ids.placeId)" icon="trash-2-outline"></nb-icon>
                </div>

                <ng-container *ngIf="row.kind === 'queue'">
                    <nb-icon class="text-warning queueIconTree" icon="map-marker-alt" pack="font-awesome"></nb-icon>
                    <span class="text-link" (click)="goTo('queue', row.ids.queueId)">File : {{row.name}}</span>
                    <nb-icon [ngxCopyToClipBoardTitle]="'ID de la file'"[ngxCopyToClipBoard]="row.ids.queueId" class="iconCopyData" icon="copy" pack="font-awesome"></nb-icon>
                    <nb-icon class="text-danger" *ngIf="edition" (click)="deleteRessource('queue', row.ids.companyId, row.ids.placeId, row.ids.queueId)" icon="trash-2-outline"></nb-icon>
                    <div class="editRoleContainer">
                        <div class="row">
                            <div class="col-lg-12">
                                <button *ngIf="!edition && selected" (click)="selectRoles( row.roles )" nbButton outline status="info" [size]="'small'">
                                    <nb-icon class="action-icon" icon="copy-outline"></nb-icon> Importer ces rôles
                                </button>
                                <button *ngIf="edition" (click)="importRoles( row.ids.companyId, row.ids.placeId, row.ids.queueId )" nbButton outline status="info" [size]="'small'">
                                    <nb-icon class="action-icon" icon="copy-outline"></nb-icon> Importer les rôles d'un autre profil
                                </button>
                                <br /><br />
                                <p class="bold underline"> Droits pour l'API de validation </p>
                                <div class="row">
                                    <div class="col-lg-4"> Changement d'état de ticket autorisé </div>
                                    <div class="col-lg-8">
                                        <ng-container *ngFor="let ticketStateTmpList of ticketStateListSliced">
                                            <div class="row">
                                                <div class="col-lg-3" *ngFor="let state of ticketStateTmpList"> {{state.name | lbTranslateFromObject:{lang: locale.language} }} </div>
                                                <div class="col-lg-3" *ngFor="let state2 of ticketStateTmpList">
                                                    <nb-select *ngIf="edition" [size]="'small'" (click)="changeSetStateRoles( $event, row.ids.companyId, row.ids.placeId, row.ids.queueId, state2.value )" class="select-multi selectAuthorisedState" multiple [(selected)]="row.roles.validation.authorizedSetState[ state2.value + '' ]">
                                                        <nb-option *ngFor="let state3 of ticketStateList" [value]="state3.value"> {{state3.name | lbTranslateFromObject:{lang: locale.language} }} </nb-option>
                                                    </nb-select>
                                                    <nb-select *ngIf="!edition" [size]="'small'" [disabled]="true" class="select-multi selectAuthorisedState" multiple [(selected)]="row.roles.validation.authorizedSetState[ state2.value + '' ]">
                                                        <nb-option *ngFor="let state3 of ticketStateList" [value]="state3.value"> {{state3.name | lbTranslateFromObject:{lang: locale.language} }} </nb-option>
                                                    </nb-select>
                                                </div>
                                            </div>
                                            <br />
                                        </ng-container>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-lg-6"> Possibilité de rappeler le client </div>
                                    <div class="col-lg-6">
                                        <nb-toggle *ngIf="edition" [(checked)]="row.roles.validation.recallAllowed" (checkedChange)="listChange()"></nb-toggle>
                                        <ng-container *ngIf="!edition">
                                            <p *ngIf="row.roles.validation.recallAllowed"> Oui </p>
                                            <p *ngIf="!row.roles.validation.recallAllowed"> Non </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-lg-6"> Droit d'accéder aux indicateurs de la file </div>
                                    <div class="col-lg-6">
                                        <nb-toggle *ngIf="edition" [(checked)]="row.roles.validation.showIndicators" (checkedChange)="listChange()"></nb-toggle>
                                        <ng-container *ngIf="!edition">
                                            <p *ngIf="row.roles.validation.showIndicators"> Oui </p>
                                            <p *ngIf="!row.roles.validation.showIndicators"> Non </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-lg-6"> Autoriser le mode délestage </div>
                                    <div class="col-lg-6">
                                        <nb-toggle *ngIf="edition" [(checked)]="row.roles.validation.hiddenModeAllowed" (checkedChange)="listChange()"></nb-toggle>
                                        <ng-container *ngIf="!edition">
                                            <p *ngIf="row.roles.validation.hiddenModeAllowed"> Oui </p>
                                            <p *ngIf="!row.roles.validation.hiddenModeAllowed"> Non </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-lg-6"> Possibilité de mettre en pause la file </div>
                                    <div class="col-lg-6">
                                        <nb-toggle *ngIf="edition" [(checked)]="row.roles.validation.pauseAllowed" (checkedChange)="listChange()"></nb-toggle>
                                        <ng-container *ngIf="!edition">
                                            <p *ngIf="row.roles.validation.pauseAllowed"> Oui </p>
                                            <p *ngIf="!row.roles.validation.pauseAllowed"> Non </p>
                                        </ng-container>
                                        <!--<nb-select [size]="'small'" [(selected)]="row.roles.validation.pauseAllowed" (selectedChange)="listChange()">
                                            <nb-option [value]="true">Oui</nb-option>
                                            <nb-option [value]="false">Non</nb-option>
                                        </nb-select>-->
                                    </div>
                                </div>

                            </div>
                            <!--<div class="col-lg-4">
                                <p class="bold underline"> Droits pour l'API de prise de tickets </p>
                            </div>
                            <div class="col-lg-4">
                                <p class="bold underline"> Droits pour l'API d'administration </p>
                            </div>-->
                        </div>
                    </div>

                </ng-container>

                <nb-select [size]="'small'" (click)="addCompany( $event, row.ids.list )" class="createSelect" *ngIf="row.kind === 'add-company'" [selected]="'-'" (selectedChange)="addRessource('company', $event)">
                    <nb-option  [value]="'-'">Ajouter une entreprise</nb-option>
                    <nb-option *ngFor="let company of row.ids.list" value="{{company.companyId}}">
                        {{company.langs | lbTranslateFromObject:{lang: locale.language} }}
                    </nb-option>
                </nb-select>

                <nb-select [size]="'small'" (click)="addPlace( $event, row.ids.companyId, row.ids.list )" class="createSelect" *ngIf="row.kind === 'add-place'" [selected]="'-'" (selectedChange)="addRessource('place', row.ids.companyId, $event)">
                    <nb-option [value]="'-'">Ajouter un lieu</nb-option>
                    <nb-option *ngFor="let place of row.ids.list" value="{{place.placeId}}">
                        {{place.langs | lbTranslateFromObject:{lang: locale.language} }}
                    </nb-option>
                </nb-select>

                <nb-select [size]="'small'" (click)="addQueue( $event, row.ids.companyId, row.ids.placeId, row.ids.list )" class="createQueueSelect" *ngIf="row.kind === 'add-queue'" [selected]="'-'" (selectedChange)="addRessource('queue', row.ids.companyId, row.ids.placeId, $event)">
                    <nb-option [value]="'-'">Ajouter une file</nb-option>
                    <nb-option *ngFor="let queue of row.ids.list" value="{{queue.queueId}}">
                        {{queue.langs | lbTranslateFromObject:{lang: locale.language} }}
                    </nb-option>
                </nb-select>
            </div>
        </ng-container>
    </div>
</div>
