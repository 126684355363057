import { Component, Inject, Input } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'ngx-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmDialogComponent {

  @Input() title: string
  @Input() text: string

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      protected ref: NbDialogRef<ConfirmDialogComponent>
  ) { }

  cancel() {
    this.ref.close()
  }

  submit() {
    this.ref.close( true )
  }

}
