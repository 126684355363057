import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslationComponent } from './translation.component'
import { NbCardModule, NbIconModule, NbInputModule, NbListModule, NbSelectModule } from '@nebular/theme'
import { RouterModule } from '@angular/router'
import { LbDirectivesModule, LbPipesModule } from 'lb-utils-front/dist'
import { MarkdownEditorModule } from '../markdown-editor/markdown-editor.module'
import { DirectivesModule } from '../../../directives/directives.module'

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NbInputModule,
        NbSelectModule,
        NbIconModule,
        NbCardModule,
        NbListModule,
        LbPipesModule,
        LbDirectivesModule,
        MarkdownEditorModule,
        DirectivesModule
    ],
    exports: [
        TranslationComponent
    ],
    declarations: [
        TranslationComponent
    ]
} )
export class TranslationModule {
}
