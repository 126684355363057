import { Injectable, NgZone } from '@angular/core'
import * as _ from 'lodash'
import { Langs, PaginatedTickets, TicketApiAdmin } from 'lb-types'
import { EMPTY, Observable, of, Subject, Subscription } from 'rxjs'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { expand, first, reduce, switchMap, takeWhile } from 'rxjs/operators'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill'
import { TicketApiBookingV2, TicketState } from "lb-types/dist";
import Timeout = NodeJS.Timeout;
import { sorInProgressTickets, sortDoneTickets, sortLateTickets, sortTickets } from 'lb-utils-front/dist'
import * as moment from 'moment-timezone'


const EventSource = NativeEventSource || EventSourcePolyfill
let currentsTicketsSse

const lateStates = [300, 400]
const doneStates = [600, 700]
const inProgressState = 500
const calledState = 200

interface TicketEvent {
    tickets: TicketApiAdmin[]
    number: number,
    people: number
}

interface TicketsEvent {
    current: TicketEvent,
    inProgress: TicketEvent,
    late: TicketEvent,
    done: TicketEvent,
    totalTicketsNumber: number,
    totalPeopleNumber: number
}

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    public ticketState = [
        { state: 0, apiState: 0, name: { 'fr_FR': 'Réservé' } },
        { state: 0.5, apiState: 50, name: { 'fr_FR': 'En cours de confirmation' } },
        { state: 1, apiState: 100, name: { 'fr_FR': 'Alerté' } },
        { state: 2, apiState: 200, name: { 'fr_FR': 'Appelé' } },
        { state: 3, apiState: 300, name: { 'fr_FR': 'En retard' } },
        { state: 4, apiState: 400, name: { 'fr_FR': 'Absent' } },
        { state: 5, apiState: 500, name: { 'fr_FR': 'En cours' } },
        { state: 6, apiState: 600, name: { 'fr_FR': 'Annulé' } },
        { state: 7, apiState: 700, name: { 'fr_FR': 'Terminé' } }
    ]

    public ticketsOfQueues: (TicketApiBookingV2 & { lastUpdate: string })[] = []


    private initialDoneTicket: (TicketApiBookingV2 & { lastUpdate: string })[] = []
    private ticketsSSE: (TicketApiBookingV2 & { lastUpdate: string })[] = []

    private sseSubscription: Subscription

    private reloadTicketTimeout: Timeout

    private currentDateTicketData: number = 0

    private currentTicketsEvent: Subject<any> = new Subject<any>()
    private inProgressTicketsEvent: Subject<any> = new Subject<any>()
    private lateTicketsEvent: Subject<any> = new Subject<any>()
    private doneTicketsEvent: Subject<any> = new Subject<any>()

    public $currentTicketsEvent: Observable<any> = new Observable<any>()
    public $inProgressTicketsEvent: Observable<any> = new Observable<any>()
    public $lateTicketsEvent: Observable<any> = new Observable<any>()
    public $doneTicketsEvent: Observable<any> = new Observable<any>()

    private ticketsEvent: Subject<TicketsEvent> = new Subject<TicketsEvent>()
    public $ticketsEvent: Observable<TicketsEvent> = new Observable<TicketsEvent>()

    public timezone = ''

    constructor(
        private httpService: HttpService,
        private configService: ConfigService,
        private zone: NgZone
    ) {
        this.$currentTicketsEvent = this.currentTicketsEvent.asObservable()
        this.$inProgressTicketsEvent = this.inProgressTicketsEvent.asObservable()
        this.$lateTicketsEvent = this.lateTicketsEvent.asObservable()
        this.$doneTicketsEvent = this.doneTicketsEvent.asObservable()
        this.$ticketsEvent = this.ticketsEvent.asObservable()
    }

    public getTicketState() {
        const res = []
        for (const k in this.ticketState) {
            res.push({ value: this.ticketState[k].state, name: this.ticketState[k].name })
        }
        return _.cloneDeep(res)
    }

    public getTicketApiState(): { value: number, name: Langs }[] {
        const res = []
        for (const k in this.ticketState) {
            res.push({ value: this.ticketState[k].apiState, name: this.ticketState[k].name })
        }
        return _.cloneDeep(res)
    }

    public createFakeTickets(queueId: string, ticketParam: any, nb: number): Observable<LocalCRUDRes> {

        let url = this.configService.httpUrl.tickets.createFakeTickets
        url = url.replace('{queueId}', queueId)
        return this.httpService.post(
            url, { ticketsParam: [{ ticketParam: ticketParam, number: nb }] }, null, null
        ).pipe(
            switchMap((httpRes: boolean) => {
                // TODO: reload queues tickets list
                if (httpRes === true) {
                    return of({ success: true })
                } else {
                    return of({ success: false })
                }
            })
        )
    }

    public treatBookingDemands(queueId: string): Observable<LocalCRUDRes> {

        let url = this.configService.httpUrl.tickets.treatBookingDemands
        url = url.replace('{queueId}', queueId)
        return this.httpService.post(
            url, null, null, null
        ).pipe(
            switchMap((httpRes: boolean) => {
                // TODO: reload queues tickets list
                if (httpRes === true) {
                    return of({ success: true })
                } else {
                    return of({ success: false })
                }
            })
        )
    }

    getTickets(queuesId: string[], start, end, page = 1, pageSize = 25) {

        return this.getTicketsInternal(queuesId, null, start, end, page, pageSize)
            .pipe(
                first(),
                expand((res: PaginatedTickets<TicketApiAdmin>) => {

                    return this.getTicketsInternal(queuesId, null, start, end, ++page, pageSize).pipe(first())

                }),
                takeWhile((res: PaginatedTickets<TicketApiAdmin>) => {
                    return res.lastPage === false
                }, true),
                reduce((acc, x: PaginatedTickets<TicketApiAdmin>, index) => {
                    return acc.concat(x.tickets)
                }, []),
                // switchMap( ( res: {[queuesId: string]: TicketApiBookingV2[]} ) => {
                //     return of( _.cloneDeep( res ) )
                // }),
            )
    }

    getDoneTicket(queuesId: string[], start, end, page = 1, pageSize = 25): Observable<TicketApiAdmin[]> {
        return this.getTicketsInternal(queuesId, [600, 700], start, end, page, pageSize)
            .pipe(
                first(),
                expand((res: PaginatedTickets<TicketApiAdmin>) => {

                    return this.getTicketsInternal(queuesId, [600, 700],start, end, ++page, pageSize).pipe(first())

                }),
                takeWhile((res: PaginatedTickets<TicketApiAdmin>) => {
                    return res.lastPage === false
                }, true),
                reduce((acc, x: PaginatedTickets<TicketApiAdmin>, index) => {
                    return acc.concat(x.tickets)
                }, []),
                // switchMap( ( res: {[queuesId: string]: TicketApiBookingV2[]} ) => {
                //     return of( _.cloneDeep( res ) )
                // }),
            )
    }

    getAdminTicket(ticketId: string, queueId: string): Observable<TicketApiBookingV2> {
        let url = this.configService.httpUrl.tickets.getAdminTicket
        url = url.replace('{queueId}', queueId).replace('{ticketId}', ticketId)
        return this.httpService.get(url, null, null).pipe(
            switchMap((res: TicketApiBookingV2) => {
                return of(res)
            }),
            first()
        )
    }



    getDaysWithTickets(queuesId: string[], start, end): Observable<{ [queuesId: string]: { [day: string]: number } }> {
        const query: any = { queueIds: queuesId }
        if (start) { query.startAt = start }
        if (end) { query.endAt = end }
        let url = this.configService.httpUrl.queues.getDaysWithTicket
        return this.httpService.get(
            url, query, null
        ).pipe(
            switchMap((res: { [queuesId: string]: { [day: string]: number } }) => {
                return of(_.cloneDeep(res))
            }),
            first()
        )
    }


    initSSE(queuesId: string[], timezone: string) {

        if (this.ticketsSSE.length > 0) {
            this.sseSubscription = this.getCurrentsTicketsSse(queuesId).subscribe((event) => {
                this.updateTicketFromEvent(event)
            })
            return this.reloadTicketToDisplay()
        }
        this.timezone = timezone
        if (this.sseSubscription) this.clear()
        this.sseSubscription = this.getTickets(queuesId, moment.tz(Date.now(), this.timezone).startOf('day').toISOString(), moment.tz(Date.now(), this.timezone).endOf('day').toISOString()).pipe(
            switchMap((res) => {
                this.updateTicketFromEvent({ resource: 'TICKET', actionCategory: 'ALL_DATA', data: { tickets: res } })
                return this.getCurrentsTicketsSse(queuesId)
            })
        )
       .subscribe((event) => {
            this.updateTicketFromEvent(event)
        })
        // this.getDoneTicket(queuesId, moment.tz(Date.now(), this.timezone).startOf('day').toISOString(), moment.tz(Date.now(), this.timezone).endOf('day').toISOString())
        // .subscribe((res) => {
        //     this.initialDoneTicket = res
        // })
    }

    closeSSE() {
        this.clear()
    }

    reloadTicketToDisplay() {

        const startofDay = moment.tz(Date.now(), this.timezone).startOf('day').valueOf()
        // Delete the ticket of the past day if the interface stay opened on many days
        if (startofDay !== this.currentDateTicketData) {
            this.currentDateTicketData = startofDay

            const newTicketArray = []
            for (const t of this.ticketsSSE) {
                if (moment.tz(t.timeline.initialBookedFor, moment.ISO_8601, this.timezone).valueOf() >= startofDay) {
                    newTicketArray.push(JSON.parse(JSON.stringify(t)))
                }
            }

            this.ticketsOfQueues = JSON.parse(JSON.stringify(newTicketArray))

            const newInitialDoneTicketArray = []
            for (const t of this.initialDoneTicket) {
                if (moment.tz(t.timeline.initialBookedFor, moment.ISO_8601, this.timezone).valueOf() >= startofDay) {
                    newInitialDoneTicketArray.push(JSON.parse(JSON.stringify(t)))
                }
            }

            this.initialDoneTicket = JSON.parse(JSON.stringify(newInitialDoneTicketArray))
        }

        let currentTickets = []
        let inProgressTickets = []
        let lateTickets = []
        let doneTickets = []
        let currentPeopleNumber = 0
        let inProgressPeopleNumber = 0
        let latePeopleNumber = 0
        let donePeopleNumber = 0

        let initialDoneTicket = JSON.parse(JSON.stringify(this.initialDoneTicket))
        let tickets = JSON.parse(JSON.stringify(this.ticketsSSE))
        // if (this.filter && JSON.stringify(this.filter) !== '{}') {
        //   initialDoneTicket = generateFilterFunctionFromPseudoCodeForArray(JSON.parse(JSON.stringify(initialDoneTicket)), this.filter, 'ticket')
        //   tickets = generateFilterFunctionFromPseudoCodeForArray(JSON.parse(JSON.stringify(tickets)), this.filter, 'ticket')
        // }

        for (const k in initialDoneTicket) {
            doneTickets.push(JSON.parse(JSON.stringify(initialDoneTicket[k])))
            donePeopleNumber += initialDoneTicket[k].groupSize
        }

        for (const k in tickets) {
            if (lateStates.indexOf(tickets[k].state) >= 0) {
                lateTickets.push(JSON.parse(JSON.stringify(tickets[k])))
                latePeopleNumber += tickets[k].groupSize
            } else if (doneStates.indexOf(tickets[k].state) >= 0) {
                doneTickets.push(JSON.parse(JSON.stringify(tickets[k])))
                donePeopleNumber += tickets[k].groupSize
            } else if (tickets[k].state !== inProgressState) {
                currentTickets.push(JSON.parse(JSON.stringify(tickets[k])))
                currentPeopleNumber += tickets[k].groupSize
            } else {
                inProgressTickets.push(JSON.parse(JSON.stringify(tickets[k])))
                inProgressPeopleNumber += tickets[k].groupSize
            }
        }

        currentTickets = currentTickets.sort(sortTickets)
        inProgressTickets = inProgressTickets.sort(sorInProgressTickets)
        lateTickets = lateTickets.sort(sortLateTickets)
        doneTickets = doneTickets.sort(sortDoneTickets)

        // if (this.sort && JSON.stringify(this.sort) !== '{}') {
        //   currentTickets = generateSortFunctionFromPseudoCode(currentTickets, this.sort)
        //   inProgressTickets = generateSortFunctionFromPseudoCode(inProgressTickets, this.sort)
        //   lateTickets = generateSortFunctionFromPseudoCode(lateTickets, this.sort)
        //   doneTickets = generateSortFunctionFromPseudoCode(doneTickets, this.sort)
        // }

        const currentTicketsNumber = currentTickets.length
        const inProgressTicketsNumber = inProgressTickets.length
        const lateTicketsNumber = lateTickets.length
        const doneTicketsNumber = doneTickets.length

        // currentTickets = currentTickets.slice(0, this.maxTicketDisplay)
        // inProgressTickets = inProgressTickets.slice(0, this.maxTicketDisplay)
        // lateTickets = lateTickets.slice(0, this.maxTicketDisplay)
        // doneTickets = doneTickets.slice(0, this.maxTicketDisplay)

        const res = {
            current: {
                number: currentTicketsNumber,
                people: currentPeopleNumber,
                tickets: currentTickets
            },
            inProgress: {
                number: inProgressTicketsNumber,
                tickets: inProgressTickets,
                people: inProgressPeopleNumber
            },
            late: {
                number: lateTicketsNumber,
                tickets: lateTickets,
                people: latePeopleNumber
            },
            done: {
                number: doneTicketsNumber,
                tickets: doneTickets,
                people: donePeopleNumber
            },
            totalTicketsNumber: currentTicketsNumber + inProgressTicketsNumber + lateTicketsNumber + doneTicketsNumber,
            totalPeopleNumber: currentPeopleNumber + inProgressPeopleNumber + latePeopleNumber + donePeopleNumber
        }

        this.ticketsEvent.next(res)

        // this.currentTickets = currentTickets
        this.currentTicketsEvent.next({ number: currentTicketsNumber, tickets: currentTickets, people: currentPeopleNumber })

        // this.inProgressTickets = inProgressTickets
        this.inProgressTicketsEvent.next({
            number: inProgressTicketsNumber,
            tickets: inProgressTickets,
            people: inProgressPeopleNumber
        })

        // this.lateTickets = lateTickets
        this.lateTicketsEvent.next({ number: lateTicketsNumber, tickets: lateTickets, people: latePeopleNumber })

        if (doneTicketsNumber > 0) {
            this.doneTicketsEvent.next({ number: doneTicketsNumber, tickets: doneTickets, people: donePeopleNumber })
        }


    }


    private updateTicketFromEvent(event) {
        if (event.resource === 'TICKET') {
            if (event.actionCategory === 'ALL_DATA') {
                const now = Date.now()
                for (const k in event.data.tickets) {
                    this.findTicketAndDoAction(event.data.tickets[k], 'UPDATE', event.actionName, now)
                }

            } else if (event.actionCategory === 'CREATE' || event.actionCategory === 'UPDATE') {
                this.findTicketAndDoAction(event.data.ticket, 'UPDATE', event.actionName, event.time)
            }

            if (this.reloadTicketTimeout) clearTimeout(this.reloadTicketTimeout)
            this.reloadTicketTimeout = null

            this.reloadTicketTimeout = setTimeout(() => this.reloadTicketToDisplay(), 250)

        }
    }

    private findTicketAndDoAction(ticket: TicketApiBookingV2 & { lastUpdate: string }, action, actionName, time) {
        let found = false

        for (const k in this.ticketsSSE) {
            if (this.ticketsSSE[k].ticketId === ticket.ticketId) {

                found = true

                if (!time || !this.ticketsSSE[k].lastUpdate || time > this.ticketsSSE[k].lastUpdate) {
                    if (action === 'UPDATE') {
                        if (this.ticketsSSE[k].state !== TicketState.CANCELED && this.ticketsSSE[k].state !== TicketState.DONE) {
                            /*if ( actionName === 'TICKET_POSITION_UPDATE' ) {
                                this.tickets[k].timeline = ticket.timeline
                                this.tickets[k].ticketPosition = ticket.ticketPosition
                            } else {*/
                            this.ticketsSSE[k] = ticket
                            // this.lastTicketUpdate.next(ticket)
                            // }
                        }
                        this.ticketsSSE[k].lastUpdate = time ? time : Date.now()

                    } else if (action === 'DELETE') {
                        this.ticketsSSE.splice(parseInt(k, 10), 1)
                        this.ticketsSSE[k].lastUpdate = time ? time : Date.now()
                    }
                }

                break
            }
        }

        if (!found && action === 'UPDATE') {
            ticket.lastUpdate = time ? time : Date.now()
            this.ticketsSSE.push(ticket)
        }

    }

    setTicketState(ticketId: string, queueId, state: number, endpointId?: string): Observable<LocalCRUDRes> {
        const body: { tickets: { [ticketId: string]: { setStateTo: number, endpointId?: string } } } = {
            tickets: {
                [ticketId]: { setStateTo: state }
            }
        }

        if (endpointId) body.tickets[ticketId].endpointId = endpointId

        let url = this.configService.httpUrl.tickets.setTicketsState
        url = url.replace('{queueId}', queueId)
        return this.httpService.put(url, body, null, null).pipe(
            first(),
            switchMap((httpRes: HttpCRUDRes) => {
                if (httpRes.error === 0 && httpRes.success > 0) {
                    return of({ success: true })
                } else {
                    return of({ success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError })
                }
            }),
        )
    }

    private getTicketsInternal(queuesId: string[], states, start, end, page = 1, pageSize = 25): Observable<PaginatedTickets<TicketApiAdmin>> {
        const query: any = { queueIds: queuesId }
        if (start) { query.startAt = start }
        if (end) { query.endAt = end }
        if (page) { query.page = page }
        if (pageSize) { query.pageSize = pageSize }
        if (states) {query.states = states}
        let url = this.configService.httpUrl.queues.getTickets
        return this.httpService.get(
            url, query, null
        ).pipe(
            first(),
        )
    }



    private getCurrentsTicketsSse(queuesId: string[]) {
        let url = this.configService.httpUrl.queues.getTicketsSSE

        url = this.httpService.applyGetParamsToUrl(url, { queueIds: queuesId })
        return this.httpService.getHeadersForSSE().pipe(
            switchMap((headers) => {
                return new Observable((observer) => {
                    currentsTicketsSse = new EventSourcePolyfill(url, {
                        headers: headers
                    })
                    currentsTicketsSse.onopen = () => {
                        console.log('Ticket SSE connected')
                    }
                    currentsTicketsSse.onmessage = (event) => {
                        this.zone.run(() => observer.next(JSON.parse(event.data)))
                    }
                    currentsTicketsSse.onerror = (error) => {
                        console.error('Error on ticket sse', error)
                        this.zone.run(() => observer.error('SSE_CLOSED'))
                    }
                })
            })
        )
    }

    private clear() {
        this.sseSubscription.unsubscribe()
        currentsTicketsSse.close()
        this.ticketsOfQueues = []
        this.ticketsSSE = []
        this.initialDoneTicket = []
    }
}
