import { Pipe, PipeTransform } from '@angular/core'
import { LbTranslationService } from 'lb-utils-front/dist'
import { CompaniesPlacesQueuesService } from '../services/default-resources/companies-places-queues.service'
import { Company } from 'lb-types/dist'

@Pipe({
  name: 'companyNameFromId'
})
export class CompanyNameFromIdPipe implements PipeTransform {

  constructor (
      private lbTranslationService: LbTranslationService,
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService
  ) {
  }

  transform ( obj: string ): any {
    const company: Company = this.companiesPlacesQueuesService.getCompanyById( obj )
    if ( company ) {
      return this.lbTranslationService.translateFromObject( company.name )
    }
    else {
      return '-'
    }
  }
}
