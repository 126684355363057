import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core'
import { Company, OperatorProfiles, Place, Queue } from 'lb-types'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { LbTranslationService } from 'lb-utils-front/dist'
import { NbDialogRef } from '@nebular/theme'
import { CompaniesPlacesQueuesService } from '../../services/default-resources/companies-places-queues.service'
import { CompaniesPlacesAndQueues } from '../../interfaces/companiesPlacesAndQueues/companiesPlacesAndQueues'
import { DatatableSettings } from '../../global-components/datatable/datatable.component'
import { UtilsService } from '../../services/utils/utils.service'
@Component({
  selector: 'ngx-select-company-place-queue',
  templateUrl: './select-company-place-queue.component.html',
  styleUrls: ['./select-company-place-queue.component.scss']
})
export class SelectCompanyPlaceQueueComponent implements OnInit {

  public companiesPlaceAndQueues: CompaniesPlacesAndQueues
  @Input() availablePage: 'company' | 'place' | 'queue' | 'auto' = 'auto'
  @Input() firstPage: 'company' | 'place' | 'queue' | 'auto' = 'auto'
  @Input() multiSelection: boolean = false
  @Input() typeOfData: 'companiesPlacesQueues' | 'simple' = 'companiesPlacesQueues'
  @Input() title: string
  @Input() allSelect: {[companyId: string]: { [placeId: string]: string[] }} = {}
  @Input() simpleSelect: string[] = []
  @Input() alreadySelectedCompanies: string[] = []
  @Input() alreadySelectedPlaces: string[] = []
  @Input() alreadySelectedQueues: string[] = []
  @Input() allowedCompaniesPlacesQueues: { all: boolean,
    companies: {all: boolean, companyId: string}[],
    places: {all: boolean, companyId: string, placeId: string}[],
    queues: {companyId: string, placeId: string, queueId: string}[]
  } =
      { all: false,
        companies: [],
        places: [],
        queues: []
      }

  public filter: string = ''
  public currentPage: 'company' | 'place' | 'queue' = 'company'
  public selectedCompanyId: string = ''
  public selectedPlaceId: string = ''
  public settingsCompany: DatatableSettings
  public settingsPlace: DatatableSettings
  public settingsQueue: DatatableSettings
  public dataCompany: any = []
  public dataPlace: any = []
  public dataQueue: any = []

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
      private lbTranslationService: LbTranslationService,
      protected ref: NbDialogRef<SelectCompanyPlaceQueueComponent>,
      public utilsService: UtilsService
  ) { }

  ngOnInit (): void {
    if ( !this.simpleSelect ) { this.simpleSelect = [] }
    if ( !this.allSelect ) { this.allSelect = {} }
    this.companiesPlacesQueuesService.getCompaniesPlacesAndQueues().subscribe( ( companiesPlaceAndQueues: any ) => {
      this.companiesPlaceAndQueues = companiesPlaceAndQueues
      if ( this.firstPage === 'auto' || this.availablePage === 'auto' ) {
        if ( Object.keys( this.companiesPlaceAndQueues ).length === 1 ) {
          this.selectedCompanyId = Object.keys( this.companiesPlaceAndQueues )[0]
          if ( Object.keys( this.companiesPlaceAndQueues[ this.selectedCompanyId ].places ).length === 1 ) {
            this.selectedPlaceId = Object.keys( this.companiesPlaceAndQueues[ this.selectedCompanyId ].places )[0]
            if ( this.availablePage === 'auto' ) { this.availablePage = 'queue' }
            if ( this.firstPage === 'auto' ) { this.currentPage = 'queue' }
          } else {
            if ( this.availablePage === 'auto' ) { this.availablePage = 'place' }
            if ( this.firstPage === 'auto' ) { this.currentPage = 'place' }
          }
        } else {
          if ( this.availablePage === 'auto' ) { this.availablePage = 'company' }
          if ( this.firstPage === 'auto' ) { this.currentPage = 'company' }
        }
      } else {
        this.currentPage = this.firstPage
      }
      this.initData()
    })
  }

  /* 
    Si l'entreprise est dans la liste des entreprises autorisées
    et que l'entreprise a au moins une file autorisée ou que tous les lieux de l'entreprise sont autorisés
  */
  companyIsAllowed(companyId){
    let companyAllowed = false
    let allPlacesAllowed = false
    let hasAQueue = false
    if(this.allowedCompaniesPlacesQueues.all)
      return true
    this.allowedCompaniesPlacesQueues.companies.forEach( company => {
      if(company.companyId === companyId && company.all){
        companyAllowed = true
        allPlacesAllowed = company.all
      }
    })
    // On vérifie s'il existe au moins une file allouée à cette entreprise
    this.allowedCompaniesPlacesQueues.queues.forEach( queue => {
      if(queue.companyId === companyId)
        hasAQueue = true
    })
    return (companyAllowed && (allPlacesAllowed || hasAQueue)) || false
  }
  /* 
    Si le lieu est dans la liste des lieux autorisés
    ou que tous les lieux de l'entreprise à laquelle il appartient sont autorisés
    et qu'il existe au moins une file allouée à ce lieu
   */
  placeIsAllowed(companyId, placeId){
    let placeAllowed = false
    let allQueuesAllowed = false
    let allPlacesOfCompanyAllowed = false
    let hasAQueue = false
    if(this.allowedCompaniesPlacesQueues.all)
      return true
    this.allowedCompaniesPlacesQueues.places.forEach( place => {
      if(place.placeId === placeId && place.all){
        placeAllowed = true
        allQueuesAllowed = place.all
      }
    })
    this.allowedCompaniesPlacesQueues.companies.forEach( company => {
      if((company.companyId === companyId) && company.all)
        allPlacesOfCompanyAllowed = true
    })
    // On vérifie s'il existe au moins une file allouée à ce lieu
    this.allowedCompaniesPlacesQueues.queues.forEach( queue => {
      if(queue.placeId === placeId)
        hasAQueue = true
    })
    return (allPlacesOfCompanyAllowed || (placeAllowed && (allQueuesAllowed || hasAQueue))) || false
  }
  /* 
   Si la file est dans la liste des files autorisées
   ou que toutes les files du lieu dans lequel elle se trouve sont autorisées
   ou que tous les lieux de l'entreprise dans laquelle elle se trouve sont autorisées
  */
  queueIsAllowed(companyId, placeId, queueId){
    let queueAllowed = false
    if(this.allowedCompaniesPlacesQueues.all)
      return true
    this.allowedCompaniesPlacesQueues.queues.forEach( queue => {
      if(queue.queueId === queueId)
        queueAllowed = true
    })
    this.allowedCompaniesPlacesQueues.places.forEach( place => {
      if(place.placeId === placeId && place.all)
        queueAllowed = true
    })
    this.allowedCompaniesPlacesQueues.companies.forEach( company => {
      if(company.companyId === companyId && company.all)
        queueAllowed = true
    })
    return queueAllowed || false
  }

  companyExists(){
    if(this.allowedCompaniesPlacesQueues.all)
      return true
    if(this.utilsService.isDefined(this.allowedCompaniesPlacesQueues.companies)){
      if(this.allowedCompaniesPlacesQueues.companies.length > 0)
        return true
    }
    return false
  }

  placeExists(){
    if(this.allowedCompaniesPlacesQueues.all)
      return true
    if(this.utilsService.isDefined(this.allowedCompaniesPlacesQueues.places)){
      if(this.allowedCompaniesPlacesQueues.places.length > 0)
        return true
    }
    return false
  }

  queueExists(){
    if(this.allowedCompaniesPlacesQueues.all)
      return true
    if(this.utilsService.isDefined(this.allowedCompaniesPlacesQueues.queues)){
      if(this.allowedCompaniesPlacesQueues.queues.length > 0)
        return true
    }
    return false
  }

  addPlaceToList( company, places ) {
    if( Object.keys(places).length > 0 ){
      for ( const placeId in places ) {
        if ( placeId in places ) {
          if(places[placeId] !== null && places[placeId] !== undefined){
            if(places[placeId].place !== null && places[placeId].place !== undefined){
              const tmpPlace: any = {
                companyId: company.companyId,
                name: places[placeId].place.name,
                companyName: company.name,
                logo: company.logo,
                country: places[placeId].place.country + ' / ' + places[placeId].place.city
              }
              if(this.placeIsAllowed(company.companyId, placeId)) {
                this.dataPlace.push( { key: placeId, value: tmpPlace } )
                if(! this.existsInAllowedResources(placeId, 'place')) {
                  this.allowedCompaniesPlacesQueues.places.push({companyId: company.companyId, placeId: placeId, all: false})
                }
              }
            }
          }
        }
      }
    }
  }

  addQueueToList( company: Company, place: Place, queues: { [queueId: string]: Queue} ) {
    for ( const queueId in queues ) {
      if ( queueId in queues ) {
        if(place !== null && place !== undefined){
          const tmpQueue: any = {
            companyId: company.companyId,
            placeId: place.placeId,
            name: queues[queueId].name,
            disabled: queues[queueId].disabled,
            companyName: company.name,
            placeName: place.name,
            logo: company.logo
          }
          if(this.queueIsAllowed(company.companyId, place.placeId, queueId)){
            this.dataQueue.push( { key: queueId, value: tmpQueue } )
            if(! this.existsInAllowedResources(queueId, 'queue'))
              this.allowedCompaniesPlacesQueues.queues.push({companyId: company.companyId, placeId: place.placeId, queueId: queueId})
          }
        }
      }
    }
  }

  existsInAllowedResources(id, type){
    if(type === 'company'){
      for ( const company of this.allowedCompaniesPlacesQueues.companies ) {
        if ( company.companyId === id ) {
          return true
        }
      }
    }
    else if(type === 'place'){
      for ( const place of this.allowedCompaniesPlacesQueues.places ) {
        if ( place.placeId === id ) {
          return true
        }
      }
    }
    else if(type === 'queue'){
      for ( const queue of this.allowedCompaniesPlacesQueues.queues ) {
        if ( queue.queueId === id ) {
          return true
        }
      }
    }
  }

  initData() {
    this.dataCompany = []
    this.dataPlace = []
    this.dataQueue = []
    for ( const companyId in this.companiesPlaceAndQueues ) {
      if ( companyId in this.companiesPlaceAndQueues && this.companiesPlaceAndQueues[companyId].company !== null && this.companiesPlaceAndQueues[companyId].company !== undefined) {
        const tmpCompany: any = {
          name: this.companiesPlaceAndQueues[companyId].company.name,
          logo: this.companiesPlaceAndQueues[companyId].company.logo,
          language: this.companiesPlaceAndQueues[companyId].company.language
        }
        if(this.companyIsAllowed(companyId)){
          this.dataCompany.push( { key: companyId, value: tmpCompany } )
          if(! this.existsInAllowedResources(companyId, 'company'))
            this.allowedCompaniesPlacesQueues.companies.push({companyId: companyId, all: false})
        }

        const company = this.companiesPlaceAndQueues[ companyId ].company
        this.addPlaceToList( company, this.companiesPlaceAndQueues[ companyId ].places )
        for ( const placeId in this.companiesPlaceAndQueues[ companyId ].places ) {
          if ( this.companiesPlaceAndQueues[ companyId ].places[ placeId ] ) {
            const place = this.companiesPlaceAndQueues[ companyId ].places[ placeId ].place
            this.addQueueToList( company, place, this.companiesPlaceAndQueues[ companyId ].places[ placeId ].queues )
          }
        }
      }
    }

    this.settingsCompany = {
      title : 'Liste des Entreprises',
      columns : [
        { id : 'logo', width : 1, title : 'Logo', search : false, sortAllowed : true, textAlign: 'center', field: 'value.logo', type: 'string' },
        { id : 'companyName', width : 11, title : 'Nom de l\'entreprise', search : true, searchType: 'textAndSelect', sortAllowed : true, textAlign: 'left', type: 'langs', field: 'value.name' }
      ],
      defaultSort : [ { id: 'companyName', sort: 'ASC'} ],
      messageOnEmptyData : 'Aucune entreprise n\'existe',
      messageOnEmptySearchResult : 'Aucun résultat trouvé',
      multipleSelectionKey : 'key',
      showMoreActions : false,
      rowClickable: true,
      defaultNumberElementDisplayed: 10,
      availableNumberOfElementsToDisplay: [5, 10, 15],
      showNumberOfElementsToDisplay: true,
      selectedItems: this.alreadySelectedCompanies,
      showMultipleSelection: this.multiSelection,
      multipleLineAction : [
        { title: 'Sélectionner', id: 'addCompanies', icon: 'plus-circle-outline'}
      ]
    }

    this.settingsPlace = {
      title : 'Liste des Lieux',
      columns : [
        { id : 'logo', width : 1, title : 'Logo', search : false, sortAllowed : true, textAlign: 'center', field: 'value.logo', type: 'string' },
        { id : 'companyName', width : 5, title : 'Nom de l\'entreprise', search : true, searchType: 'textAndSelect', sortAllowed : true, textAlign: 'left', type: 'langs', field: 'value.companyName' },
        { id : 'placeName', width : 6, title : 'Nom du lieu', search : true, searchType: 'textAndSelect', sortAllowed : true, textAlign: 'left', type: 'langs', field: 'value.name' }
      ],
      defaultSort : [ { id: 'companyName', sort: 'ASC'}, { id: 'placeName', sort: 'ASC'} ],
      messageOnEmptyData : 'Aucun lieu n\'existe',
      messageOnEmptySearchResult : 'Aucun résultat trouvé',
      multipleSelectionKey : 'key',
      showMoreActions : false,
      rowClickable: true,
      defaultNumberElementDisplayed: 10,
      availableNumberOfElementsToDisplay: [5, 10, 15],
      showNumberOfElementsToDisplay: true,
      selectedItems: this.alreadySelectedPlaces,
      showMultipleSelection: this.multiSelection,
      multipleLineAction : [
        { title: 'Sélectionner', id: 'addPlaces', icon: 'plus-circle-outline'}
      ]
    }

    this.settingsQueue = {
      title : 'Liste des files',
      columns : [
        { id : 'logo', width : 1, title : 'Logo', search : false, sortAllowed : true, textAlign: 'center', field: 'value.error.display', type: 'boolean' },
        { id : 'companyName', width : 3, title : 'Nom de l\'entreprise', search : true, searchType: 'textAndSelect', sortAllowed : true, textAlign: 'left', type: 'langs', field: 'value.companyName' },
        { id : 'placeName', width : 3, title : 'Nom du lieu', search : true, searchType: 'textAndSelect', sortAllowed : true, textAlign: 'left', type: 'langs', field: 'value.placeName' },
        { id : 'queueName', width : 5, title : 'Nom de la file', search : true, searchType: 'textAndSelect', sortAllowed : true, textAlign: 'left', type: 'langs', field: 'value.name' }
      ],
      defaultSort : [ { id: 'companyName', sort: 'ASC'}, { id: 'placeName', sort: 'ASC'}, { id: 'queueName', sort: 'ASC'} ],
      messageOnEmptyData : 'Aucune file n\'existe',
      messageOnEmptySearchResult : 'Aucun résultat trouvé',
      multipleSelectionKey : 'key',
      showMoreActions : false,
      rowClickable: true,
      defaultNumberElementDisplayed: 10,
      availableNumberOfElementsToDisplay: [5, 10, 15],
      showNumberOfElementsToDisplay: true,
      selectedItems: this.alreadySelectedQueues,
      showMultipleSelection: this.multiSelection,
      multipleLineAction : [
        { title: 'Sélectionner', id: 'addQueues', icon: 'plus-circle-outline'}
      ]

    }
  }

  multipleAction ( event ) {
    if ( event.actionId === 'addCompanies') {
      this.submit()
    }
    if ( event.actionId === 'addPlaces') {
      this.submit()
    }
    if ( event.actionId === 'addQueues') {
      this.submit()
    }
  }

  onCompaniesSelected ( event ) {
    for ( const companyId of event.actionIds ) {
      this.selectCompany( {key: companyId, value: {} })
    }
  }

  onPlacesSelected ( event ) {
    for ( const placeId of event.actionIds ) {
      const companyId = this.companiesPlacesQueuesService.getPlaceById( placeId ).companyId
      this.selectPlace( { key: placeId, value: {companyId: companyId} } )
    }
  }

  onQueuesSelected ( event ) {
    for ( const queueId of event.actionIds ) {
      const companyId = this.companiesPlacesQueuesService.getQueueById( queueId ).companyId
      const placeId = this.companiesPlacesQueuesService.getQueueById( queueId ).placeId
      //this.selectQueue( companyId, placeId, queueId )
      this.selectQueue( { key: queueId, value: {placeId: placeId, companyId: companyId} } )
    }
  }

  updateSelectedItems () {
    const companiesId = []
    const placesId = []
    const queuesId = []
    for ( const companyId in this.allSelect ) {
      companiesId.push( companyId )
      for ( const placeId in this.allSelect[companyId] ) {
        placesId.push( placeId )
        for ( const queueId of this.allSelect[companyId][placeId] ) {
          queuesId.push( queueId )
        }
      }
    }
    this.settingsCompany.selectedItems = [...companiesId, this.alreadySelectedCompanies]
    this.settingsPlace.selectedItems = [...placesId, this.alreadySelectedPlaces]
    this.settingsQueue.selectedItems = [...queuesId, this.alreadySelectedQueues]
  }

    selectCompany ( event ) {
      const companyId = event.key
      if ( this.typeOfData === 'companiesPlacesQueues' ) {
        if ( this.multiSelection ) {
          if ( this.allSelect[ companyId ] ) {
            delete this.allSelect[ companyId ]
          } else {
            this.allSelect[ companyId ] = {}
          }
          this.updateSelectedItems()
        } else {
          this.allSelect = { [companyId]: {} }
          this.submit()
        }
      } else {
        if ( this.multiSelection ) {
          if ( this.simpleSelect.indexOf( companyId ) < 0 ) {
            this.simpleSelect.push( companyId )
          } else {
            this.simpleSelect.splice( this.simpleSelect.indexOf( companyId ), 1 )
          }
          this.settingsCompany.selectedItems = this.simpleSelect
        } else {
          this.simpleSelect = [ companyId ]
          this.submit()
        }
      }
    }

    selectPlace ( event ) {
      const placeId = event.key
      const companyId = event.value.companyId
      if ( this.typeOfData === 'companiesPlacesQueues' ) {
        if ( this.multiSelection ) {
          if ( this.allSelect[ companyId ] ) {
            if ( this.allSelect[ companyId ][ placeId ] ) {
              delete this.allSelect[ companyId ][ placeId ]
              if ( Object.keys( this.allSelect[ companyId ] ).length === 0 ) {
                delete this.allSelect[ companyId ]
              }
            } else {
              this.allSelect[ companyId ][ placeId ] = []
            }
          } else {
            this.allSelect[ companyId ] = { [placeId]: [] }
          }
          this.updateSelectedItems()
        } else {
          this.allSelect = { [companyId]: {} }
          this.submit()
        }
      } else {
        if ( this.multiSelection ) {
          if ( this.simpleSelect.indexOf( placeId ) < 0 ) {
            this.simpleSelect.push( placeId )
          } else {
            this.simpleSelect.splice( this.simpleSelect.indexOf( placeId ), 1 )
          }
          this.settingsPlace.selectedItems = this.simpleSelect
        } else {
          this.simpleSelect = [ placeId ]
          this.submit()
        }
      }
    }

    selectQueue ( event ) {
      const queueId = event.key
      const placeId = event.value.placeId
      const companyId = event.value.companyId
      if ( this.typeOfData === 'companiesPlacesQueues' ) {
        if ( this.multiSelection ) {
          if ( this.allSelect[ companyId ] ) {
            if ( this.allSelect[ companyId ][ placeId ] ) {
              if ( this.allSelect[ companyId ][ placeId ].indexOf( queueId ) >= 0 ) {
                this.allSelect[ companyId ][ placeId ].splice( this.allSelect[ companyId ][ placeId ].indexOf( queueId ), 1 )
                if ( this.allSelect[ companyId ][ placeId ].length <= 0 ) { delete this.allSelect[ companyId ][ placeId ] }
                if ( Object.keys( this.allSelect[ companyId ] ).length === 0 ) { delete this.allSelect[ companyId ] }
              } else {
                this.allSelect[ companyId ][ placeId ].push( queueId )
              }
            } else {
              this.allSelect[ companyId ][ placeId ] = [ queueId ]
            }
          } else {
            this.allSelect[ companyId ] = { [placeId]: [ queueId ] }
          }
          this.updateSelectedItems()
        } else {
          this.allSelect = { [companyId]: {} }
          this.submit()
        }
      } else {
        if ( this.multiSelection ) {
          if ( this.simpleSelect.indexOf( queueId ) < 0 ) {
            this.simpleSelect.push( queueId )
          } else {
            this.simpleSelect.splice( this.simpleSelect.indexOf( queueId ), 1 )
          }
          this.settingsQueue.selectedItems = this.simpleSelect
        } else {
          this.simpleSelect = [ queueId ]
          this.submit()
        }
      }
    }

    cancel() {
      this.ref.close()
    }

    submit(): void {
      if ( this.typeOfData === 'companiesPlacesQueues' ) {
      this.ref.close( this.allSelect )
    } else {
      this.ref.close( this.simpleSelect )
    }
  }

  }
