<nb-card class="list-card">
    <nb-card-header class="text-center"> Configuration de l'affichage de l'heure / temps d'attente </nb-card-header>
    <nb-card-body>
        <div class="configTypeContainer">
            <h3 class="text-center"> Formats d'affichage </h3>
            <div>
                <div class="typeTitle"> Formats simples </div>
                <div>
                    <button *ngIf="availableTypeConditions.indexOf('DATE') >= 0" (click)="selectConfigType('DATE')" nbButton status="{{ configType === 'DATE' ? 'success' : 'primary' }}">Date (12h30)</button>
                    <button *ngIf="availableTypeConditions.indexOf('DATE') >= 0" (click)="selectConfigType('TIME_LEFT')" nbButton status="{{ configType === 'TIME_LEFT' ? 'success' : 'primary' }}">Temps restant (10min)</button>
                    <br />
                    <button *ngIf="availableTypeConditions.indexOf('NB_GLOBAL_PEOPLE') >= 0" (click)="selectConfigType('NB_GLOBAL_PEOPLE')" nbButton status="{{ configType === 'NB_GLOBAL_PEOPLE' ? 'success' : 'primary' }}">Nombre de personnes sur la file</button>
                    <button *ngIf="availableTypeConditions.indexOf('NB_GLOBAL_TICKET') >= 0" (click)="selectConfigType('NB_GLOBAL_TICKET')" nbButton status="{{ configType === 'NB_GLOBAL_TICKET' ? 'success' : 'primary' }}">Nombre de tickets sur la file</button>
                    <button *ngIf="availableTypeConditions.indexOf('NB_ENDPOINT_PEOPLE') >= 0" (click)="selectConfigType('NB_ENDPOINT_PEOPLE')" nbButton status="{{ configType === 'NB_ENDPOINT_PEOPLE' ? 'success' : 'primary' }}">Nombre de personnes sur le point de passage</button>
                    <button *ngIf="availableTypeConditions.indexOf('NB_ENDPOINT_TICKET') >= 0" (click)="selectConfigType('NB_ENDPOINT_TICKET')" nbButton status="{{ configType === 'NB_ENDPOINT_TICKET' ? 'success' : 'primary' }}">Nombre de tickets sur le point de passage</button>
                </div>
            </div>
            <div>
                <div class="typeTitle"> Formats combinés </div>
                <div>
                    <button *ngIf="availableTypeConditions.indexOf('TIME_LEFT-NB_GLOBAL_PEOPLE') >= 0" (click)="selectConfigType('TIME_LEFT-NB_GLOBAL_PEOPLE')" nbButton status="{{ configType === 'TIME_LEFT-NB_GLOBAL_PEOPLE' ? 'success' : 'primary' }}">Temps restant puis NB de personnes sur la file</button>
                    <button *ngIf="availableTypeConditions.indexOf('TIME_LEFT-NB_GLOBAL_TICKET') >= 0" (click)="selectConfigType('TIME_LEFT-NB_GLOBAL_TICKET')" nbButton status="{{ configType === 'TIME_LEFT-NB_GLOBAL_TICKET' ? 'success' : 'primary' }}">Temps restant puis NB de tickets sur la file</button>
                    <button *ngIf="availableTypeConditions.indexOf('TIME_LEFT-NB_ENDPOINT_PEOPLE') >= 0" (click)="selectConfigType('TIME_LEFT-NB_ENDPOINT_PEOPLE')" nbButton status="{{ configType === 'TIME_LEFT-NB_ENDPOINT_PEOPLE' ? 'success' : 'primary' }}">Temps restant puis NB de personnes sur le point de passage</button>
                    <button *ngIf="availableTypeConditions.indexOf('TIME_LEFT-NB_ENDPOINT_TICKET') >= 0" (click)="selectConfigType('TIME_LEFT-NB_ENDPOINT_TICKET')" nbButton status="{{ configType === 'TIME_LEFT-NB_ENDPOINT_TICKET' ? 'success' : 'primary' }}">Temps restant puis NB de tickets sur le point de passage</button>
                    <br />
                    <button *ngIf="availableTypeConditions.indexOf('DATE-TIME_LEFT') >= 0" (click)="selectConfigType('DATE-TIME_LEFT')" nbButton status="{{ configType === 'DATE-TIME_LEFT' ? 'success' : 'primary' }}">Date puis temps restant</button>
                    <button *ngIf="availableTypeConditions.indexOf('DATE-NB_GLOBAL_PEOPLE') >= 0" (click)="selectConfigType('DATE-NB_GLOBAL_PEOPLE')" nbButton status="{{ configType === 'DATE-NB_GLOBAL_PEOPLE' ? 'success' : 'primary' }}">Date puis NB de personnes sur la file</button>
                    <button *ngIf="availableTypeConditions.indexOf('DATE-NB_GLOBAL_TICKET') >= 0" (click)="selectConfigType('DATE-NB_GLOBAL_TICKET')" nbButton status="{{ configType === 'DATE-NB_GLOBAL_TICKET' ? 'success' : 'primary' }}">Date puis NB de tickets sur la file</button>
                    <button *ngIf="availableTypeConditions.indexOf('DATE-NB_ENDPOINT_PEOPLE') >= 0" (click)="selectConfigType('DATE-NB_ENDPOINT_PEOPLE')" nbButton status="{{ configType === 'DATE-NB_ENDPOINT_PEOPLE' ? 'success' : 'primary' }}">Date puis NB de personnes sur le point de passage</button>
                    <button *ngIf="availableTypeConditions.indexOf('DATE-NB_ENDPOINT_TICKET') >= 0" (click)="selectConfigType('DATE-NB_ENDPOINT_TICKET')" nbButton status="{{ configType === 'DATE-NB_ENDPOINT_TICKET' ? 'success' : 'primary' }}">Date puis NB de tickets sur le point de passage</button>
                </div>
            </div>
        </div>

        <br /><br /><br />

        <nb-card>
            <nb-card-header (click)="conditionsOpened = !conditionsOpened" class="text-center">
                Textes et conditions d'affichages
                <nb-icon class="chevronIcon" [icon]="conditionsOpened ? 'chevron-up' : 'chevron-down' " pack="font-awesome"></nb-icon>
            </nb-card-header>
            <nb-card-body class="conditionsContainer" *ngIf="conditionsOpened">
                <nb-card class="conditionCard" *ngFor="let cond of timeConfig.cond">
                    <nb-card-body>
                        <div class="conditionTypeContainer">
                            <span>Format d'affichage : </span>
                            <span *ngIf="cond.type === 'DATE'"> Heure HH:mm </span>
                            <span *ngIf="cond.type === 'TIME_LEFT'"> Temps restant </span>
                            <span *ngIf="cond.type === 'NB_GLOBAL_PEOPLE'"> Nombre de personnes dans la file </span>
                            <span *ngIf="cond.type === 'NB_GLOBAL_TICKET'"> Nombre de tickets dans la file </span>
                            <span *ngIf="cond.type === 'NB_ENDPOINT_PEOPLE'"> Nombre de personnes sur le point de passage </span>
                            <span *ngIf="cond.type === 'NB_ENDPOINT_TICKET'"> Nombre de tickets sur le point de passage </span>
                        </div>
                        <div class="conditionListContainer">
                            <ul>
                                <li *ngIf="cond.nbGlobalPeople >= 0"> Si il y a moins de <input class="inputNumberCondition" type="number" [(ngModel)]="cond.nbGlobalPeople"> personnes dans la file </li>
                                <li *ngIf="cond.nbGlobalTickets >= 0"> Si il y a moins de <input class="inputNumberCondition" type="number" [(ngModel)]="cond.nbGlobalTickets"> tickets dans la file </li>
                                <li *ngIf="cond.nbEndpointPeople >= 0"> Si il y a moins de <input class="inputNumberCondition" type="number" [(ngModel)]="cond.nbEndpointPeople"> personnes sur le point de passage </li>
                                <li *ngIf="cond.nbEndpointTickets >= 0"> Si il y a moins de <input class="inputNumberCondition" type="number" [(ngModel)]="cond.nbEndpointTickets"> tickets sur le point de passage </li>
                                <li *ngIf="cond.state >= 0"> Si le ticket est au moins dans l'état <span class="text-link">{{ cond.state | lbTicketState:locale.language | lbCapitalize:'FIRST' }}</span> </li>
                                <li *ngIf="(cond.time | lbTypeOf ) === 'number' && cond.time >= 0">
                                    Si il y au moins de
                                    <span><ngx-time [duration]="(cond.time | lbMs)" [format]="[ 'hour', 'minute' ]" (onChange)="setTime( $event )"></ngx-time></span>
                                    d'attente
                                </li>
                                <li *ngIf="(cond.time | lbTypeOf ) === 'string' && cond.time === '#actual_fullDate'"> Si le créneau est pour aujorud'hui </li>
                            </ul>
                        </div>

                        <ng-container *ngIf="cond.texts && !(cond.texts | lbIsEmpty)">
                            <ngx-input-to-translation *ngIf="cond.texts" (onChange)="cond.texts = $event" [markDown]="false" [showTitle]="true" [data]="cond.texts" [edit]="true" [showAddAndDelete]="true"></ngx-input-to-translation>
                        </ng-container>
                    </nb-card-body>
                </nb-card>

                <div *ngIf="textsToShow && textsToShow.length > 0" class="translationsContainer">
                    <br /><br />
                    <div *ngFor="let textData of textsToShow">
                        <nb-card class="translationCard">
                            <nb-card-body>
                                <div>
                                    <div> {{ textData.title | lbTranslateFromObject:{lang: locale.language} }} </div>
                                    <br />
                                    <ngx-input-to-translation (onChange)="timeConfig.texts[textData.name] = $event" [markDown]="false" [showTitle]="true" [data]="timeConfig.texts[textData.name] ? timeConfig.texts[textData.name] : { 'fr_FR': '' }" [edit]="true" [showAddAndDelete]="true"></ngx-input-to-translation>
                                </div>
                            </nb-card-body>
                        </nb-card>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>

        <br /><br />
    </nb-card-body>
    <nb-card-footer class="actionsButtonContainer">
        <button class="cancel align-center" nbButton status="danger" (click)="cancel()">Annuler</button>
        <button nbButton status="success" (click)="submit()">Valider</button>
    </nb-card-footer>
</nb-card>
