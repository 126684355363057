import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { AllAccessRolesFromUrl, UserService } from '../services/user.service'

@Directive({
  selector: '[ngxLbSecurityRole]'
})
export class LbSecurityRoleDirective {

  constructor(private _elementRef : ElementRef,
              private _templateRef: TemplateRef<any>,
              private _viewContainer: ViewContainerRef,
              private userService: UserService
  ) {}

  @Input() set ngxLbSecurityRole( data: { roles: string[], allAccessRoles?: AllAccessRolesFromUrl } ) {
    if ( this.userService.operatorHasAccessFromObjectAndUrl( data.roles, data.allAccessRoles ) ) {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }

}
