import { Inject, Injectable } from '@angular/core'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { ConfigService } from '../config.service'

export type translationVariablesType = 'TICKET_BOOKING' | 'PRINT_TICKET' | 'ALL_BOOKING_TICKETS'

@Injectable({
    providedIn: 'root'
})
export class TranslationVariablesService {

    public ticketBookingContent: { icon?: string, value: string, name: string }[] = [
        { icon: 'home', value: 'place.name', name: 'services_utils_translationVariables_ticketBooking_placeName' },
        { icon: 'map-marker-alt', value: 'queue.name', name: 'services_utils_translationVariables_ticketBooking_queueName' },
        { icon: 'id-card', value: 'appointmentType.name', name: 'services_utils_translationVariables_ticketBooking_appointmentTypeName' },
        { icon: 'sort-numeric-up', value: 'label', name: 'services_utils_translationVariables_ticketBooking_label' },
        { icon: 'users', value: 'groupSize', name: 'services_utils_translationVariables_ticketBooking_groupSize' },
        { icon: 'calendar-alt', value: 'timeline.estimatedFor | autoComplete', name: 'services_utils_translationVariables_ticketBooking_estimatedFor_autoComplete' },
        { icon: 'hourglass-half', value: 'timeline.estimatedFor | timeLeftLong', name: 'services_utils_translationVariables_ticketBooking_estimatedFor_timeLeft' },
        { icon: 'users', value: 'ticketPosition.global.nbPeople', name: 'services_utils_translationVariables_ticketBooking_globalNbPeople' },
        { icon: 'ticket-alt', value: 'ticketPosition.global.nbTickets', name: 'services_utils_translationVariables_ticketBooking_globalNbTickets' },
        { icon: 'user-tie', value: 'endpoint.name', name: 'services_utils_translationVariables_ticketBooking_endpointName' },
        { icon: 'link', value: 'shortUrl', name: 'services_utils_translationVariables_ticketBooking_shortLink' }
    ]

    public printTicketContent: { icon?: string, value: string, name: string }[] = [
        { icon: 'home', value: 'placeName', name: 'services_utils_translationVariables_ticketBooking_placeName' },
        { icon: 'map-marker-alt', value: 'queueName', name: 'services_utils_translationVariables_ticketBooking_queueName' },
        { icon: 'id-card', value: 'appointmentType', name: 'services_utils_translationVariables_ticketBooking_appointmentTypeName' },
        { icon: 'sort-numeric-up', value: 'label', name: 'services_utils_translationVariables_ticketBooking_label' },
        { icon: 'users', value: 'groupSize', name: 'services_utils_translationVariables_ticketBooking_groupSize' },
        { icon: 'calendar-alt', value: 'bookedFor | date', name: 'services_utils_translationVariables_ticketBooking_estimatedFor_autoComplete' },
        { icon: 'hourglass-half', value: 'bookedFor | time', name: 'services_utils_translationVariables_ticketBooking_estimatedFor_timeLeft' },
        { icon: 'id-card', value: 'name', name: 'services_utils_translationVariables_ticketBooking_clientName' }
    ]

    public allBookingContent: { icon?: string, value: string, name: string }[] = [
        { icon: 'home', value: 'company.name.fr_FR', name: 'services_utils_translationVariables_allBookingContent_company_companyName' },
        { icon: 'home', value: 'place.name.fr_FR', name: 'services_utils_translationVariables_allBookingContent_place_placeName' },
        { icon: 'map-marker-alt', value: 'queue.name.fr_FR', name: 'services_utils_translationVariables_allBookingContent_queue_queueName' },

        { icon: 'home', value: 'ticket.company.name', name: 'services_utils_translationVariables_allBookingContent_ticket_companyName' },
        { icon: 'home', value: 'ticket.place.name', name: 'services_utils_translationVariables_allBookingContent_ticket_placeName' },
        { icon: 'map-marker-alt', value: 'ticket.queue.name', name: 'services_utils_translationVariables_allBookingContent_ticket_queueName' },
        { icon: 'id-card', value: 'ticket.appointmentType.name', name: 'services_utils_translationVariables_allBookingContent_ticket_appointmentTypeName' },
        { icon: 'sort-numeric-up', value: 'ticket.label', name: 'services_utils_translationVariables_allBookingContent_ticket_label' },
        { icon: 'users', value: 'ticket.groupSize', name: 'services_utils_translationVariables_allBookingContent_ticket_groupSize' },
        { icon: 'calendar-alt', value: 'ticket.timeline.estimatedFor | autoComplete', name: 'services_utils_translationVariables_allBookingContent_ticket_estimatedFor_autoComplete' },
        { icon: 'hourglass-half', value: 'ticket.timeline.estimatedFor | timeLeftLong', name: 'services_utils_translationVariables_allBookingContent_ticket_estimatedFor_timeLeft' },
        { icon: 'users', value: 'ticket.ticketPosition.global.nbPeople', name: 'services_utils_translationVariables_allBookingContent_ticket_globalNbPeople' },
        { icon: 'ticket-alt', value: 'ticket.ticketPosition.global.nbTickets', name: 'services_utils_translationVariables_allBookingContent_ticket_globalNbTickets' },
        { icon: 'user-tie', value: 'ticket.endpoint.name', name: 'services_utils_translationVariables_allBookingContent_ticket_endpointName' },
        { icon: 'phone', value: 'ticket.extendedAttributes.phone', name: 'services_utils_translationVariables_allBookingContent_ticket_phone' },
        { icon: 'at', value: 'ticket.extendedAttributes.mail', name: 'services_utils_translationVariables_allBookingContent_ticket_mail' },
        { icon: 'database', value: 'ticket.extendedAttributes.toto', name: 'services_utils_translationVariables_allBookingContent_ticket_extendedAttributes' },
        { icon: 'language', value: 'ticket.lang', name: 'services_utils_translationVariables_allBookingContent_ticket_lang' },
        { icon: 'ticket-alt', value: 'ticket.extraInfos.source.ticketType', name: 'services_utils_translationVariables_allBookingContent_ticket_ticketType' },
        { icon: 'stopwatch', value: 'ticket.duration', name: 'services_utils_translationVariables_allBookingContent_ticket_duration' }
    ]

    constructor(
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        private configService: ConfigService
    ) { }

    get( type: translationVariablesType ) {
        if ( type === 'TICKET_BOOKING' ) {
            return JSON.parse( JSON.stringify( this.ticketBookingContent ) )
        } else if ( type === 'PRINT_TICKET' ) {
            return JSON.parse( JSON.stringify( this.printTicketContent ) )
        } else if ( type === 'ALL_BOOKING_TICKETS' ) {
            return JSON.parse( JSON.stringify( this.allBookingContent ) )
        }
    }

}
