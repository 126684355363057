import { Injectable } from '@angular/core'
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme'

@Injectable( {
    providedIn: 'root'
} )
export class NotificationsService {

    private readonly maxLineSize: number = 45

    constructor (
        private toastrService: NbToastrService
    ) {
    }

    public showNotif ( success: boolean, title: string, text: string, style?: string ): void {

        let displayFullText: boolean = false
        let status: NbComponentStatus
        let notificationDuration: number
        if ( success ) {
            status = 'success'
            notificationDuration = 3000
        } else {
            status = 'danger'
            notificationDuration = 10000
        }

        const config: any = {
            status: status,
            destroyByClick: true,
            duration: notificationDuration,
            hasIcon: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
            preventDuplicates: false
        }

        if ( style ) {
            if ( style === 'fullText' ) {
                config.toastClass = 'fullText'
                displayFullText = true
            }
        }

        let textToDisplay = text
        if ( !displayFullText && textToDisplay.length > this.maxLineSize ) {
            textToDisplay = textToDisplay.slice( 0, (this.maxLineSize - 3) ) + '...'
        }

        this.toastrService.show(
            textToDisplay,
            title,
            config
        )
    }
}
