import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CopyToClipboardDirective } from './copy-to-clipboard.directive'
import { LbSecurityRoleDirective } from './lb-security-role.directive'

@NgModule( {
    imports: [CommonModule],
    exports: [
        CopyToClipboardDirective,
        LbSecurityRoleDirective
    ],
    declarations: [
        CopyToClipboardDirective,
        LbSecurityRoleDirective
    ]
} )
export class DirectivesModule {
}
