<dx-html-editor [value]="_value" (valueChange)="_value = $event; emit()" valueType="markdown">
    <dxo-toolbar [multiline]="false">
        <dxi-item name="undo"></dxi-item>
        <dxi-item name="redo"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="bold"></dxi-item>
        <dxi-item name="italic"></dxi-item>
        <dxi-item name="strike"></dxi-item>
        <dxi-item name="underline"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="orderedList"></dxi-item>
        <dxi-item name="bulletList"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
        <!--<dxi-item name="separator"></dxi-item>
        <dxi-item name="extendedImage"></dxi-item>-->
        <dxi-item name="separator"></dxi-item>
        <dxi-item widget="dxButton" [options]="toolbarButtonOptions"></dxi-item>
    </dxo-toolbar>
</dx-html-editor>

<dx-popup [showTitle]="true" title="Markup" [(visible)]="popupVisible">
    <div>{{_formatedValue}}</div>
</dx-popup>
