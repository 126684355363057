import { Injectable } from '@angular/core'
import { AppBookingQueueConfiguration, AppointmentType } from 'lb-types'
import { LbUtilsService } from 'lb-utils-front/dist'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { forkJoin, Observable, of } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import * as _ from 'lodash'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { QueuesConfigurationService } from './queues-configuration.service'

const MAX_QUEUES_CONFIG_TO_LOAD = 10

@Injectable({
  providedIn: 'root'
})
export class AppointmenttypesService {

  private queuesAppointmentTypes: { [queueId: string]: { [appointmentTypeId: string]: AppointmentType } } = {}
  private listOfQueuesIdLoaded: string[] = []

  constructor(
      private queuesConfigurationService: QueuesConfigurationService,
      private lbUtilsService: LbUtilsService,
      private httpService: HttpService,
      private configService: ConfigService
  ) { }

  public getAppointmentTypes ( queueId: string ): Observable<{ [appointmentTypeId: string]: AppointmentType }> {
    if ( this.queuesAppointmentTypes && this.queuesAppointmentTypes[ queueId ] ) {
      return of( _.cloneDeep(this.queuesAppointmentTypes[ queueId ]) )
    } else {
      return this.httpService.get(
          this.configService.httpUrl.queues.getAppointmentTypes, {queuesId: [queueId]}, null
      ).pipe(
          switchMap( ( res: { [queueId: string]: { [appointmentTypeId: string]: AppointmentType } } ) => {
            this.queuesAppointmentTypes[ queueId ] = res[ queueId ]
            this.listOfQueuesIdLoaded.push( queueId )
            if ( this.listOfQueuesIdLoaded.length > MAX_QUEUES_CONFIG_TO_LOAD ) {
              delete this.queuesAppointmentTypes[ this.listOfQueuesIdLoaded[ 0 ] ]
              this.listOfQueuesIdLoaded.shift()
            }
            return of( _.cloneDeep( this.queuesAppointmentTypes[ queueId ] ) )
          } ),
          first()
      )
    }
  }

  public getQueuesAppointmentTypes ( queuesId: string[] ): Observable<{ [queueId: string]: {[appointmentTypeId: string]: AppointmentType} }> {
    const res: { [queueId: string]: {[appointmentTypeId: string]: AppointmentType} } = {}
    const queueIdToLoad = []
    for ( const queueId of queuesId ) {
        if ( this.queuesAppointmentTypes && this.queuesAppointmentTypes[ queueId ] ) {
            res[ queueId ] = _.cloneDeep(this.queuesAppointmentTypes[ queueId ])
        } else {
            queueIdToLoad.push( queueId )
        }
    }

    if ( queueIdToLoad.length > 0 ) {
        return this.httpService.get(
            this.configService.httpUrl.queues.getAppointmentTypes, {queuesId: queueIdToLoad}, null
        ).pipe(
            switchMap( ( httpRes: { [queueId: string]: { [appointmentTypeId: string]: AppointmentType } } ) => {
                for ( const queueId in httpRes ) {
                    this.queuesAppointmentTypes[ queueId ] = httpRes[ queueId ]
                    res[ queueId ] = httpRes[ queueId ]
                    this.listOfQueuesIdLoaded.push( queueId )
                    if ( this.listOfQueuesIdLoaded.length > MAX_QUEUES_CONFIG_TO_LOAD ) {
                        delete this.queuesAppointmentTypes[ this.listOfQueuesIdLoaded[ 0 ] ]
                        this.listOfQueuesIdLoaded.shift()
                    }
                }
                return of( res )
            } ),
            first()
        )
    } else {
        return of(res )
    }
  }

  public createAppointmentTypes( params: { [queueId: string]: Partial<AppointmentType>[] } ): Observable<LocalCRUDRes> {
      if ( Object.keys( params ).length > 0 ) {
          return this.httpService.post(
              this.configService.httpUrl.queues.createAppointmentTypes, { queuesId: params }, null, null
          ).pipe(
              switchMap( ( httpRes: HttpCRUDRes ) => {
                  for ( const queueId in httpRes.objectSuccess ) {
                      if ( queueId in httpRes.objectSuccess ) {
                          for ( const appointmentTypeId in httpRes.objectSuccess[ queueId ] ) {
                              if ( appointmentTypeId in httpRes.objectSuccess[ queueId ] ) {
                                  this.queuesAppointmentTypes[queueId][appointmentTypeId] = httpRes.objectSuccess[ queueId ][ appointmentTypeId ]
                              }
                          }
                      }
                  }

                  if ( httpRes.error === 0 && httpRes.success > 0 ) {
                      return of( { success: true } )
                  } else {
                      return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                  }
              })
          )
      } else {
          return of( { success: true } )
      }
  }

  public deleteAppointmentTypes( params: { [queueId: string]: string[] } ): Observable<LocalCRUDRes> {
      if ( Object.keys( params ).length > 0 ) {
          return this.httpService.delete(
              this.configService.httpUrl.queues.deleteAppointmentTypes, { queuesId: params }, null, null
          ).pipe(
              switchMap( ( httpRes: HttpCRUDRes ) => {
                  for ( const queueId in httpRes.objectSuccess ) {
                      if ( queueId in httpRes.objectSuccess ) {
                          for ( const appointmentTypeId in httpRes.objectSuccess[ queueId ] ) {
                              if ( appointmentTypeId in httpRes.objectSuccess[ queueId ] ) {
                                  delete this.queuesAppointmentTypes[queueId][appointmentTypeId]
                              }
                          }
                      }
                  }

                  if ( httpRes.error === 0 && httpRes.success > 0 ) {
                      return of( { success: true } )
                  } else {
                      return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                  }
              })
          )
      } else {
          return of( { success: true } )
      }
  }

  public editAppointmentTypes ( params: { [queueId: string]: { appointmentTypeId: string, appointmentTypesSetSet: Partial<AppointmentType> }[] } ): Observable<LocalCRUDRes> {
      const set = {}

      for ( const queueId in params ) {
          if ( Object.keys( params[ queueId ] ).length > 0 ) {
              for ( const param of params[ queueId ] ) {
                  const finalAppointmentTypesSetSet: any = this.lbUtilsService.diffObject( param.appointmentTypesSetSet, this.queuesAppointmentTypes[ queueId ][ param.appointmentTypeId ] )
                  if ( typeof ( finalAppointmentTypesSetSet.translation ) !== 'undefined' ) { finalAppointmentTypesSetSet.translation = param.appointmentTypesSetSet.translation }
                  if ( typeof ( finalAppointmentTypesSetSet.tTranslation ) !== 'undefined' ) { finalAppointmentTypesSetSet.tTranslation = param.appointmentTypesSetSet.tTranslation }
                  if ( typeof ( finalAppointmentTypesSetSet.eTranslation ) !== 'undefined' ) { finalAppointmentTypesSetSet.eTranslation = param.appointmentTypesSetSet.eTranslation }
                  if ( typeof ( finalAppointmentTypesSetSet.aTranslation ) !== 'undefined' ) { finalAppointmentTypesSetSet.aTranslation = param.appointmentTypesSetSet.aTranslation }
                  if ( typeof ( finalAppointmentTypesSetSet.activated ) !== 'undefined' ) { finalAppointmentTypesSetSet.activated = param.appointmentTypesSetSet.activated }
                  if ( Object.keys( finalAppointmentTypesSetSet ).length > 0 ) {
                      if ( typeof set[ queueId ] === 'undefined' ) { set[ queueId ] = {} }
                      set[ queueId ][ param.appointmentTypeId ] = finalAppointmentTypesSetSet
                  }
              }
          }
      }

      if ( Object.keys( set ).length > 0 ) {
          return this.httpService.put(
              this.configService.httpUrl.queues.setAppointmentTypes, { queuesId: set }, null, null
          ).pipe(
              switchMap( ( httpRes: HttpCRUDRes ) => {
                  for ( const queueId in httpRes.objectSuccess ) {
                      if ( queueId in httpRes.objectSuccess ) {
                          for ( const appointmentTypeId in httpRes.objectSuccess[ queueId ] ) {
                              if ( appointmentTypeId in httpRes.objectSuccess[ queueId ] ) {
                                  this.queuesAppointmentTypes[ queueId ][ appointmentTypeId ] = httpRes.objectSuccess[ queueId ][ appointmentTypeId ]
                              }
                          }
                      }
                  }

                  if ( httpRes.error === 0 && httpRes.success > 0 ) {
                      return of( { success: true } )
                  } else {
                      return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                  }
              })
          )
      } else {
          return of( { success: true } )
      }
  }

  public addEditAndDeleteAppointmentTypes( queueId: string, appointmentTypesSetSet: Partial<AppointmentType> ): Observable<LocalCRUDRes> {
      const currentAppointmentTypes = this.queuesAppointmentTypes[ queueId ]
      const appointmentTypesToAdd = []
      const appointmentTypesToEdit = []
      const appointmentTypesToDelete = []
      for ( const appointmentTypeId in appointmentTypesSetSet ) {
          if ( typeof( currentAppointmentTypes[ appointmentTypeId ] ) !== 'undefined' ) {
              if ( Object.keys( appointmentTypesSetSet[ appointmentTypeId ] ).length > 0 ) {
                  appointmentTypesToEdit.push( { appointmentTypeId: appointmentTypeId, appointmentTypesSetSet: appointmentTypesSetSet[ appointmentTypeId ] } )
              }
          } else if ( Object.keys( appointmentTypesSetSet[ appointmentTypeId ] ).length > 0 ) {
              appointmentTypesToAdd.push( appointmentTypesSetSet[ appointmentTypeId ] )
          }
      }
      for ( const appointmentTypeId in currentAppointmentTypes ) {
          if ( typeof( appointmentTypesSetSet[ appointmentTypeId ] ) === 'undefined' ) {
              appointmentTypesToDelete.push( appointmentTypeId )
          }
      }

      const observablesToCreate: Observable<LocalCRUDRes>[] = []
      const observablesToEdit: Observable<LocalCRUDRes>[] = []
      const observablesToDelete: Observable<LocalCRUDRes>[] = []
      if (appointmentTypesToAdd.length > 0) {
          observablesToCreate.push(this.createAppointmentTypes({ [queueId]: appointmentTypesToAdd }))
      }
      if (appointmentTypesToEdit.length > 0) {
          observablesToEdit.push(this.editAppointmentTypes({ [queueId]: appointmentTypesToEdit }))
      }
      if (appointmentTypesToDelete.length > 0) {
          observablesToDelete.push(this.deleteAppointmentTypes({ [queueId]: appointmentTypesToDelete }))
      }

      let finalRes: LocalCRUDRes = { success: true, nbError: 0, totalElem: 0 }
      if (observablesToCreate.length > 0 || observablesToEdit.length > 0 || observablesToDelete.length > 0) {
          let observablesToCreateRes: Observable<LocalCRUDRes[]>
          let observablesToEditRes: Observable<LocalCRUDRes[]>
          let observablesToDeleteRes: Observable<LocalCRUDRes[]>

          observablesToDeleteRes = observablesToDelete.length > 0 ?
              forkJoin(observablesToDelete) : of([{ success: true }])
          observablesToCreateRes = observablesToCreate.length > 0 ?
              forkJoin(observablesToCreate) : of([{ success: true }])
          observablesToEditRes = observablesToEdit.length > 0 ?
              forkJoin(observablesToEdit) : of([{ success: true }])

          return observablesToDeleteRes.pipe(
              switchMap((resDelete: LocalCRUDRes[]): Observable<LocalCRUDRes[]> => {
                  finalRes = JSON.parse(JSON.stringify(this.handleObservablesResponses(finalRes, resDelete)))
                  return observablesToCreateRes
              }),
              switchMap((resCreate: LocalCRUDRes[]): Observable<LocalCRUDRes[]> => {
                  finalRes = JSON.parse(JSON.stringify(this.handleObservablesResponses(finalRes, resCreate)))
                  return observablesToEditRes
              }),
              switchMap((resEdit: LocalCRUDRes[]): Observable<boolean | LocalCRUDRes> => {
                  finalRes = JSON.parse(JSON.stringify(this.handleObservablesResponses(finalRes, resEdit)))
                  return this.checkLabelMakerNeedUpdate(queueId)
              }),
              switchMap((): Observable<LocalCRUDRes> => {
                  return of(finalRes)
              })
          )
      }
      return of(finalRes)
  }

    private handleObservablesResponses(finalRes: LocalCRUDRes, res: LocalCRUDRes[]): LocalCRUDRes {
        res.map((response) => {
            if (!response.success) {
                finalRes.success = response.success
            }
            if (response.nbError) {
                finalRes.nbError += response.nbError
            }
            if (response.totalElem) {
                finalRes.totalElem += response.totalElem
            }
        })
        return finalRes
    }

  // Check if we need to add or delete appointmentType in label maker pseudo code
  private checkLabelMakerNeedUpdate ( queueId ) {
      let ap: { [appointmentTypeId: string]: AppointmentType }
      return this.getAppointmentTypes( queueId ).pipe(
          switchMap( (apRes: { [appointmentTypeId: string]: AppointmentType }) => {
              ap = apRes
              return this.queuesConfigurationService.getAppBookingQueueConfiguration( queueId )
          }),
          switchMap( ( config: AppBookingQueueConfiguration ) => {
              if (
                  config.labelGeneratorVersion === 3
                  && config.labelGeneratorConfig
                  && config.labelGeneratorConfig.typeOfConfiguration
                  && config.labelGeneratorConfig.typeOfConfiguration === 'letter_by_appointmentTypes'
              ) {
                  const tmpLetters = this.extractLettersByAppointmentTypeFromPseudoCode( config.labelGeneratorConfig )
                  const finalLetters: {[appointmentTypeId: string]: string} = {}

                  for ( const apId in ap ) {
                      if ( typeof( tmpLetters[ apId ] ) !== 'undefined' ) {
                          finalLetters[ apId ] = tmpLetters[ apId ]
                      } else {
                          finalLetters[ apId ] = 'A'
                      }
                  }
                  const beforeConfig = JSON.parse( JSON.stringify( config.labelGeneratorConfig ) )
                  config.labelGeneratorConfig = this.generateLetterLabelPseudoCode( config.labelGeneratorConfig, finalLetters)

                  if ( beforeConfig !== JSON.parse( JSON.stringify( config.labelGeneratorConfig ) ) ) {
                    return this.queuesConfigurationService.editAppBookingQueueConfiguration( [ { queueId: queueId, queueConfigurationSet: { labelGeneratorConfig: config.labelGeneratorConfig } }] )
                  } else {
                      return of( true )
                  }
              } else {
                  return of( true )
              }
          })
      )
  }

  public extractLettersByAppointmentTypeFromPseudoCode( labelConfig ) {
      const tmpLetters = {}
      if ( labelConfig && labelConfig.textPart ) {
          if (
              labelConfig.textPart['#if']
              && labelConfig.textPart['#if']['#cond']
              && labelConfig.textPart['#if']['#cond']['#ticket:appointmentTypeObject:id']
              && labelConfig.textPart['#if']['#cond']['#ticket:appointmentTypeObject:id']['#value']
              && labelConfig.textPart['#if']['#then']
              && labelConfig.textPart['#if']['#then']['#return']
          ) {
              const ap = labelConfig.textPart['#if']['#cond']['#ticket:appointmentTypeObject:id']['#value']
              const letter = labelConfig.textPart['#if']['#then']['#return']
              tmpLetters[ ap ] = letter
          }

          if ( labelConfig.textPart['#elseif'] ) {
              for ( const elem of labelConfig.textPart['#elseif'] ) {
                  if (
                      elem
                      && elem['#cond']
                      && elem['#cond']['#ticket:appointmentTypeObject:id']
                      && elem['#cond']['#ticket:appointmentTypeObject:id']['#value']
                      && elem['#then']
                      && elem['#then']['#return']
                  ) {
                      const ap = elem['#cond']['#ticket:appointmentTypeObject:id']['#value']
                      const letter = elem['#then']['#return']
                      tmpLetters[ ap ] = letter
                  }
              }
          }
      }
      return tmpLetters
  }

  public generateLetterLabelPseudoCode( config: any, letterByAppointmentType: {[appointmentTypeId: string]: string} ) {
      let counter = 0
      config.textPart = {}
      for ( const apId in letterByAppointmentType ) {
          const cond = {
              '#cond' : {
                  '#ticket:appointmentTypeObject:id' : {
                      '#op' : '#eq',
                      '#value' : apId
                  }
              },
              '#then' : { '#return' : letterByAppointmentType[ apId ] }
          }

          if ( counter === 0) {
              config.textPart['#if'] = cond
          } else if ( counter === 1) {
              config.textPart['#elseif'] = [ cond ]
          } else {
              config.textPart['#elseif'].push( cond )
          }

          counter++
      }
      return config
  }

}
