import { Component } from '@angular/core'

@Component( {
    selector: 'ngx-footer',
    styleUrls: ['./footer.component.scss'],
    template: `
        <ng-template #help>
            <table class="helpTable">
                <tr>
                    <td class="tdContainer"> <i class="colorError fas fa-exclamation"></i> </td>
                    <td>Il y a une incohérence sur la ressource</td>
                </tr>
                <tr>
                    <td class="tdContainer"> <i class="eyeColor far fa-eye"></i> </td>
                    <td>Afficher le détail de la ressource</td>
                </tr>
                <tr>
                    <td class="tdContainer"> <nb-icon icon="more-vertical-outline"></nb-icon> </td>
                    <td>Afficher la liste des actions possible sur la ressource</td>
                </tr>
                <tr>
                    <td class="tdContainer text-disabled"> texte grisé </td>
                    <td>Action non disponible actuellement</td>
                </tr>
                <tr>
                    <td class="tdContainer eyeColor"> - </td>
                    <td>Information non renseignée</td>
                </tr>
            </table>
        </ng-template>
        <ng-template #serverState>
            <table class="serverStateTable">
                <tr>
                    <td colspan="2" class="title"> État des serveurs lineberty </td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion de la prise de tickets (appBooking)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion des resources (appAdmin)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion des horaires (appTimetable)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion des données (appReport)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion des points de passage (appEndpoint)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion global des notifications (appPushRouter)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion des notifications SMS (appSmsPusher)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion des notifications android et ios (appNotificationPusher)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>Serveurs de gestion des notifications mail (appMailPusher)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>calcul automatique des reportings (bot reports)</td>
                </tr>
                <tr>
                    <td> <nb-icon class="stateOnIcon clickable" icon="radio-button-off-outline"></nb-icon> </td>
                    <td>lancement automatique des exports (bot exports)</td>
                </tr>
            </table>
        </ng-template>
        <span class="created-by">Créé par <b><a href="https://www.lineberty.com/" target="_blank">©Lineberty</a></b> {{ currentYear }}. Tous droits réservés</span>
        <div class="socials">
            <!-- a href="https://github.com/Lineberty/" target="_blank">
                <nb-icon class="footerIcon" icon="github-outline"></nb-icon>
            </a>
            <a href="https://www.facebook.com/lineberty/" target="_blank">
                <nb-icon class="footerIcon" icon="facebook-outline"></nb-icon>
            </a>
            <a href="https://twitter.com/lineberty?lang=fr" target="_blank">
                <nb-icon class="footerIcon" icon="twitter-outline"></nb-icon>
            </a>
            <a href="https://www.linkedin.com/company/lineberty/" target="_blank">
                <nb-icon class="footerIcon" icon="linkedin-outline"></nb-icon>
            </a -->
            <nb-icon class="iconHelp clickable" [nbPopover]="help" nbPopoverTrigger="hint" nbPopoverPlacement="top" icon="question-mark-circle-outline"></nb-icon>
            <nb-icon class="iconState clickable" [nbPopover]="serverState" nbPopoverTrigger="hint" nbPopoverPlacement="top" icon="radio-button-off-outline"></nb-icon>
        </div>
  `
} )
export class FooterComponent {
    currentYear: number = (new Date()).getFullYear()
}
