import { Pipe, PipeTransform } from '@angular/core'
import { Profile } from 'lb-types'
import { ProfilesService } from '../services/developer-resources/profiles.service'
import { LbTranslationService } from 'lb-utils-front/dist'

@Pipe({
    name: 'profileNameFromId'
})
export class ProfileNameFromIdPipe implements PipeTransform {

    constructor(
        private profilesService: ProfilesService,
        private lbTranslationService: LbTranslationService
    ) {}

    transform(profileId: string, lang?: string): any {
        const profile: Profile | null = this.profilesService.getProfileById( profileId )
        if ( profile ) {
            return this.lbTranslationService.translateFromObject( profile.name, {lang: lang} )
        } else {
            return '-'
        }
    }

}
