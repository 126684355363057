import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DatatableComponent } from './datatable.component'
import { NbActionsModule, NbCardModule, NbCheckboxModule, NbIconModule, NbInputModule, NbListModule, NbPopoverModule, NbSelectModule } from '@nebular/theme'
import { RouterModule } from '@angular/router'
import { LbPipesModule } from 'lb-utils-front/dist'
import { PipesModule } from '../../pipes/pipes.module'

@NgModule( {
    imports: [
        CommonModule,
        RouterModule,
        NbInputModule,
        NbSelectModule,
        NbIconModule,
        NbCardModule,
        NbPopoverModule,
        NbListModule,
        NbActionsModule,
        NbCheckboxModule,
        LbPipesModule,
        PipesModule
    ],
    exports: [
        DatatableComponent
    ],
    declarations: [
        DatatableComponent
    ]
} )
export class DatatableModule {
}
