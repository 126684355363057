import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { NbButtonModule, NbCardModule, NbSelectModule, NbStepperModule, NbTreeGridModule } from '@nebular/theme'
import { SelectProfileRolesComponent } from './select-profile-roles.component'
import { CompaniesPlacesQueuesRolesTreeModule } from '../companies-places-queues-roles-tree/companies-places-queues-roles-tree.module'
import { LbPipesModule } from 'lb-utils-front/dist'

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        NbCardModule,
        NbButtonModule,
        NbTreeGridModule,
        NbSelectModule,
        NbStepperModule,
        CompaniesPlacesQueuesRolesTreeModule,
        LbPipesModule
    ],
    declarations: [
        SelectProfileRolesComponent
    ],
    exports: [
        SelectProfileRolesComponent
    ]
} )
export class SelectProfileRolesModule {
}
