<!-- POPUP ACTIONS ON THE LINE -->
<ng-template #moreActionPopOver>
    <nb-list class="action-list">
        <nb-list-item *ngFor="let action of selectedItemLineActions" (click)="doAction( action.id, action.disabled )">
            <nb-icon [ngClass]="{disabled: action.disabled}" *ngIf="action.icon" icon="{{action.icon}}"></nb-icon>
            <span [ngClass]="{disabled: action.disabled}" *ngIf="action.title">{{action.title}}</span>
        </nb-list-item>
    </nb-list>
</ng-template>

<ng-template #columnsHeaderTemplate let-columns>
    <div *ngFor="let column of columns" class="col-{{column.width}} datatableLineCol" [ngClass]="{'text-center': column.textAlign === 'center', 'text-left': column.textAlign === 'left', 'text-right': column.textAlign === 'right'}">
        <div [ngClass]="{'clickable': column.sortAllowed}" (click)="changeSort( column.id )">
            <span>{{ column.title }}</span>
            <nb-icon *ngIf="isSorted( column.id, 'ASC' )" class="sortIcon down" icon="sort-down" pack="font-awesome"></nb-icon>
            <nb-icon *ngIf="isSorted( column.id, 'DESC' )" class="sortIcon up" icon="sort-up" pack="font-awesome"></nb-icon>
        </div>
        <div *ngIf="column.search">
            <div *ngIf="(!column.selectSearch || column.selectSearch.length <= 0) && (column.type === 'string' || column.type === 'langs' || column.type === 'number') ">
                <ng-container *ngIf="column.searchType === 'text'" >
                    <input (keyup)="setSearch( column.id, $event, 'input' )" [value]="column.searchValue" type="text" nbInput fullWidth />
                </ng-container>
                <div *ngIf="column.searchType === 'select'" class="buttonSelectContainer" >
                    <input readonly="true" (click)="searchSelect( column.id )" [value]="column.searchValue" type="text" nbInput fullWidth />
                    <nb-icon class="arrowIcon" (click)="searchSelect( column.id )" icon="sort-down" pack="font-awesome"></nb-icon>
                </div>
                <div *ngIf="column.searchType === 'textAndSelect'" class="buttonSelectContainer" >
                    <input (keyup)="setSearch( column.id, $event, 'input' )" [value]="column.searchValue" type="text" nbInput fullWidth />
                    <nb-icon (click)="searchSelect( column.id )" icon="list-alt" pack="font-awesome"></nb-icon>
                </div>
                <div *ngIf="column.searchType === 'selectState'" class="buttonSelectContainer" >
                    <input readonly="true" (click)="searchSelect( column.id )" [value]="column.searchValue | selectState:{lang: locale.language}" type="text" nbInput fullWidth />
                    <nb-icon class="arrowIcon" (click)="searchSelect( column.id )" icon="sort-down" pack="font-awesome"></nb-icon>
                </div>
            </div>
            <nb-select *ngIf="(!column.selectSearch || column.selectSearch.length <= 0) && column.type === 'boolean'" [size]="'small'" (selectedChange)="setSearch( column.id, $event, 'select' )" class="select" [selected]="column.searchValue">
                <nb-option [value]="'all'"> Tous </nb-option>
                <nb-option [value]="'yes'"> oui </nb-option>
                <nb-option [value]="'no'"> non </nb-option>
            </nb-select>
            <nb-select *ngIf="column.selectSearch && column.selectSearch.length > 0" [size]="'small'" (selectedChange)="setSearch( column.id, $event, 'select' )" class="select" [selected]="column.searchValue">
                <nb-option [value]="'all'"> Tous </nb-option>
                <nb-option *ngFor="let search of column.selectSearch" [value]="search.value"> {{search.text}} </nb-option>
            </nb-select>
        </div>
    </div>
</ng-template>

<ng-template #dataTable>
    <nb-list>

        <!-- GROUP OF COLUMNS -->
        <nb-list-item *ngIf="globalSettings.showHeader && globalSettings.groupOfColumns && globalSettings.groupOfColumns.length > 0">
            <div class="row row-with-checkbox row-list-item-header">
                <div *ngFor="let groupOfColumn of globalSettings.groupOfColumns" class="col-{{groupOfColumn.width}} datatableLineCol" [ngClass]="{'text-center': groupOfColumn.textAlign === 'center', 'text-left': groupOfColumn.textAlign === 'left', 'text-right': groupOfColumn.textAlign === 'right'}">
                    <span>{{ groupOfColumn.title }}</span>
                </div>
                <div *ngIf="globalSettings.showMoreActions" class="col-lg-1 text-right"></div>
            </div>
        </nb-list-item>

        <!-- COLUMNS HEADER -->
        <nb-list-item *ngIf="globalSettings.showHeader">
            <div class="row row-list-item-header" [ngClass]="{'row-with-checkbox': globalSettings.showMultipleSelection}">
                <nb-checkbox *ngIf="globalSettings.showMultipleSelection" class="list-checkbox clickable" (checkedChange)="selectAll()" [checked]="allSelected"></nb-checkbox>
                <ng-container *ngIf="!globalSettings.groupOfColumns || globalSettings.groupOfColumns.length <= 0">
                    <ng-container [ngTemplateOutlet]="columnsHeaderTemplate" [ngTemplateOutletContext]="{$implicit: globalSettings.columns}"></ng-container>
                </ng-container>
                <ng-container *ngIf="globalSettings.groupOfColumns && globalSettings.groupOfColumns.length > 0">
                    <div *ngFor="let groupOfColumn of globalSettings.groupOfColumns" class="col-{{groupOfColumn.width}} datatableLineCol">
                        <div class="row">
                            <ng-container [ngTemplateOutlet]="columnsHeaderTemplate" [ngTemplateOutletContext]="{$implicit: groupOfColumn.columns}"></ng-container>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="globalSettings.showMoreActions" class="col-lg-1 text-right"></div>
            </div>
        </nb-list-item>

        <!-- TABLE DATA -->
        <nb-list-item (click)="clickOnRow(item, $event )" class="datatableLine" *ngFor="let item of dataToDisplay; let r = index" [ngClass]="{listItemOdd: ((r+1)%2 === 1), clickable: globalSettings.rowClickable}">
            <div class="row dataLineContainer" [ngClass]="{'row-with-checkbox': globalSettings.showMultipleSelection}">
                <span class="nb-checkbox-container">
                    <nb-checkbox *ngIf="globalSettings.showMultipleSelection" class="list-checkbox clickable" (checkedChange)="toggleSelectedItem(item)"
                             [checked]="itemSelected( item )" ></nb-checkbox>
                </span>
                <ng-container *ngIf="!globalSettings.groupOfColumns || globalSettings.groupOfColumns.length <= 0">
                    <div *ngFor="let column of globalSettings.columns" class="col-{{column.width}} datatableLineCol" [ngClass]="{'text-center': column.textAlign === 'center', 'text-left': column.textAlign === 'left', 'text-right': column.textAlign === 'right'}">
                        <ng-template [ngTemplateOutlet]="columnContent[column.indexPosition]" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                    </div>
                </ng-container>
                <ng-container *ngIf="globalSettings.groupOfColumns && globalSettings.groupOfColumns.length > 0">
                    <div *ngFor="let groupOfColumn of globalSettings.groupOfColumns" class="col-{{groupOfColumn.width}} datatableLineCol">
                        <div class="row">
                            <div *ngFor="let column of groupOfColumn.columns" class="col-{{column.width}} datatableLineCol" [ngClass]="{'text-center': column.textAlign === 'center', 'text-left': column.textAlign === 'left', 'text-right': column.textAlign === 'right'}">
                                <ng-template [ngTemplateOutlet]="columnContent[column.indexPosition]" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <nb-icon *ngIf="globalSettings.showMoreActions" (click)="selectItem( item )" [nbPopover]="moreActionPopOver" nbPopoverPlacement="bottom" class="moreActionIcon" icon="more-vertical-outline"></nb-icon>
            </div>
        </nb-list-item>

        <!-- SUM LINE -->
        <nb-list-item *ngIf="globalSettingsData.showSumLine">
            <div class="row" [ngClass]="{'row-with-checkbox': globalSettings.showMultipleSelection}">
                <ng-container *ngIf="!globalSettingsData.groupOfColumns || globalSettingsData.groupOfColumns.length <= 0">
                    <div *ngFor="let column of globalSettingsData.columns" class="col-{{column.width}} datatableLineCol" [ngClass]="{'text-center': column.textAlign === 'center', 'text-left': column.textAlign === 'left', 'text-right': column.textAlign === 'right'}">
                        <span class="bold"> {{ column.columnSum }} </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="globalSettingsData.groupOfColumns && globalSettingsData.groupOfColumns.length > 0">
                    <div *ngFor="let groupOfColumn of globalSettingsData.groupOfColumns" class="col-{{groupOfColumn.width}} datatableLineCol">
                        <div class="row">
                            <div *ngFor="let column of groupOfColumn.columns" class="col-{{column.width}} datatableLineCol" [ngClass]="{'text-center': column.textAlign === 'center', 'text-left': column.textAlign === 'left', 'text-right': column.textAlign === 'right'}">
                                <span class="bold"> {{ column.columnSum }} </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </nb-list-item>
    </nb-list>

    <!-- MESSAGES ON NO DATA -->
    <ng-container *ngIf="!dataFiltered && (dataToDisplay | lbIsEmpty)">
        <h5 class="text-center text-hint noDataTitle">{{globalSettings.messageOnEmptyData}}</h5>
    </ng-container>

    <ng-container *ngIf="dataFiltered && (dataToDisplay | lbIsEmpty)">
        <h5 class="text-center text-hint noDataTitle">{{globalSettings.messageOnEmptySearchResult}}</h5>
    </ng-container>

    <!-- FOOTER -->
    <div class="row arrayInformations">
        <div class="col-3 text-left">
            <div class="numberOfElemeToDisplay" (click)="setElemsToDisplay()" *ngIf="globalSettings.showNumberOfElementsToDisplay">
                <span> {{ elemsToDisplay }} </span>
                <nb-icon class="" icon="sort-down" pack="font-awesome"></nb-icon>
            </div>
        </div>
        <div class="col-6 text-center">
            <table class="paginationTable" *ngIf="showPagination">
                <tr>
                    <td *ngFor="let page of paginationPages" (click)="setPage(page.value)" [ngClass]="{disabled: page.disabled, selected: page.selected}"> {{ page.name }} </td>
                </tr>
            </table>
        </div>
        <div class="col-3 text-right">
            <div *ngIf="globalSettings.showTotalNumberOfElements && !dataFiltered" class="counter"> {{elemenDisplayedNumber.start}} - {{elemenDisplayedNumber.end}} sur {{elemenDisplayedNumber.total}} éléments </div>
            <div *ngIf="globalSettings.showTotalNumberOfElements && dataFiltered" class="counter"> {{elemenDisplayedNumber.total}} résultats </div>
        </div>
    </div>
</ng-template>

<!-- TABLE CONTAINER -->
<ng-container *ngIf="globalSettings.dataTableInNbCard">
    <div class="row withoutMargin">
        <div class="col-lg-12">
            <nb-card class="list-card">
                <nb-card-header>
                    <div class="row">
                        <div class="col-lg-11">
                            {{globalSettings.title}} {{globalSettings.showTotalNumberOfElementsInTitle ? ' (' + elemenDisplayedNumber.total + ')' : ''}}
                        </div>
                        <div *ngIf="globalSettings.showMinimiseButton" (click)="setExpanded()" class="col-lg-1 text-right iconExpanded clickable">
                            <nb-icon *ngIf="!isExpanded" icon="plus" pack="font-awesome"></nb-icon>
                            <nb-icon *ngIf="isExpanded" icon="minus" pack="font-awesome"></nb-icon>
                        </div>
                    </div>
                </nb-card-header>
                <nb-card-body>
                    <ng-container *ngIf="isExpanded" [ngTemplateOutlet]="dataTable"></ng-container>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</ng-container>

<div class="datatableNotInNbCard" *ngIf="!globalSettings.dataTableInNbCard">
    <ng-container [ngTemplateOutlet]="dataTable"></ng-container>
</div>

<!-- MULTIPLE ACTIONS -->
<div *ngIf="globalSettings.showMultipleSelection && selectedItems.length > 0" class="row withoutMargin">
    <div class="col-md-12">
        <nb-card>
            <nb-card-body>
                <nb-actions size="small" fullWidth>
                    <nb-action class="clickable" class="clickable" *ngFor="let action of globalSettings.multipleLineAction" (click)="doMultipleAction(action.id, action.disabled)">
                        <nb-icon *ngIf="action.icon" class="action-icon" icon="{{action.icon}}"></nb-icon>
                        <span [ngClass]="{disabled: action.disabled}" *ngIf="action.title">{{action.title}}</span>
                    </nb-action>
                </nb-actions>
            </nb-card-body>
        </nb-card>
    </div>
</div>
