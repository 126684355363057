import { Injectable } from '@angular/core'
import { CompaniesPlacesQueuesService } from './companies-places-queues.service'
import { LbUtilsService } from 'lb-utils-front/dist'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { Observable, of } from 'rxjs'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { first, switchMap } from 'rxjs/operators'
import * as _ from 'lodash'
import { Company, Place, PlaceConfiguration } from 'lb-types/dist'

const MAX_PLACEs_CONFIG_TO_LOAD = 50

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

    private placesConfig: { [placeId: string]: PlaceConfiguration } = {}
    private listOfPlacesIdLoaded: string[] = []

  constructor (
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
      private lbUtilsService: LbUtilsService,
      private httpService: HttpService,
      private configService: ConfigService
  ) {
  }

  public createPlaces( params: Partial<Place>[] ): Observable<LocalCRUDRes> {
      if ( Object.keys( params ).length > 0 ) {
          return this.httpService.post(
              this.configService.httpUrl.places.createPlaces, { places: params }, null, null
          ).pipe(
              switchMap( ( httpRes: HttpCRUDRes ) => {
                  for ( const placeId in httpRes.objectSuccess ) {
                      if ( placeId in httpRes.objectSuccess ) {
                          this.companiesPlacesQueuesService.addLocalPlace( httpRes.objectSuccess[placeId].companyId, placeId, httpRes.objectSuccess[placeId] )
                      }
                  }

                  if ( httpRes.error === 0 && httpRes.success > 0 ) {
                      return of( { success: true, res: httpRes.objectSuccess } )
                  } else {
                      return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                  }
              })
          )
      } else {
          return of( { success: true } )
      }
  }

    public getPlaceConfiguration ( placeId: string ): Observable<PlaceConfiguration> {
        if ( this.placesConfig && this.placesConfig[ placeId ] ) {
            return of( this.placesConfig[ placeId ] )
        } else {
            return this.httpService.get(
                this.configService.httpUrl.places.getPlacesConfig, {placesId: [placeId]}, null
            ).pipe(
                switchMap( ( res: { [placeId: string]: PlaceConfiguration } ) => {
                    this.placesConfig[ placeId ] = res[ placeId ]
                    this.listOfPlacesIdLoaded.push( placeId )
                    if ( this.listOfPlacesIdLoaded.length > MAX_PLACEs_CONFIG_TO_LOAD ) {
                        delete this.placesConfig[ this.listOfPlacesIdLoaded[ 0 ] ]
                        this.listOfPlacesIdLoaded.shift()
                    }
                    return of( _.cloneDeep( this.placesConfig[ placeId ] ) )
                } ),
                first()
            )
        }
    }

    public editPlaceConfiguration ( params: { placeId: string, placeConfigurationSet: Partial<PlaceConfiguration> }[] ): Observable<LocalCRUDRes> {
        const set = {}

        for ( const param of params ) {
            const queueConfigurationSet: any = this.lbUtilsService.diffObject( param.placeConfigurationSet, this.placesConfig[ param.placeId ] )
            set[ param.placeId ] = queueConfigurationSet
        }

        return this.httpService.put(
            this.configService.httpUrl.places.setPlacesConfig,
            { placesId: set }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const placeId in httpRes.objectSuccess ) {
                    if ( placeId in httpRes.objectSuccess ) {
                        this.placesConfig[placeId] = _.cloneDeep( httpRes.objectSuccess[placeId] )
                    }
                }

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true, res: httpRes.objectSuccess } )
                }
                else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                }
            })
        )
    }

  public deletePlaces ( params: string[] ): Observable<LocalCRUDRes> {

    return this.httpService.delete(
        this.configService.httpUrl.places.deletePlaces, { places: params }, null, null
    ).pipe(
        switchMap( ( httpRes: HttpCRUDRes ) => {
          for ( const placeId in httpRes.objectSuccess ) {
            if ( placeId in httpRes.objectSuccess ) {
              this.companiesPlacesQueuesService.deleteLocalPlace( placeId )
            }
          }
          if ( httpRes.error === 0 && httpRes.success > 0 ) {
            return of( { success: true } )
          } else {
            return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
          }
        } )
    )
  }

  public editPlaces ( params: { placeId: string, placeSet: any }[] ): Observable<LocalCRUDRes> {

    const set = {}
    for ( const k in params ) {
      if ( k in params ) {
        const actualPlace = this.companiesPlacesQueuesService.getPlaceById( params[k].placeId )
        if ( actualPlace ) {
          const placeSet: any = this.lbUtilsService.diffObject( params[k].placeSet, actualPlace )
          if ( typeof ( placeSet.accessCodes ) !== 'undefined' ) { placeSet.accessCodes = params[k].placeSet.accessCodes }

          if ( placeSet && Object.keys( placeSet ).length > 0 ) {
            set[params[k].placeId] = placeSet
          }
        }
      }
    }

    if ( set && Object.keys( set ).length > 0 ) {
      return this.httpService.put( this.configService.httpUrl.places.setPlaces,
          { places: set }, null, null
      ).pipe(
          switchMap( ( httpRes: HttpCRUDRes ) => {
            for ( const placeId in httpRes.objectSuccess ) {
              if ( placeId in httpRes.objectSuccess ) {
                this.companiesPlacesQueuesService.updateLocalPlace( placeId, httpRes.objectSuccess[placeId] )
              }
            }

            if ( httpRes.error === 0 && httpRes.success > 0 ) {
              return of( { success: true } )
            }
            else {
              return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
            }
          })
      )
    } else {
        return of( { success: true } )
    }
  }
}
