import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { LocalStorageService } from './local-storage.service'

const GLOBAL_NAVIGATION_KEY = 'lb_navigation_history'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private userPages: {
    'config': string[],
    'developers': string[],
    'infos': string[],
    'allPages': string[],
    'byCompany': {[companyId: string]: string},
    'byPlace': {[placeId: string]: string},
    'byQueue': {[queueId: string]: string}
  }

  constructor(
      private router: Router,
      private localStorageService: LocalStorageService
  ) {
    const userPages = this.localStorageService.get( GLOBAL_NAVIGATION_KEY )
    if ( userPages && Object.keys( userPages ).length > 0 ) {
      this.userPages = userPages
    } else {
      this.userPages = {
        'config': [],
        'developers': [],
        'infos': [],
        'allPages': [],
        'byCompany': {},
        'byPlace': {},
        'byQueue': {}
      }
    }
    /**
     * On each navigation we save the history
     */
    router.events.subscribe(e => {
      // console.log( 'ROUTE EVENT' )
      // console.log( e )
      if (e && e instanceof NavigationEnd) {
        return this.treatNavigationUrl( this.router.url )
      }
    })
  }

  public treatNavigationUrl ( url: string ) {
    if ( url.match(/^\/developers\//) ) {
      this.addNewUrl( 'developers', url )
      this.addNewUrl( 'allPages', url )
    } else if ( url.match(/^\/config\//) ) {
      this.addNewUrl( 'config', url )
      this.addNewUrl( 'allPages', url )
      if ( url && url.match( /.+\/companies\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ ) ) {
        const companyId = url.match( /.+\/companies\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ )[1]
        this.userPages.byCompany[ companyId ] = url

      } else if ( url && url.match( /.+\/places\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ ) ) {
        const placeId = url.match( /.+\/places\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ )[1]
        this.userPages.byPlace[ placeId ] = url

      } else if ( url && url.match( /.+\/queues\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ ) ) {
        const queueId = url.match( /.+\/queues\/([a-zA-Z0-9\-\_]+)\/[a-zA-Z]+/ )[1]
        this.userPages.byQueue[ queueId ] = url
      }
    } else if ( url.match(/^\/infos\//) ) {
      this.addNewUrl( 'infos', url )
      this.addNewUrl( 'allPages', url )
    } else if ( url.match(/^\/auth\//) ) {
      // this.userConnected = false
    }
  }

  /**
   * On each navigation, we save the last 15 visited page
   * @param globalPage - the current global display
   * @param url - the real url
   */
  private addNewUrl( globalPage: string, url: string ) {
    // if no history => push the new url
    if ( this.userPages[ globalPage ].length === 0 ) {
      this.userPages[ globalPage ].push( url )

    // If the user only change query params, we replace the last url by the new with the new query params
    } else if ( this.userPages[ globalPage ].length > 0 && url.split('?')[0] === this.userPages[ globalPage ][ 0 ].split('?')[0] ) {
      this.userPages[ globalPage ][ 0 ] = url

    // If the user just reload the same page => do nothing
    } else if ( this.userPages[ globalPage ].length > 0 && url === this.userPages[ globalPage ][ 0 ] ) {
      // do nothing

    // In the other case we add the new URL at the first position
    } else {
      this.userPages[ globalPage ].unshift( url )
    }

    // We keep only the 15 last page in history to limit the size
    if ( this.userPages[ globalPage ].length > 15 ) {
      this.userPages[ globalPage ].splice(14, this.userPages[ globalPage ].length )
    }

    // We save the history in the localStorage
    this.localStorageService.set( GLOBAL_NAVIGATION_KEY, this.userPages )
  }

  /**
   * While loggin, we want to return to the last visited page before being deconnected. This function return the url
   */
  public onLogin() {
    if ( this.userPages.allPages.length > 0 ) {
      this.navigateToUrl( this.userPages.allPages[0] )
    } else {
      this.router.navigate( [ 'config/settings/queues' ] )
    }
  }

  /**
   * Return a boolean that indicate if we can display a back button to go to the next visited page (if exist)
   */
  public goBackAvailable(): boolean {
    const currentUrl = this.userPages.allPages.length > 0 ? this.userPages.allPages[0] : null
    const lastUrl = this.userPages.allPages.length > 1 ? this.userPages.allPages[1] : null
    return (currentUrl && currentUrl.length > 0 && lastUrl && lastUrl.length > 0)
  }

  /**
   * when switching of global pages : go to the last visited page of the global page
   * @param globalPage - the wanted global display
   */
  public goToLastGlobalPage( globalPage: string ) {
    if ( globalPage && this.userPages[ globalPage ] ) {
      if ( this.userPages[ globalPage ].length > 0 ) {
        this.navigateToUrl( this.userPages[ globalPage ][0] )
      } else {
        this.router.navigate( [ globalPage + '/dashboard' ] )
      }
    } else {
      this.router.navigate( [ 'config/notFound' ] )
    }
  }

  /**
   * When clicking on the link of a company, go to the last queue sub page visited
   * @param companyId - the wanted company ID
   */
  public goToLastCompanyPage( companyId: string ) {
    if ( this.userPages && this.userPages.byCompany && this.userPages.byCompany[ companyId ] ) {
      this.navigateToUrl( this.userPages.byCompany[ companyId ] )
    } else {
      this.router.navigate( [ 'config/settings/companies/' + companyId + '/view' ] )
    }
  }

  /**
   * When clicking on the link of a company, go to the last queue sub page visited
   * @param companyId - the wanted company ID
   */
  public goToLastPlacePage( placeId: string ) {
    if ( this.userPages && this.userPages.byPlace && this.userPages.byPlace[ placeId ] ) {
      this.navigateToUrl( this.userPages.byPlace[ placeId ] )
    } else {
      this.router.navigate( [ 'config/settings/places/' + placeId + '/view' ] )
    }
  }

  /**
   * When clicking on the link of a company, go to the last queue sub page visited
   * @param companyId - the wanted company ID
   */
  public goToLastQueuePage( queueId: string ) {
    if ( this.userPages && this.userPages.byQueue && this.userPages.byQueue[ queueId ] ) {
      this.navigateToUrl( this.userPages.byQueue[ queueId ] )
    } else {
      this.router.navigate( [ 'config/settings/queues/' + queueId + '/view' ] )
    }
  }

  /**
   * Navigate to the last visited page
   */
  public goBack() {
    const currentUrl = this.userPages.allPages.length > 0 ? this.userPages.allPages[0] : null
    const lastUrl = this.userPages.allPages.length > 1 ? this.userPages.allPages[1] : null
    if ( lastUrl ) {
      this.userPages.allPages.shift()
      if ( currentUrl.match(/^\/developers\//) ) {
        this.userPages.developers.shift()
      } else if ( currentUrl.match(/^\/config\//) ) {
        this.userPages.config.shift()
      } else if ( currentUrl.match(/^\/infos\//) ) {
        this.userPages.infos.shift()
      }
      return this.navigateToUrl( lastUrl )
    }
  }

  private navigateToUrl( url: string ) {
    if ( url.match(/.+\?.+/gi) ) {
      const params = url.split('?')[1]
      const paramsArray = params.split('&')
      const queryParams = {}
      for ( const param of paramsArray ) {
        const paramSplit = param.split('=')
        queryParams[ paramSplit[0] ] = paramSplit[1]
      }
      this.router.navigate( [url.split('?')[0]], {queryParams: queryParams} )
    } else {
      this.router.navigate( [url] )
    }
  }
}
