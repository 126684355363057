import { Injectable } from '@angular/core'
import { Observable, of as observableOf } from 'rxjs'
import { Camera, SecurityCamerasData } from '../data/security-cameras'

@Injectable()
export class SecurityCamerasService extends SecurityCamerasData {

    private cameras: Camera[] = []

    getCamerasData (): Observable<Camera[]> {
        return observableOf( this.cameras )
    }
}
