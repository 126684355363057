import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TimeComponent } from './time.component'
import { NbCardModule, NbIconModule, NbInputModule, NbListModule, NbSelectModule } from '@nebular/theme'
import { RouterModule } from '@angular/router'
import { LbPipesModule } from 'lb-utils-front/dist'
import { FormsModule } from '@angular/forms'

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NbInputModule,
        NbSelectModule,
        NbIconModule,
        NbCardModule,
        NbListModule,
        LbPipesModule
    ],
    exports: [
        TimeComponent
    ],
    declarations: [
        TimeComponent
    ]
} )
export class TimeModule {
}
