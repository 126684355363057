<div class="pageContainer">
    <div class="row withoutMargin">
        <div class="col-12 firstCol lastCol">
            <nb-card>
                <nb-tabset>
                    <nb-tab *ngIf="availablePage === 'company' && companyExists()" tabTitle="Entreprises">
                        <div class="datatableContainer">
                            <ngx-datatable [settings]="settingsCompany" (onRowClick)="selectCompany( $event )" [data]="dataCompany" [columnContent]="[columnLogo, columnCompanyName]" (onItemsSelected)="onCompaniesSelected( $event )" (onMultipleRowAction)="multipleAction($event)">
                                <ng-template #columnLogo let-item>
                                    <img class="logoImageInList" *ngIf="item.value.logo" src="{{item.value.logo}}" alt="company logo"/>
                                </ng-template>
                                <ng-template #columnCompanyName let-item>
                                    <div class="text-link datatableDiv" *ngIf="item.value.name">{{item.value.name | lbTranslateFromObject:{lang: locale.language} | lbCapitalize:'FIRST' }}</div>
                                </ng-template>
                            </ngx-datatable>
                        </div>

                    </nb-tab>
                    <nb-tab *ngIf="availablePage !== 'queue' && placeExists()" tabTitle="Lieux">
                        <div class="datatableContainer">
                            <ngx-datatable [settings]="settingsPlace" (onRowClick)="selectPlace( $event )" [data]="dataPlace" [columnContent]="[columnLogo, columnCompanyName, columnPlaceName]" (onItemsSelected)="onPlacesSelected( $event )" (onMultipleRowAction)="multipleAction($event)">
                                <ng-template #columnLogo let-item>
                                    <img class="logoImageInList" *ngIf="item.value.logo" src="{{item.value.logo}}" alt="company logo"/>
                                </ng-template>
                                <ng-template #columnCompanyName let-item>
                                    <div class="text-link datatableDiv" *ngIf="item.value.companyName">{{item.value.companyName && item.value.companyName['fr_FR'] ? (item.value.companyName['fr_FR'].toLowerCase() | lbCapitalize:'FIRST') : '-'}}</div>
                                </ng-template>
                                <ng-template #columnPlaceName let-item>
                                    <div class="text-link datatableDiv" *ngIf="item.value.name">{{item.value.name && item.value.name['fr_FR'] ? (item.value.name['fr_FR'].toLowerCase() | lbCapitalize:'FIRST') : '-'}}</div>
                                </ng-template>
                            </ngx-datatable>
                        </div>

                    </nb-tab>
                    <nb-tab *ngIf="queueExists()" tabTitle="Files">
                        <div class="datatableContainer">
                            <ngx-datatable [settings]="settingsQueue" (onRowClick)="selectQueue( $event )" [data]="dataQueue" [columnContent]="[columnLogo, columnCompanyName, columnPlaceName, columnQueueName]" (onItemsSelected)="onQueuesSelected( $event )" (onMultipleRowAction)="multipleAction($event)">
                                <ng-template #columnLogo let-item>
                                    <img class="logoImageInList" *ngIf="item.value.logo" src="{{item.value.logo}}" alt="company logo"/>
                                </ng-template>
                                <ng-template #columnCompanyName let-item>
                                    <div [ngClass]="{'text-disabled': item.value.disabled}" class="text-link datatableDiv" *ngIf="item.value.companyName">{{item.value.companyName && item.value.companyName['fr_FR'] ? (item.value.companyName['fr_FR'].toLowerCase() | lbCapitalize:'FIRST') : '-'}}</div>
                                </ng-template>
                                <ng-template #columnPlaceName let-item>
                                    <div [ngClass]="{'text-disabled': item.value.disabled}" class="text-link datatableDiv" *ngIf="item.value.placeName">{{item.value.placeName && item.value.placeName['fr_FR'] ? (item.value.placeName['fr_FR'].toLowerCase() | lbCapitalize:'FIRST') : '-'}}</div>
                                </ng-template>
                                <ng-template #columnQueueName let-item>
                                    <div [ngClass]="{'text-disabled': item.value.disabled}" class="text-link datatableDiv" *ngIf="item.value.name">{{item.value.name && item.value.name['fr_FR'] ? (item.value.name['fr_FR'].toLowerCase() | lbCapitalize:'FIRST') : '-'}}</div>
                                </ng-template>
                            </ngx-datatable>
                        </div>
                    </nb-tab>
                </nb-tabset>
            </nb-card>
        </div>
    </div>

</div>
