import { Injectable } from '@angular/core'
import { forkJoin, Observable, of } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import * as _ from 'lodash'
import { Langs, Profile } from 'lb-types'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { LbUtilsService } from 'lb-utils-front/dist'
import { NotificationsService } from '../utils/notifications.service'
import { CacheService } from '../utils/cache.service'
import { OperatorsService } from './operators.service'

@Injectable( {
    providedIn: 'root'
} )
export class ProfilesService {

    private profilesLoaded: boolean = false
    private profiles: { [profileId: string]: Profile } = {}

    constructor (
        private cacheService: CacheService,
        private httpService: HttpService,
        private configService: ConfigService,
        private lbUtilsService: LbUtilsService,
        private operatorsService: OperatorsService,
        private notificationsService: NotificationsService
    ) {
    }

    public getProfiles (): Observable<{ [profileId: string]: Profile }> {
        if ( this.profilesLoaded ) {
            return of( _.cloneDeep(this.profiles) )
        }
        else {
            return this.httpService.get(
                this.configService.httpUrl.profiles.getProfiles, null, null
            ).pipe(
                switchMap( ( res: { [profileId: string]: Profile } ) => {
                    this.profilesLoaded = true
                    this.profiles = res
                    return of( _.cloneDeep( this.profiles ) )
                } ),
                first()
            )
        }
    }

    public getProfileById( profileId: string ): Profile | null {
        if ( this.profiles  && this.profiles[ profileId ] ) {
            return _.cloneDeep(this.profiles[ profileId ])
        } else {
            return null
        }
    }

    /* 
    Ancienne version qui ne renvyait pas un observable
    public getProfilesList( excludeProfileId?: string[] | null, filterDevAccountId?: string[] | null ): {profileId: string, langs: Langs}[] {
        const res = []
        for ( const profileId in this.profiles ) {
            if (
                (!excludeProfileId || excludeProfileId.length === 0 || excludeProfileId.indexOf( profileId ) < 0)
                && (!filterDevAccountId || filterDevAccountId.length === 0 || filterDevAccountId.indexOf( this.profiles[ profileId ].devAccountId ) >= 0)
            ) {
                res.push( {
                    profileId: profileId,
                    langs: _.cloneDeep( this.profiles[ profileId ].name )
                })
            }
        }
        return res
    } 
    */

    
    public getProfilesList( excludeProfileId?: string[] | null, filterDevAccountId?: string[] | null ): Observable <{profileId: string, langs: Langs}[]> {
        const res = []
        if(this.profilesLoaded){
            for ( const profileId in this.profiles ) {
                if (
                    (!excludeProfileId || excludeProfileId.length === 0 || excludeProfileId.indexOf( profileId ) < 0)
                    && (!filterDevAccountId || filterDevAccountId.length === 0 || filterDevAccountId.indexOf( this.profiles[ profileId ].devAccountId ) >= 0)
                ) {
                    res.push( {
                        profileId: profileId,
                        langs: _.cloneDeep( this.profiles[ profileId ].name )
                    })
                }
            }
            return of(res)
        }
        else{
            return this.getProfiles().pipe(
                switchMap( ( profiles: { [profileId: string]: Profile } ) => {
                    for ( const profileId in profiles ) {
                        if (
                            (!excludeProfileId || excludeProfileId.length === 0 || excludeProfileId.indexOf( profileId ) < 0)
                            && (!filterDevAccountId || filterDevAccountId.length === 0 || filterDevAccountId.indexOf( profiles[ profileId ].devAccountId ) >= 0)
                        ) {
                            res.push( {
                                profileId: profileId,
                                langs: _.cloneDeep( profiles[ profileId ].name )
                            })
                        }
                    }
                    return of(res)
                }),
                first()
            )
        }
        
    }

    public searchById ( id: string ): Observable<{ found: boolean, type: string, res: { profile: Profile | null } }> {
        if ( id ) {
            return this.getProfiles().pipe(
                switchMap( ( profiles: { [profileId: string]: Profile } ) => {
                    const profile = profiles[id]
                    if ( profile ) {
                        return of( { found: true, type: 'profile', res: { profile: _.cloneDeep( profile ) } } )
                    }
                    else {
                        return of( { found: false, type: '', res: { profile: null } } )
                    }
                } ),
                first()
            )
        }
        else {
            return of( { found: false, type: '', res: { profile: null } } )
        }
    }

    public createProfiles ( params: Partial<Profile>[] ): Observable<LocalCRUDRes> {

        return this.httpService.post(
            this.configService.httpUrl.profiles.createProfiles,
            { profiles: params }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const profileId in httpRes.objectSuccess ) {
                    if ( profileId in httpRes.objectSuccess ) {
                        this.profiles[profileId] = httpRes.objectSuccess[profileId]
                    }
                }

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true, res: httpRes.objectSuccess } )
                }
                else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
                }
            } )
        )
    }

    public deleteProfiles ( params: string[] ): Observable<LocalCRUDRes> {

        return this.httpService.delete(
            this.configService.httpUrl.profiles.deleteProfiles,
            { profilesIds: params }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const profileId in httpRes.objectSuccess ) {
                    if ( profileId in httpRes.objectSuccess ) {
                        delete this.profiles[profileId]
                    }
                }

                this.deleteOperatorCacheValues( httpRes )

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true } )
                }
                else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
                }
            } )
        )
    }

    public editProfiles ( params: { profileId: string, profileSet: any }[] ): Observable<LocalCRUDRes> {

        let actionHasBeDone = true
        const profiles = params.map( obj => this.searchById( obj.profileId ) )

        return forkJoin( ...profiles ).pipe(
            switchMap( ( foundResTab: { found: boolean, type: string, res: { profile: Profile | null } }[] ) => {
                const set = {}

                for ( const k in foundResTab ) {
                    if ( k in foundResTab ) {
                        if ( foundResTab[k].found ) {
                            const profile = foundResTab[k].res.profile
                            const tmpProfileSet = params.find( ( obj ) => {
                                return obj.profileId === profile.profileId
                            })
                            const profileSet: any = this.lbUtilsService.diffObject( tmpProfileSet.profileSet, profile )

                            if ( profileSet && Object.keys( profileSet ).length > 0 ) {

                                if ( typeof (profileSet.name) !== 'undefined' ) {
                                    profileSet.name = _.cloneDeep( tmpProfileSet.profileSet.name )
                                }

                                if ( typeof (profileSet.validationQueueRoles) !== 'undefined' ) {
                                    profileSet.validationQueueRoles = _.cloneDeep( tmpProfileSet.profileSet.validationQueueRoles )
                                }

                                if ( typeof (profileSet.validationExtendedAttributes) !== 'undefined' ) {
                                    profileSet.validationExtendedAttributes = _.cloneDeep( tmpProfileSet.profileSet.validationExtendedAttributes )
                                }

                                if ( typeof (profileSet.adminResources) !== 'undefined' ) {
                                    profileSet.adminResources = _.cloneDeep( tmpProfileSet.profileSet.adminResources )
                                }

                                if ( typeof (profileSet.adminVisual) !== 'undefined' ) {
                                    profileSet.adminVisual = _.cloneDeep( tmpProfileSet.profileSet.adminVisual )
                                }
                                set[tmpProfileSet.profileId] = profileSet
                            }
                        }
                    }
                }

                return of( set )
            } ),
            switchMap( ( setParam: any ) => {
                if ( setParam && Object.keys( setParam ).length > 0 ) {
                    return this.httpService.put(
                        this.configService.httpUrl.profiles.setProfiles,
                        { profiles: setParam }, null, null
                    )
                } else {
                    actionHasBeDone = false
                    this.notificationsService.showNotif( true, 'Édition d\'un profil', 'Aucune modification à sauvegarder' )
                    return of({
                        success: 1,
                        error: 0,
                        objectSuccess: {},
                        objectError: {}
                    })
                }
            }),
            switchMap( ( httpRes: HttpCRUDRes ) => {
                if ( actionHasBeDone ) {
                    for ( const profileId in httpRes.objectSuccess ) {
                        if ( profileId in httpRes.objectSuccess ) {
                            this.profiles[profileId] = httpRes.objectSuccess[profileId]
                        }
                    }

                    this.deleteOperatorCacheValues( httpRes )

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true, actionHasBeDone: actionHasBeDone } )
                    }
                    else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), actionHasBeDone: actionHasBeDone } )
                    }
                } else {
                    return of( { success: true, actionHasBeDone: actionHasBeDone } )
                }
            })
        )
    }

    private deleteOperatorCacheValues ( httpRes: HttpCRUDRes ): void {
        const profilesIds = []
        for ( const profileId in httpRes.objectSuccess ) {
            profilesIds.push( profileId )
        }
        this.operatorsService.deleteOperatorCacheValuesFromProfiles( profilesIds )
    }

    public getProfilesOfDevAccount( devAccountId: string ): Observable<{[profileId: string]: Profile}> {
        const res = {}
        return this.getProfiles().pipe(
            switchMap( ( profiles: { [profileId: string]: Profile } ) => {
                for ( const profileId in profiles ) {
                    if ( profiles[ profileId].devAccountId === devAccountId ) {
                        res[ profileId ] = profiles[ profileId]
                    }
                }
                return of( res )
            } ),
            first()
        )
    }

    public getProfilesFromArray ( profileArray: string[] ): Observable<{[profileId: string]: Profile}> {
        const profilesObject: { companies: any, list: {profileId: string, langs: Langs}[] } = { companies: {}, list: [] }
        const search = []
        const res: {[profileId: string]: Profile} = {}
        for ( const profileId of profileArray ) {
            search.push( this.searchById( profileId ).pipe(
                switchMap( ( foundRes: { found: boolean, type: string, res: { profile: Profile | null } } ): Observable<Profile | null> => {
                    if ( foundRes.found ) {
                        profilesObject.list.push( { profileId: foundRes.res.profile.profileId, langs: foundRes.res.profile.name }  )
                        res[ foundRes.res.profile.profileId ] = foundRes.res.profile
                        return of( foundRes.res.profile )
                    } else {
                        return of( null )
                    }
                })
                )
            )
        }
        return forkJoin(...search).pipe(
            switchMap( (profiles: Profile[]) => {
                return of(res)
            })
        )
    }
}
