import { Pipe, PipeTransform } from "@angular/core";
import { LbTranslationService } from "lb-utils-front/dist";

@Pipe({
    name: 'lbDayFromNumber',
    pure: true
})
export class DayFromNumber implements PipeTransform {

    constructor(
        private lbTranslationService: LbTranslationService
    ) {}

    transform(value: string): string {
        let key = 'globalComponents_timeslots_viewWeekTimeslots_'
        switch (value) {
            case '1':
                key += 'monday'
                break
            case '2':
                key += 'tuesday'
                break
            case '3':
                key += 'wednesday'
                break
            case '4':
                key += 'thursday'
                break
            case '5':
                key += 'friday'
                break
            case '6':
                key += 'saturday'
                break
            case '0':
                key += 'sunday'
                break
        }
        return this.lbTranslationService.translateFromKey( key )
    }
}
