import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { NotificationsService } from './notifications.service'

@Injectable( {
    providedIn: 'root'
} )
export class LoadingService {

    private loadSource: Subject<{show: boolean, withoutLoader: boolean}> = new Subject<{show: boolean, withoutLoader: boolean}>()
    public $show: Observable<{show: boolean, withoutLoader: boolean}> = new Observable<{show: boolean, withoutLoader: boolean}>()

    constructor (
        private notificationsService: NotificationsService
    ) {
        this.$show = this.loadSource.asObservable()
    }

    public show ( withoutLoader?: boolean ) {
        withoutLoader = typeof withoutLoader !== 'undefined' ? withoutLoader : false
        this.loadSource.next( {show: true, withoutLoader: withoutLoader} )
    }

    public hide ( notification?: { success: boolean, title: string, text: string } ) {
        this.loadSource.next( {show: false, withoutLoader: false} )
        if ( notification ) {
            this.notificationsService.showNotif( notification.success, notification.title, notification.text )
        }
    }
}
