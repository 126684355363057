<nb-card class="list-card">
    <nb-card-header class="text-center">
        {{ title ? title : 'Choisissez une valeur' }}
        <div *ngIf="showSearch" class="selectSearch">
            <input type="text" nbInput fullWidth placeholder="Rechercher dans la liste" (keyup)="filter = $event.target.value">
        </div>
    </nb-card-header>
    <nb-card-body>
        <nb-list class="action-list">
            <nb-list-item class="text-left" *ngFor="let possibility of possibilities" [ngClass]="{selected: possibility.value === selected, hidden: (filter && filter !== '' && !match(possibility) )}" (click)="submit( possibility.value )">
                <span *ngIf="(possibility.langs | lbTypeOf) === 'object'">{{ possibility.langs | lbTranslateFromObject:{lang:locale.language} }}</span>
                <span *ngIf="(possibility.langs | lbTypeOf) === 'string'">{{ possibility.langs }}</span>
            </nb-list-item>
        </nb-list>
    </nb-card-body>
    <nb-card-footer>
        <button class="cancel align-center" nbButton status="danger" (click)="cancel()">Annuler</button>
    </nb-card-footer>
</nb-card>
