import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { QueueNameFromIdPipe } from './queue-name-from-id.pipe'
import { AppointmentTypeNameFromIdPipe } from './appointment-type-name-from-id.pipe'
import { OperatorNameFromIdPipe } from './operator-name-from-id.pipe'
import { EndpointNameFromIdPipe } from './endpoint-name-from-id.pipe'
import { EndpointNameFromLoginIdPipe } from './endpoint-name-from-login-id.pipe'
import { ProfileNameFromIdPipe } from './profile-name-from-id.pipe'
import { PlaceNameFromIdPipe } from './place-name-from-id.pipe'
import { CompanyNameFromIdPipe } from './company-name-from-id.pipe'
import { StateNamePipe } from "./state-name.pipe"
import { LbPipesModule } from 'lb-utils-front/dist'
import { SelectStatePipe } from './select-state.pipe'
import { DayFromNumber } from './day-from-number.pipe'

@NgModule( {
    imports: [CommonModule, LbPipesModule],
    exports: [
        QueueNameFromIdPipe,
        AppointmentTypeNameFromIdPipe,
        OperatorNameFromIdPipe,
        ProfileNameFromIdPipe,
        EndpointNameFromIdPipe,
        EndpointNameFromLoginIdPipe,
        PlaceNameFromIdPipe,
        CompanyNameFromIdPipe,
        StateNamePipe,
        SelectStatePipe,
        DayFromNumber
    ],
    declarations: [
        QueueNameFromIdPipe,
        AppointmentTypeNameFromIdPipe,
        OperatorNameFromIdPipe,
        ProfileNameFromIdPipe,
        EndpointNameFromIdPipe,
        EndpointNameFromLoginIdPipe,
        PlaceNameFromIdPipe,
        CompanyNameFromIdPipe,
        StateNamePipe,
        SelectStatePipe,
        DayFromNumber
    ]
} )
export class PipesModule {
}
