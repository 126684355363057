import { Injectable } from '@angular/core'
import {Observable, of, throwError, timer} from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { catchError, first, mergeMap, retryWhen, switchMap } from 'rxjs/operators'
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth'
import { Router } from '@angular/router'
import { AnyCnameRecord } from 'dns'

interface HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[]
    }
    observe?: 'body'
    params?: HttpParams | {
        [param: string]: string | string[]
    }
    reportProgress?: boolean
    responseType?: 'json'
    withCredentials?: boolean
}

interface GenericRetryStrategyParams {
    maxRetryAttempts?: number
    scalingDuration?: number
    includedStatusCodes?: number[]
}

const genericRetryStrategy =
    ( {
          maxRetryAttempts = 3, scalingDuration = 500, includedStatusCodes = [499, 503]
      }: GenericRetryStrategyParams = {} ) => ( attempts: Observable<any> ) => {
        return attempts.pipe(
            mergeMap( ( error, i ) => {
                const retryAttempt = i + 1
                if (
                    retryAttempt > maxRetryAttempts ||
                    (!includedStatusCodes.find( e => e === error.status ) && error.status)
                ) {
                    return throwError( error )
                }
                console.log( `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms` )
                return timer( retryAttempt * scalingDuration )
            } )
        )
    }

@Injectable( {
    providedIn: 'root'
} )
export class HttpService {

    constructor (
        private http: HttpClient,
        private authService: NbAuthService,
        private router: Router
    ) {
    }

    public get ( url, queryParams, headers ): Observable<any> {
        return this.runRequest( { type: 'GET', url, queryParams, headers, body: null } )
    }

    public put ( url, body, queryParams, headers ): Observable<any> {
        return this.runRequest( { type: 'PUT', url, queryParams, headers, body: body } )
    }

    public post ( url, body, queryParams, headers ): Observable<any> {
        return this.runRequest( { type: 'POST', url, queryParams, headers, body: body } )
    }

    public delete ( url, body, queryParams, headers ): Observable<any> {
        return this.runRequest( { type: 'DELETE', url, queryParams, headers, body: body } )
    }

    public getHeadersForSSE(): Observable<any> {

        return this.authService.getToken().pipe(
            switchMap( (userToken) => {
                const headers = {}
                headers[ 'Access-Control-Allow-Methods' ] = 'POST,GET,OPTIONS,DELETE'
                headers[ 'Access-Control-Allow-Origin' ] = '*'
                headers[ 'Content-Type' ] = 'application/json'

                headers['Authorization'] = 'Bearer ' + userToken.getValue()


                return of(headers)
            })
        )


    }

    public applyGetParamsToUrl (url , params ) {
        let firstParam = true
        for ( const k in params ) {
            if ( firstParam ) {
                url += '?'
                firstParam = false
            } else {
                url += '&'
            }
            if ( typeof( params[k] ) === 'object' ) {
                let firstParam2 = true
                for (const p in params[k]) {
                    if ( firstParam2 ) {
                        firstParam2 = false
                    } else {
                        url += '&'
                    }
                    url += k + '[]=' + params[k][p]
                }
            } else {
                url += k + '=' + params[k]
            }
        }

        return url
    }

    private runRequest ( data ): Observable<Object> {
        if ( !data || !data.type || !data.url ) {
            throw new Error( 'INVALID_TYPE_OR_URL' )
        }

        let body = null
        if ( data.body ) {
            body = data.body
        }

        const httpOptions: any = { params: {}, headers: {} }
        if ( data.queryParams ) {
            httpOptions.params = data.queryParams
        }
        if ( data.headers ) {
            httpOptions.headers = data.headers
        }

        let ob: Observable<Object>
        switch ( data.type ) {
            case 'GET':
                ob = this.http.get( data.url, httpOptions )
                break
            case 'PUT':
                ob = this.http.put( data.url, body, httpOptions )
                break
            case 'POST':
                ob = this.http.post( data.url, body, httpOptions )
                break
            case 'DELETE':
                if ( body ) { httpOptions.body = body }
                ob = this.http.delete( data.url, httpOptions )
                break
            default:
                throw new Error( 'INVALID_TYPE' )

        }

        return this.authService.getToken().pipe(
            switchMap( ( userToken: NbAuthJWTToken ) => {
                httpOptions.headers['Access-Control-Allow-Origin'] = '*'
                if ( !data.url.match( 'world.json' ) ) {
                    httpOptions.headers['Authorization'] = 'Bearer ' + userToken.getValue()
                }
                return ob
            } ),
            first(),
            retryWhen( genericRetryStrategy( {} ) ),
            catchError( ( error ) => {
                console.log( 'Error on HTTP request' )
                console.log( error )
                if ( error.status === 401 && error.error && error.error.error && error.error.error === 'token_expired' ) {
                    this.router.navigate( ['/#/auth/before-logout'] )
                }
                return throwError( error )
            } )
        )
    }
}
