import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { CompaniesPlacesQueuesService } from './companies-places-queues.service'
import { LbUtilsService } from 'lb-utils-front/dist'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { switchMap } from 'rxjs/operators'
import {Queue} from "lb-types/dist";

@Injectable({
  providedIn: 'root'
})
export class QueuesService {

  constructor (
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
      private lbUtilsService: LbUtilsService,
      private httpService: HttpService,
      private configService: ConfigService
  ) {
  }


  public createQueues ( params: Partial<Queue>[]): Observable<LocalCRUDRes> {
      return this.httpService.post(
          this.configService.httpUrl.queues.createQueues, { queues: params }, null, null
      ).pipe(
          switchMap( ( httpRes: HttpCRUDRes ) => {
              console.log(httpRes)
              for ( const queueId in httpRes.objectSuccess ) {
                  if ( queueId in httpRes.objectSuccess ) {
                      this.companiesPlacesQueuesService.deleteLocalQueue( queueId )
                      this.companiesPlacesQueuesService.addLocalQueue(httpRes.objectSuccess[queueId].companyId, httpRes.objectSuccess[queueId].placeId, queueId, httpRes.objectSuccess[queueId])
                  }
              }
              if ( httpRes.error === 0 && httpRes.success > 0 ) {
                  return of( { success: true, res: httpRes.objectSuccess } )
              } else {
                  return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
              }
          } )
      )
  }

  public deleteQueues ( params: string[] ): Observable<LocalCRUDRes> {

    return this.httpService.delete(
        this.configService.httpUrl.queues.deleteQueues, { queues: params }, null, null
    ).pipe(
        switchMap( ( httpRes: HttpCRUDRes ) => {
          for ( const queueId in httpRes.objectSuccess ) {
            if ( queueId in httpRes.objectSuccess ) {
              this.companiesPlacesQueuesService.deleteLocalQueue( queueId )
            }
          }
          if ( httpRes.error === 0 && httpRes.success > 0 ) {
            return of( { success: true } )
          } else {
            return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
          }
        } )
    )
  }

  public editQueues ( params: { queueId: string, queueSet: any }[] ): Observable<LocalCRUDRes> {

    const set = {}
    for ( const k in params ) {
      if (k in params) {
        set[params[k].queueId] = params[k].queueSet
      }
    }

    if ( set && Object.keys( set ).length > 0 ) {
      return this.httpService.put( this.configService.httpUrl.queues.setQueues,
          { queues: set }, null, null
      ).pipe(
          switchMap( ( httpRes: HttpCRUDRes ) => {
            for ( const queueId in httpRes.objectSuccess ) {
              if ( queueId in httpRes.objectSuccess ) {
                this.companiesPlacesQueuesService.updateLocalQueue( queueId, httpRes.objectSuccess[queueId] )
              }
            }

            if ( httpRes.error === 0 && httpRes.success > 0 ) {
              return of( { success: true, res: httpRes.objectSuccess } )
            }
            else {
              return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
            }
          })
      )
    } else {
        return of( { success: true } )
    }
  }

    public cleanQueuesTickets ( queuesId: {queueId: string, start?: string, end?: string}[] ): Observable<LocalCRUDRes> {

        return this.httpService.delete(
            this.configService.httpUrl.queues.cleanQueuesTickets, { queuesId: queuesId }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                // TODO: reload queues tickets list
                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true } )
                } else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
                }
            } )
        )
    }
}
