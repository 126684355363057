import { Component, Inject, OnInit } from '@angular/core'
import { Langs, Profile } from 'lb-types'
import { ProfilesService } from '../../../../../../services/developer-resources/profiles.service'
import { NbDialogRef, NbDialogService } from '@nebular/theme'
import { SelectDialogComponent } from '../../../../../../modals/select/select-dialog.component'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component({
  selector: 'ngx-select-profile-roles',
  templateUrl: './select-profile-roles.component.html',
  styleUrls: ['./select-profile-roles.component.scss']
})
export class SelectProfileRolesComponent implements OnInit {

  selectedProfileId: string = '-'
  selectedRoles: any = null
  selectedRoleLoader: boolean = false
  profileList: {profileId: string, langs: Langs}[]

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private profilesService: ProfilesService,
      protected ref: NbDialogRef<SelectProfileRolesComponent>,
      private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {
    //this.profileList = this.profilesService.getProfilesList()
    this.profilesService.getProfilesList().subscribe((res) => {
      this.profileList = res
    })
  }

  selectProfile( profileId: string ): void {
    this.selectedRoleLoader = false
    this.selectedProfileId = profileId
    this.profilesService.searchById( profileId ).subscribe( ( foundRes: { found: boolean, type: string, res: { profile: Profile | null } } ) => {
      if ( foundRes.found) {
        this.selectedRoles = foundRes.res.profile
        this.selectedRoleLoader = true
      }
    })
  }

  selectProfileId( $event ) {
    if ( $event ) { $event.stopPropagation() }
    const possibilities = this.profileList.map( (obj) => { return { 'value': obj.profileId, 'langs': obj.langs } } )
    this.dialogService.open(SelectDialogComponent, { context: { possibilities: possibilities, selected: this.selectedProfileId, title: 'Choisir un profil' } })
        .onClose.subscribe(profileId => { if ( profileId ) { this.selectProfile(profileId ) } } )
  }

  submit( selectedRole ): void {
    this.ref.close( selectedRole )
  }

}
