import {Pipe, PipeTransform} from "@angular/core";
import {Langs} from "lb-types/dist";
import { LbTranslateFromObjectPipe, LbTicketService, LbTranslationService } from "lb-utils-front/dist";


@Pipe({
    name: 'selectState'
})
export class SelectStatePipe implements  PipeTransform{

    constructor(
        private lbTicketService: LbTicketService,
        private lbTranslation: LbTranslationService
    ) {}


    transform(value: string, option: {lang: string, stateName?: {[state: number]: Langs}}): string {

        if (value === '' || value === null || value === undefined) {
            return ''
        }

        let str = ''
        const states = value.split('|')
        for (const state of states) {
            if (!option || !option.stateName) {
                str += this.lbTicketService.getTicketState( state, option.lang )
            }
            else {
                str += this.lbTranslation.translateFromObject(option.stateName[state], {lang: option.lang})
            }

            str += '|'
        }

        str = str.substring(0, str.length - 1)
       

       return str
    }
}
