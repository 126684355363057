import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../../providers/window.providers';

@Injectable( {
    providedIn: 'root'
} )
export class WindowService {

    constructor(@Inject(WINDOW) private window: Window) {
    }

    isStandardHostname() : boolean {
        return this.getHostname() === 'localhost' || this.getHostname() === 'admin.lineberty.net' || this.getHostname() === 'admin.winter.lineberty.net';
    }

    getHostname() : string {
        return this.window.location.hostname;
    }

    getUrl() : string {
        return this.window.location.href;
    }

    setUrl(url: string) : void {
        this.window.location.href = url;
    }

    getSessionStorage() : Storage {
        return this.window.sessionStorage;
    }

    open(url: string, target: string = '_blank') : void {
        this.window.open(url, target);
    }
}