import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { NbDialogService } from '@nebular/theme'
import { CompaniesPlacesQueuesService } from '../../../../../../services/default-resources/companies-places-queues.service'
import { TicketService } from '../../../../../../services/default-resources/ticket.service'
import * as _ from 'lodash'
import { SelectMultiComponent } from '../../../../../../modals/select-multi/select-multi.component'
import { SelectDialogComponent } from '../../../../../../modals/select/select-dialog.component'
import { Langs } from 'lb-types'
import { SelectProfileRolesComponent } from '../select-profile-roles/select-profile-roles.component'
import { Router } from '@angular/router'
import { NavigationService } from '../../../../../../services/utils/navigation.service'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component( {
  selector: 'ngx-companies-places-queues-roles-tree',
  templateUrl: './companies-places-queues-roles-tree.component.html',
  styleUrls: ['./companies-places-queues-roles-tree.component.scss']
} )
export class CompaniesPlacesQueuesRolesTreeComponent implements OnInit {

  @Input() type: string
  @Input() title: string
  @Input() edition: boolean = false
  @Input() selected: boolean = false
  @Input() companyExpanded: boolean = false
  @Input() placeExpanded: boolean = false
  @Output() onChange: EventEmitter<object> = new EventEmitter<object>()
  @Output() onSelect: EventEmitter<object> = new EventEmitter<object>()

  companiesPlacesAndQueuesData: any
  public expanded: any = {}
  public tree: {name: string, kind: string, roles?: any, id: string | null , ids: {companyId?: string, placeId?: string, queueId?: string, list?: any}}[] = []

  ticketStateList: {value: number, name: Langs}[]
  ticketStateListSliced: {value: number, name: Langs}[][] = []

  constructor (
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
      public navigationService: NavigationService,
      public ticketService: TicketService,
      private dialogService: NbDialogService,
      private router: Router
  ) {}

  goTo ( name, id ) {
    if ( name === 'company') {
      return this.navigationService.goToLastCompanyPage(id)
    } else if (name === 'place') {
      return this.navigationService.goToLastPlacePage(id)
    } else if ( name === 'queue') {
      return this.navigationService.goToLastQueuePage(id)
    }
  }

  ngOnInit() {
    this.ticketStateList = this.ticketService.getTicketApiState()
    this.ticketStateListSliced.push( _.cloneDeep( this.ticketStateList.slice( 0, 4 ) ) )
    this.ticketStateListSliced.push( _.cloneDeep( this.ticketStateList.slice( 4, 8 ) ) )
  }

  @Input()
  set companiesPlacesAndQueues ( companiesPlacesAndQueues: any ) {
    this.companiesPlacesAndQueuesData = companiesPlacesAndQueues
    this.initData()
  }

  addCompany( $event, list ) {
    if ( $event ) { $event.stopPropagation() }
    const possibilities = list.map( (obj) => { return { 'value': obj.companyId, 'langs': obj.langs } } )
    if ( possibilities.length > 1 ) {
      this.dialogService.open(SelectMultiComponent, { context: { possibilities: possibilities, title: 'Choisir l\'entreprise à ajouter' } })
          .onClose.subscribe(companyIds => { if ( companyIds ) { for ( const k in companyIds) { this.addRessource('company', companyIds[k] ) } } } )
    } else {
      this.dialogService.open(SelectDialogComponent, { context: { possibilities: possibilities, title: 'Choisir l\'entreprise à ajouter' } })
          .onClose.subscribe(companyId => { if ( companyId ) { this.addRessource('company', companyId ) } } )
    }
  }

  addPlace( $event, companyId, list ) {
    if ( $event ) { $event.stopPropagation() }
    const possibilities = list.map( (obj) => { return { 'value': obj.placeId, 'langs': obj.langs } } )
    if ( possibilities.length > 1 ) {
      this.dialogService.open(SelectMultiComponent, { context: { possibilities: possibilities, title: 'Choisir le lieu à ajouter' } })
          .onClose.subscribe(placeIds => { if ( placeIds ) { for ( const k in placeIds) { this.addRessource('place', companyId, placeIds[k]) } } } )
    } else {
      this.dialogService.open(SelectDialogComponent, { context: { possibilities: possibilities, title: 'Choisir le lieu à ajouter' } })
          .onClose.subscribe(placeId => { if ( placeId ) { this.addRessource('place', companyId, placeId ) } } )
    }
  }

  addQueue( $event, companyId, placeId, list ) {
    if ( $event ) { $event.stopPropagation() }
    const possibilities = list.map( (obj) => { return { 'value': obj.queueId, 'langs': obj.langs } } )
    if ( possibilities.length > 1 ) {
      this.dialogService.open(SelectMultiComponent, { context: { possibilities: possibilities, title: 'Choisir la file à ajouter' } })
          .onClose.subscribe(queueIds => { if ( queueIds ) { for ( const k in queueIds) { this.addRessource('queue', companyId, placeId, queueIds[k]) } } } )
    } else {
      this.dialogService.open(SelectDialogComponent, { context: { possibilities: possibilities, title: 'Choisir la file à ajouter' } })
          .onClose.subscribe(queueId => { if ( queueId ) { this.addRessource('queue', companyId, placeId, queueId ) } } )
    }
  }

  changeSetStateRoles ( $event, companyId, placeId, queueId, state ) {
    if ( $event ) { $event.stopPropagation() }
    const elem = this.companiesPlacesAndQueuesData[ companyId ][ placeId ][ queueId ].validation.authorizedSetState[ state + '' ]
    const possibilities = this.ticketStateList.map( (obj) => { return { 'value': obj.value, 'langs': obj.name } } )
    this.dialogService.open(SelectMultiComponent, { context: { possibilities: possibilities, selected: elem, title: 'Choisir les changements d\'états autorisés' } })
        .onClose.subscribe(res => { if ( res ) { this.companiesPlacesAndQueuesData[ companyId ][ placeId ][ queueId ].validation.authorizedSetState[ state + '' ] = res; this.initData() } } )
  }

  importRoles ( companyId, placeId, queueId ) {
    this.dialogService.open(SelectProfileRolesComponent)
        .onClose.subscribe(res => { if ( res ) { this.companiesPlacesAndQueuesData[ companyId ][ placeId ][ queueId ] = res; this.initData() } } )
  }

  initData () {

    this.tree = []
    const excludeCompany: string[] = []
    for ( const companyId in this.companiesPlacesAndQueuesData ) {
      if ( companyId in this.companiesPlacesAndQueuesData ) {

        const company: any = this.companiesPlacesQueuesService.getCompanyById( companyId )
        this.tree.push({
          name: company && company.name['fr_FR'] ? company.name['fr_FR'] : company && company.name['en_US'] ? company.name['en_US'] : '-',
          kind: 'company',
          roles: null,
          id: companyId,
          ids: { companyId: companyId }
        })
        if ( this.companyExpanded ) {
          this.expanded[ companyId ] = true
        }
        excludeCompany.push( companyId )

        const excludePlace: string[] = []
        for ( const placeId in this.companiesPlacesAndQueuesData[companyId] ) {
          if ( placeId in this.companiesPlacesAndQueuesData[companyId] ) {

            const place: any = this.companiesPlacesQueuesService.getPlaceById( placeId )
            this.tree.push({
              name: place && place.name['fr_FR'] ? place.name['fr_FR'] : place && place.name['en_US'] ? place.name['en_US'] : '-',
              kind: 'place',
              roles: null,
              id: placeId,
              ids: {
                companyId: companyId,
                placeId: placeId
              }
            })
            if ( this.placeExpanded ) {
              this.expanded[ placeId ] = true
            }
            excludePlace.push( placeId )

            const excludeQueue: string[] = []
            for ( const queueId in this.companiesPlacesAndQueuesData[companyId][placeId] ) {
              if ( queueId in this.companiesPlacesAndQueuesData[companyId][placeId] ) {
                const queue: any = this.companiesPlacesQueuesService.getQueueById( queueId )
                this.tree.push({
                  name: queue && queue.name['fr_FR'] ? queue.name['fr_FR'] : queue && queue.name['en_US'] ? queue.name['en_US'] : '-',
                  kind: 'queue',
                  roles: this.companiesPlacesAndQueuesData[companyId][placeId][queueId],
                  id: queueId,
                  ids: {
                    companyId: companyId,
                    placeId: placeId,
                    queueId: queueId
                  }
                } )
                excludeQueue.push( queueId )
              }
            }

            if ( this.edition ) {
              const queueList = this.companiesPlacesQueuesService.getQueueList( [companyId], [placeId], excludeQueue )
              if ( queueList.length > 0 ) {
                this.tree.push({
                  name: '',
                  kind: 'add-queue',
                  roles: null,
                  id: null,
                  ids: {
                    list: queueList,
                    companyId: companyId,
                    placeId: placeId
                  }
                } )
              }
            }
          }
        }

        if ( this.edition ) {
          const placeList = this.companiesPlacesQueuesService.getPlaceList( [companyId], excludePlace )
          if ( placeList.length > 0 ) {
            this.tree.push({
              name: '',
              kind: 'add-place',
              roles: null,
              id: null,
              ids: {
                list: placeList,
                companyId: companyId
              }
            } )
          }
        }
      }
    }

    if ( this.edition ) {
      const companyList = this.companiesPlacesQueuesService.getCompanyList( excludeCompany )
      if ( companyList.length > 0 ) {
        this.tree.push({
          name: '',
          kind: 'add-company',
          roles: null,
          id: null,
          ids: { list: companyList }
        } )
      }
    }
  }

  deleteRessource( type: string, companyId: string, placeId?: string, queueId?: string ) {
    if ( type === 'company' ) {
      delete this.companiesPlacesAndQueuesData[ companyId ]
    } else if ( type === 'place' ) {
      delete this.companiesPlacesAndQueuesData[ companyId ][ placeId ]
    } else if ( type === 'queue' ) {
      delete this.companiesPlacesAndQueuesData[ companyId ][ placeId ][ queueId ]
    }
    this.listChange( true )
  }

  addRessource( type: string, companyId: string, placeId?: string, queueId?: string ) {
    if ( type === 'company' ) {
      this.companiesPlacesAndQueuesData[ companyId ] = {}
      this.expanded[ companyId ] = true
    } else if ( type === 'place' ) {
      this.companiesPlacesAndQueuesData[ companyId ][ placeId ] = {}
      this.expanded[ placeId ] = true
    } else if ( type === 'queue' ) {
      this.companiesPlacesAndQueuesData[ companyId ][ placeId ][ queueId ] = {
        booking: {},
        validation: {
          authorizedSetState: {
            '0': [],
            '50': [],
            '100': [],
            '200': [],
            '300': [],
            '400': [],
            '500': [],
            '600': [],
            '700': []
          },
          extendedAttributes: [],
          recallAllowed: false,
          showIndicators: false,
          hiddenModeAllowed: false,
          pauseAllowed: false
        },
        admin: {}
      }
      this.expanded[ queueId ] = true
    }

    this.listChange( true )
  }

  listChange( launchInitData?: boolean ) {
    if ( launchInitData ) { this.initData() }
    this.onChange.emit( { companiesPlacesAndQueuesData: this.companiesPlacesAndQueuesData } )
  }

  selectRoles( roles: any ) {
    this.onSelect.emit( { roles: roles } )
  }

  expand( id ) {
    if (id) {
      if ( typeof( this.expanded[ id ] ) !== 'undefined' ) {
        if ( this.expanded[ id ] ) {
          delete this.expanded[ id ]
        } else {
          this.expanded[ id ] = true
        }
      } else {
        this.expanded[ id ] = true
      }
    }
  }

  isExpended( kind: string, id: string ) {
    const res = { show: false, expended: false }
    if (kind === 'company' || kind === 'add-company') {
      res.show = true
      res.expended = typeof( this.expanded[ id ] ) !== 'undefined' && this.expanded[ id ] === true
    } else if (kind === 'place') {
      res.show = true
      res.expended = typeof( this.expanded[ id ] ) !== 'undefined' && this.expanded[ id ] === true
    }
    return res
  }

  isVisible( kind: string, ids: any ) {
    if (kind === 'company' || kind === 'add-company') {
      return true
    } else if (kind === 'place' || kind === 'add-place' ) {
      return typeof( this.expanded[ ids.companyId ] ) !== 'undefined' && this.expanded[ ids.companyId ] === true
    } else if (kind === 'queue' || kind === 'add-queue') {
      return typeof( this.expanded[ ids.companyId ] ) !== 'undefined' && this.expanded[ ids.companyId ] === true && typeof( this.expanded[ ids.placeId ] ) !== 'undefined' && this.expanded[ ids.placeId ] === true
    } else {
      return false
    }
  }

}
