import { Pipe, PipeTransform } from '@angular/core'
import { LbTranslationService } from 'lb-utils-front/dist'
import { CompaniesPlacesQueuesService } from '../services/default-resources/companies-places-queues.service'
import { Place } from 'lb-types/dist'

@Pipe({
  name: 'placeNameFromId'
})
export class PlaceNameFromIdPipe implements PipeTransform {

  constructor (
      private lbTranslationService: LbTranslationService,
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService
  ) {
  }

  transform ( obj: string ): any {
    const place: Place = this.companiesPlacesQueuesService.getPlaceById( obj )
    if ( place ) {
      return this.lbTranslationService.translateFromObject( place.name )
    }
    else {
      return '-'
    }
  }
}
