import { Injectable } from '@angular/core'
import { ConfigService } from '../config.service'
import { WindowService } from '../utils/window.service'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor( private configService: ConfigService, private windowService: WindowService ) {
  }

  getLocalKey( key ) {
    return this.configService.webappName + '_' + key
  }

  get( key ) {
    const value = this.windowService.getSessionStorage().getItem( this.getLocalKey( key ) )
    return (typeof (value) !== 'undefined' && value !== null) ?  JSON.parse( value ) : null
  }

  set( key, value ) {
    (typeof( value ) !== 'undefined' && value !== null) ? this.windowService.getSessionStorage().setItem( this.getLocalKey( key ), JSON.stringify(value) ) : this.windowService.getSessionStorage().removeItem( this.getLocalKey( key ) )
  }

}
