import { Pipe, PipeTransform } from '@angular/core'
import { LbTranslationService } from 'lb-utils-front/dist'
import { AppointmentType } from 'lb-types/dist'
import { AppointmenttypesService } from '../services/default-resources/appointmenttypes.service'
import { map } from 'rxjs/internal/operators/map'
import { catchError } from 'rxjs/operators'

@Pipe({
  name: 'appointmentTypeNameFromId'
})
export class AppointmentTypeNameFromIdPipe implements PipeTransform {

  constructor(
      private lbTranslationService: LbTranslationService,
      private appointmenttypesService: AppointmenttypesService
  ) {}

  transform(appointmentTypeId: string, queueId: string): any {
    return this.appointmenttypesService.getAppointmentTypes(queueId)
      .pipe(
        map((appointmentTypes: { [appointmentTypeId: string]: AppointmentType }) => {
          if (appointmentTypes && appointmentTypes[appointmentTypeId]) {
            return this.lbTranslationService.translateFromObject(appointmentTypes[appointmentTypeId].translation)
          } else {
            return '-'
          }
        }),
        catchError((err) => {
          console.log(err)
          return '-'
        })
      )
  }

}
