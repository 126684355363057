import {Pipe, PipeTransform} from "@angular/core";
import {Langs} from "lb-types/dist";
import { LbTranslateFromObjectPipe, LbTicketService, LbTranslationService } from "lb-utils-front/dist";


@Pipe({
    name: 'stateName'
})
export class StateNamePipe implements  PipeTransform{

    constructor(
        private lbTicketService: LbTicketService,
        private lbTranslation: LbTranslationService
    ) {}


    transform(value: number, option: {lang: string, stateName?: {[state: number]: Langs}}): string {

        if (!option.stateName) {
            return this.lbTicketService.getTicketState( value, option.lang )
        }

        return this.lbTranslation.translateFromObject(option.stateName[value], {lang: option.lang})
    }
}
