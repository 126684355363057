import { Injectable } from "@angular/core"
import { HttpService } from "./http.service"
import { ConfigService } from "../config.service"
import { Observable } from "rxjs"

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private configService: ConfigService ,private httpService: HttpService) {
    }

    public login(ssoToken: string, subDomain: string, redirectUri: string): Observable<Object>  {
        return this.httpService.post(this.configService.httpUrl.auth.login, { ssoToken, subDomain, redirect_uri: redirectUri }, null, null)
    }
}  