import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { LbTranslationService } from 'lb-utils-front/dist'
import { SelectDialogComponent } from '../../../modals/select/select-dialog.component'
import { NbDialogService } from '@nebular/theme'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { TranslationVariablesService, translationVariablesType } from '../../../services/utils/translation-variables.service'
import { Langs } from 'lb-types'

@Component({
  selector: 'ngx-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {

  @Input() public edit: boolean
  @Input() public set data(val: any) {
    if (JSON.stringify(val) !== JSON.stringify(this._data)) {
      this._data = val
      this.dataArray = []
      for (const lang in this._data) {
        this.dataArray.push({ key: lang, value: JSON.parse(JSON.stringify(this._data[lang])) })
      }
    }
  }
  @Input() public size: 'small' | 'medium' | 'big' = 'small'
  @Input() public title: string
  @Input() public markDown: boolean = false
  @Input() public showTitle: boolean
  @Input() public showTextLength: boolean = false
  @Input() public showAddAndDelete: boolean
  @Input() public constants: { prefix?: string | null, type: translationVariablesType, show: boolean }
  @Input() public checkValidity: boolean = false
  @Input() public validationNorm: string
  @Output() onChange: EventEmitter<Langs> = new EventEmitter<Langs>()
  private editTimer = null
  public _data: any
  public constantsVariables: any
  private lastLanguageUsed; string = ''
  public dataArray: { key: string, value: string }[] = []

  // For GSM7 validity checks (multiple languages)
  public costPerCharX: object
  public messageLengthX: object

  public costAlertMessage: string = "Attention ! Le message n'est pas conforme à GSM7. Son coût va augmenter."
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private lbTranslationService: LbTranslationService,
    private translationVariablesService: TranslationVariablesService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit () {
    if ( this.constants && this.constants.show && this.constants.type ) {
      this.constantsVariables = this.translationVariablesService.get( this.constants.type )
    }
    if ( this.dataArray && this.dataArray.length > 0 ) {
      this.lastLanguageUsed = this.dataArray[0].key
    }
    // Initialisation of costPerCharX and messageLengthX to avoid undefined behavior in the template
    this.costPerCharX = {'fr_FR': 1}
    this.messageLengthX = {'fr_FR': 0}
  }

  setLastLang( value ): void {
    this.lastLanguageUsed = value
  }

  addLanguage(): void {
    const possibilities = this.lbTranslationService.getI10nNotPresent(this._data).map((obj) => { return { 'value': obj.i10n, 'langs': obj.traductions } })
    this.dialogService.open(SelectDialogComponent, { context: { possibilities: possibilities, title: 'Choisir la langue à ajouter' } })
      .onClose.subscribe(lang => {
        if (lang) {
          this._data[lang] = ''
          this.dataArray.push({ key: lang, value: '' })
          this.emit()
        }
      })
  }

  deleteLanguage(lang: string): void {
    if (typeof this._data[lang] !== 'undefined') {
      delete this._data[lang]
      for (const k in this.dataArray) {
        if (this.dataArray[k].key === lang) {
          this.dataArray.splice(parseInt(k, 10), 1)
          break
        }
      }
      this.emit()
    }
  }

  inputKeyUp(lang: string, event: any): void {
    this._data[lang] = event.target.value
    if(this.checkValidity){
      if(this.validationNorm == 'GSM7'){
        this.costPerCharX = Object.assign({}, this._data)
        this.messageLengthX = Object.assign({}, this._data)

        for(let e in this._data){
          this.costPerCharX[e] = 1
          this.messageLengthX[e] = 0
        }
        const preprocessing = (msg, lang) =>{
          const res = msg.replace(/{{.*}}/g, '')
          this.messageLengthX[lang] = res.length
          return res
        }
        for(let language in this._data){
          if(!isGSM7(preprocessing(this._data[language], language))){
            this.costPerCharX[language] = 2
          }
          else{
            this.costPerCharX[language] = 1
          }
        }
      }
    }  
  }

  inputChange(lang: string, event: any): void {
    this._data[lang] = event.target.value
    this.emit()
  }

  markdownChange(lang: string, event: any): void {
    this._data[lang] = event
    this.emitAfterEdit()
  }

  emitAfterEdit(): void {
    if (this.editTimer) {
      clearTimeout(this.editTimer)
      this.editTimer = null
    }
    this.editTimer = setTimeout(() => {
      this.emit()
    }, 1000)
  }

  addConstant( value: string ): void {
    const realValue = this.constants.prefix && this.constants.prefix.length > 0 && value !== 'shortUrl' ? '{{' + this.constants.prefix + value + '}}' : '{{' + value + '}}'
    if ( this.lastLanguageUsed && this.lastLanguageUsed.length > 0 && typeof this._data[ this.lastLanguageUsed ] !== 'undefined' ) {
      this._data[ this.lastLanguageUsed ] += realValue
      for ( const data of this.dataArray ) {
        if ( data.key === this.lastLanguageUsed ) {
          data.value = this._data[ this.lastLanguageUsed ]
        }
      }
    }
    this.emit()
  }

  emit(): void {
    this.onChange.emit(JSON.parse(JSON.stringify(this._data)))
  }

}

const isGSM7 = (msg) =>{
  const regex = /[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\\u0394_\\u03A6\\u0393\\u039B\\u03A9\\u03A0\\u03A8\\u03A3\\u0398\\u039EÆæßÉ!\"#$%&'()*+,\-.\/:;<=>?¡ÄÖÑÜ§¿äöñüà\\]*/gm
  let tmp = msg.match(regex)
  return (tmp[0].length == msg.length)
}

