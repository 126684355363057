<div class="row">
    <div class="col-lg-12">
        <nb-card>
            <nb-card-body>
                <nb-stepper orientation="horizontal">

                    <nb-step label="Profil">
                        <h3> Choisir le profil à importer </h3>
                        <br />
                        <nb-select class="devAccountSelect" (click)="selectProfileId( $event )" [selected]="selectedProfileId" status="primary">
                            <nb-option [value]="'-'" > Choisir un profil </nb-option>
                            <nb-option *ngFor="let profile of profileList" [value]="profile.profileId"> {{ profile.langs | lbTranslateFromObject:{lang: locale.language} }}</nb-option>
                        </nb-select>

                        <br /><br /><br />
                        <button class="cancel" nbButton disabled nbStepperNext> {{ 'global_action_previous' | lbTranslateFromKey: { lang: locale.language } }} </button>
                        <button [disabled]="selectedProfileId === '-'" nbButton nbStepperNext> {{ 'global_action_next' | lbTranslateFromKey: { lang: locale.language } }} </button>
                    </nb-step>

                    <nb-step label="Rôles">
                        <h3> Choisir les rôles à importer </h3>
                        <br />
                        <ng-container *ngIf="selectedRoleLoader">
                            <ngx-companies-places-queues-roles-tree [title]="'Liste des droits d\'accès sur la prise de tickets'" [companyExpanded]="true" [edition]="false" [selected]="true"
                                                                    [companiesPlacesAndQueues]="selectedRoles"
                                                                    (onSelect)="submit( $event.roles )"
                            ></ngx-companies-places-queues-roles-tree>
                        </ng-container>

                        <br /><br /><br />
                        <button nbButton nbStepperPrevious> {{ 'global_action_previous' | lbTranslateFromKey: { lang: locale.language } }} </button>
                    </nb-step>

                </nb-stepper>
            </nb-card-body>
        </nb-card>
    </div>
</div>
