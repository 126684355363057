import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { InputToTranslationComponent } from './input-to-translation.component'
import { NbCardModule, NbIconModule, NbInputModule, NbListModule, NbSelectModule } from '@nebular/theme'
import { RouterModule } from '@angular/router'
import { LbDirectivesModule, LbPipesModule } from 'lb-utils-front/dist'
import { TranslationModule } from '../translation/translation.module'
import { FormsModule } from '@angular/forms'
import { MarkdownEditorModule } from '../markdown-editor/markdown-editor.module'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NbInputModule,
        NbSelectModule,
        NbIconModule,
        NbCardModule,
        NbListModule,
        LbPipesModule,
        TranslationModule,
        LbDirectivesModule,
        MarkdownEditorModule
    ],
    exports: [
        InputToTranslationComponent
    ],
    declarations: [
        InputToTranslationComponent
    ]
} )
export class InputToTranslationModule {
}
