<nb-card class="list-card text-left">
    <nb-card-header *ngIf="showTitle">
        <div class="row withoutMargin">
            <div class="col-3 firstCol">
                <span *ngIf="title">{{title}}</span>
                <span *ngIf="!title">Liste des langues</span>
            </div>
            <div *ngIf="edit" class="col-9 text-right lastCol">
                <nb-icon class="colorSuccess clickable" *ngIf="showAddAndDelete" (click)="addLanguage()" icon="plus" pack="font-awesome"></nb-icon>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body class="periodsContainer">
        <div class="row withoutMargin">
            <div class="col-8 firstCol" [ngClass]="{'col-8': (constants && constants.show && edit), 'col-12': (!constants || !constants.show || !edit), 'lastCol': (!constants || !constants.show || !edit)}">
                <nb-list>
                    <nb-list-item class="row withoutMargin" *ngFor="let o of dataArray; let r = index"
                                  [ngClass]="{headerList: ((r+1)%2 === 1)}">
                        <div class="col-3 bold firstCol">
                            <nb-icon *ngIf="showAddAndDelete && edit" class="clickable colorError"
                                     (click)="deleteLanguage(o.key)" icon="close" pack="font-awesome"></nb-icon>
                            <span> {{ o.key | lbI10nToText | lbCapitalize:'FIRST' }} </span>
                        </div>
                        <div class="col-9 lastCol inputCol">
                            <ng-container *ngIf="!edit">
                                <span *ngIf="!markDown">{{ o.value }}</span>
                                <span *ngIf="markDown" LbMarkdown>{{ o.value }}</span>
                            </ng-container>
                            <ng-container *ngIf="edit">
                        <span *ngIf="!markDown">
                            <input *ngIf="size === 'small'" (focus)="setLastLang(o.key)" (keyup)="inputKeyUp(o.key, $event)" (change)="inputChange(o.key, $event)" type="text" nbInput fullWidth placeholder="Nom en {{o.key | lbI10nToText}}" value="{{o.value}}">
                            <textarea [ngClass]="{'textAreaMedium': size === 'medium', 'textAreaBig': size === 'big'}" *ngIf="size === 'medium' || size === 'big'" (focus)="setLastLang(o.key)" (keyup)="inputKeyUp(o.key, $event)" (change)="inputChange(o.key, $event)" value="{{o.value}}"></textarea>
                            <p *ngIf="costPerCharX[o.key] === 2 && checkValidity" style="color: orange;"> {{ costAlertMessage | lbTranslateFromKey: {lang: locale.language} }}</p>
                            <div *ngIf="showTextLength" class="textLength"> <span *ngIf="checkValidity">{{ messageLengthX[o.key] * costPerCharX[o.key] }}</span><span *ngIf="!checkValidity">{{_data[o.key].length}}</span> {{ 'globalComponents_fields_translation_lengthText' | lbTranslateFromKey: {lang: locale.language} }}</div>
                        </span>
                                <ngx-markdown-editor *ngIf="markDown" [value]="o.value"
                                                     (onChange)="markdownChange(o.key, $event)"></ngx-markdown-editor>
                            </ng-container>
                        </div>
                    </nb-list-item>
                </nb-list>
            </div>
            <div *ngIf="constants && constants.show && edit" class="col-4 lastCol constantContainer">
                <div class="variableTitle text-center"> {{ 'globalComponents_fields_translation_variableTitle' | lbTranslateFromKey: {lang: locale.language} }} </div>
                <div class="constantListContainer">
                    <nb-list>
                        <nb-list-item class="row withoutMargin clickable" (click)="addConstant(variable.value)" *ngFor="let variable of constantsVariables; let k = index" [ngClass]="{headerList: ((k+1)%2 === 1)}">
                            <nb-icon class="variableIcon" *ngIf="variable.icon && variable.icon.length > 0" icon="{{variable.icon}}" pack="font-awesome"></nb-icon>
                            <span class="variableName"> {{ variable.name | lbTranslateFromKey: {lang: locale.language} }} </span>
                        </nb-list-item>
                    </nb-list>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>
