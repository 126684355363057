import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core'
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http'
import { CoreModule } from './@core/core.module'
import { ThemeModule } from './@theme/theme.module'
import { AppComponent } from './app.component'
import { LoaderComponent } from './global-components/loader/loader.component'
// GUARDS
import { AuthGuard } from './guards/auth-guard.service'
import { NbSecurityModule } from '@nebular/security'
// PROVIDERS
import { WINDOW_PROVIDERS } from './providers/window.providers'
// RESOLVERS
import { CompaniesPlacesQueuesResolver } from './resolvers/companies-places-queues.resolver'
// ICONS
import { AppRoutingModule } from './app-routing.module'
import { NbEvaIconsModule } from '@nebular/eva-icons'
// THEME
import { NbChatModule, NbDatepickerModule, NbDialogModule, NbMenuModule, NbSidebarModule, NbToastrModule, NbWindowModule } from '@nebular/theme'
import { ModalsModule } from './modals/modals.module'
import { SelectProfileRolesModule } from './pages/developers/settings/profiles/components/select-profile-roles/select-profile-roles.module'
import { registerLocaleData } from '@angular/common'
import { NgxEchartsModule } from 'ngx-echarts'
import localeFr from '@angular/common/locales/fr'
import localeFrExtra from '@angular/common/locales/extra/fr'
// DIRECTIVES
import { LbDirectivesModule, LbPipesModule } from 'lb-utils-front/dist'
import { L10nConfig, L10nIntlModule, L10nLoader, L10nTranslationModule } from 'angular-l10n'
import { LbSecurityRoleGuard } from './guards/lb-security-role.service'
// @ts-ignore@
import fr from '../assets/l10n/locale-fr-FR.json'
// @ts-ignore@
import en from '../assets/l10n/locale-en-US.json'
import { MatNativeDateModule } from '@angular/material/core'
import { SsoInfoResolver } from './resolvers/sso-informatons.resolver'

// PIPES
registerLocaleData(localeFr, 'fr-FR', localeFrExtra)

export const l10nConfig: L10nConfig = {
    format: 'language-region',
    providers: [
        { name: 'app', asset: { 'fr-FR': fr, 'en-US': en } }
    ],
    cache: false,
    keySeparator: '.',
    defaultLocale: { language: 'fr-FR', currency: 'EUR', timeZone: 'Europe/Paris' },
    schema: [
        { locale: { language: 'en-US', currency: 'USD' }, dir: 'ltr', text: 'United States' },
        { locale: { language: 'fr-FR', currency: 'EUR' }, dir: 'ltr', text: 'France' }
    ]
}
export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
    return () => l10nLoader.init().catch((err) => {
        console.log('Error on loading translations configuration : %o', err)
    })
}

@NgModule({
    imports: [
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        NbEvaIconsModule,

        ModalsModule,
        SelectProfileRolesModule,

        NbSecurityModule.forRoot(),
        ThemeModule.forRoot(),

        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbDialogModule.forRoot(),
        NbWindowModule.forRoot(),
        NbToastrModule.forRoot(),
        NbChatModule.forRoot({
            messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY'
        }),
        CoreModule.forRoot(),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),

        L10nTranslationModule.forRoot(l10nConfig),
        L10nIntlModule,

        LbDirectivesModule,
        LbPipesModule
    ],
    providers: [
        CompaniesPlacesQueuesResolver,
        SsoInfoResolver,
        AuthGuard,
        LbSecurityRoleGuard,
        WINDOW_PROVIDERS,
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initL10n,
        //     deps: [L10nLoader],
        //     multi: true
        // },
        { provide: LOCALE_ID, useValue: 'fr-FR' }
    ],
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        LoaderComponent
    ]
})
export class AppModule {
}
