<div class="migrationContainer">
    <div *ngIf="isAlgoV2" class="row">
        <div class="row">
            <div class="col-12">
                <div class="colorError">
                    Cette file a déjà été migrée sur l'algorithme v2. Aucune migration n'est possible.
                    <button (click)="goTo('queues')" nbButton status="success">Revenir à la liste des files</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="errors.emptyQueues" class="row">
        <div class="row">
            <div class="col-12">
                <div class="colorError">
                    Aucune file sélectionné.
                    <button (click)="goTo('queues')" nbButton status="success">Revenir à la liste des files</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="errors.manyCompanies" class="row">
        <div class="row">
            <div class="col-12">
                <div class="colorError">
                    Cette interface ne peut migrer que des files appartenant à la même entreprise. Merci de ne sélectionner que des files de la même entreprise.
                    <button (click)="goTo('queues')" nbButton status="success">Revenir à la liste des files</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isAlgoV2 && !errors.emptyQueues && !errors.manyCompanies" class="row">
        <div class="col-lg-12 text-center">
            <nb-card>
                <nb-card-body>
                    <nb-stepper [disableStepNavigation]="true" [linear]="true" orientation="horizontal">

                        <nb-step label="Informations">
                            <h3> Informations sur la migration </h3>
                            <br /><br />

                            <div class="colorError text-left">
                                <p class="colorError bold">La migration des files vers l'algorithme v2 entraine plusieurs modifications :</p>
                                <ul>
                                    <li class="colorError"> Ne plus utiliser l'ancienne interface d'administration ni l'admin mac mais uniquement cette nouvelle interface d'administration </li>
                                    <li class="colorError"> Le terminal v1 et les APP/WebApp v1 ne fonctionneront plus </li>
                                    <li class="colorError"> Le endpoint v1 peut avoir quelques soucis de compatibilité </li>
                                    <li class="colorError"> Le format des disponibilités peuvent changer (avant : 10h00 / 10h10 / 10h20 => après : 10h03 / 10h07 : 10h16). Il faut bien vérifier la configuration ainsi que faire le point avec un développeur </li>
                                    <li class="colorError"> Certains cas mal gérés par l'algorithme v1 doivent être entièrement repensés et reconfigurés (la gestion des quotas, des surbooking, des séances, des horaires de endpoints lorsque la saisie est normalement manuelle...) </li>
                                    <li class="colorError"> Il peut être nécessaire après la migration de créer de nouveaux terminaux V2 et/ou de nouveaux endpoints v2 </li>
                                    <li class="colorError"> Le changement de version est effectif dans les 5 minutes après la confirmation </li>
                                </ul>
                            </div>

                            <br /><br /><br />
                            <button (click)="goTo('queues')" nbButton nbStepperNext>Annuler</button>
                            <button nbButton nbStepperNext>Continuer</button>
                        </nb-step>

                        <nb-step label="Configuration">
                            <h3> Configuration des files </h3>
                            <br />
                            <p> La nouvelle configuration des files est automatiquement calculée à partir de la première file sélectionnée. Merci de vérifier les résultats</p>
                            <br /><br />

                            <ng-container *ngIf="tmpQueueConfiguration && tmpQueueConfiguration.queueType">
                                <div class="row text-left">
                                    <div class="col-6">
                                        <nb-card>
                                            <nb-card-header> Prise de tickets </nb-card-header>
                                            <nb-card-body>
                                                <div class="row">
                                                    <div class="col-7"> Traitement des demandes de prise de tickets </div>
                                                    <div class="col-5">
                                                        <nb-select [size]="'small'" [(selected)]="tmpQueueConfiguration.queueType.booking.request.type">
                                                            <nb-option [value]="'FIFO'"> dans l'ordre </nb-option>
                                                            <nb-option [value]="'RANDOM'"> aléatoire (loterie) </nb-option>
                                                        </nb-select>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-7"> Type de prise de tickets </div>
                                                    <div class="col-5">
                                                        <nb-select (selectedChange)="setBookingType( $event )" [size]="'small'" [selected]="tmpQueueConfiguration.queueType.booking.type">
                                                            <nb-option [value]="'FIRST_AVAILABLE_TIMESLOT'"> Prochain temps disponible </nb-option>
                                                            <nb-option [value]="'USER_DEFINED_TIMESLOT'"> L'utilisateur choisit son heure </nb-option>
                                                            <nb-option [value]="'MIXED'"> Mixte </nb-option>
                                                        </nb-select>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-7"> Les tickets FIFO </div>
                                                    <div class="col-5">
                                                        <nb-select [size]="'small'" [(selected)]="tmpQueueConfiguration.queueType.booking.serverBookingType">
                                                            <nb-option [value]="'FIFO'"> Avancent avec la file </nb-option>
                                                            <nb-option [value]="'APPOINTMENT'"> N'avancent pas avec la file </nb-option>
                                                        </nb-select>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-7"> Ouverture de la prise de tickets </div>
                                                    <div class="col-5">
                                                        {{ preBookingDelay | lbMsToTime:'fr' }} avant un point de passage
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-7"> Délai de sécurité pour l'acceptation de rendez-vous </div>
                                                    <div class="col-5">
                                                        <ngx-time [duration]="tmpQueueConfiguration.queueType.availabilities.display.feature.delayToBookedTicket | lbMs" (onChange)="setQueueTypeDelayToBookedTicket($event)"></ngx-time>
                                                    </div>
                                                </div>
                                            </nb-card-body>
                                        </nb-card>
                                    </div>

                                    <div class="col-6">
                                        <nb-card>
                                            <nb-card-header> Disponibilités </nb-card-header>
                                            <nb-card-body>
                                                <div class="row">
                                                    <div class="col-6"> Calcul des dispos sur </div>
                                                    <div class="col-6">
                                                        <input type="text" nbInput [(ngModel)]="tmpQueueConfiguration.queueType.availabilities.calculate.period.numberOfPeriod"> jours
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-6"> Nombre infini de tickets </div>
                                                    <div class="col-6">
                                                        <nb-select [size]="'small'" [(selected)]="tmpQueueConfiguration.queueType.availabilities.calculate.feature.infiniteAvailabilityOnCurrentPeriod">
                                                            <nb-option [value]="'NONE'"> désactivé </nb-option>
                                                            <nb-option [value]="'FIFO'"> activé pour les fifos </nb-option>
                                                            <nb-option [value]="'APPOINTMENT'"> activé pour les rendez-vous </nb-option>
                                                            <nb-option [value]="'ALL'"> activé pour tous types de tickets </nb-option>
                                                        </nb-select>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-6"> Affichage des dispos sur </div>
                                                    <div class="col-6">
                                                        <nb-select [size]="'small'" [(selected)]="tmpQueueConfiguration.queueType.availabilities.display.visibility.type">
                                                            <nb-option [value]="'FULL_DAYS'"> toute la journée </nb-option>
                                                            <nb-option [value]="'ROLLING'"> les x prochaines heures </nb-option>
                                                        </nb-select>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-6"> Proposer des dispos FIFO </div>
                                                    <div class="col-6">
                                                        <nb-select [size]="'small'" [(selected)]="tmpQueueConfiguration.queueType.availabilities.display.feature.fifoOnlyOnCurrentPeriod">
                                                            <nb-option [value]="true"> uniquement pour la journée </nb-option>
                                                            <nb-option [value]="false"> pour tous les jours </nb-option>
                                                        </nb-select>
                                                    </div>
                                                </div>
                                            </nb-card-body>
                                        </nb-card>
                                    </div>
                                </div>
                            </ng-container>

                            <br /><br /><br />
                            <button nbButton nbStepperPrevious> {{ 'global_action_previous' | lbTranslateFromKey: { lang: locale.language } }} </button>
                            <button nbButton nbStepperNext>Valider</button>
                        </nb-step>

                        <nb-step label="Validation">
                            <h3>Validation de la migration</h3>

                            <br />
                            <div class="colorError">
                                <p> Attention, ce choix a de lourds impacts sur la gestion de la file et côté client. Êtes vous sûr(e) de vouloir confirmer cette action ? </p>
                            </div>

                            <br /><br /><br />
                            <button nbButton nbStepperPrevious> {{ 'global_action_previous' | lbTranslateFromKey: { lang: locale.language } }} </button>
                            <button (click)="migrate()" nbButton nbStepperNext>Confirmer</button>
                        </nb-step>

                        <nb-step label="Confirmation">
                            <div class="row">
                                <div class="col-xl-3 col-12"></div>
                                <div class="col-xl-6 col-12"><div class="text-left">
                                    <br /><br /><br />
                                    <div *ngIf="currentMigrateQueue" class="text-center"> {{ currentMigrateQueue | lbTranslateFromObject:{lang: locale.language} }} </div>
                                    <br />
                                    <nb-progress-bar [value]="migrationPercent" status="primary" [displayValue]="true"></nb-progress-bar>
                                    <br /><br />
                                    <ng-container *ngIf="!(queuesErrors | lbIsEmpty)">
                                        Liste des files pour lesquelles une erreur s'est produite lors de la migration :
                                        <ul>
                                            <li *ngFor="let queueName of queuesErrors"> {{ queueName | lbTranslateFromObject:{lang: locale.language} }} </li>
                                        </ul>
                                        <br /><br />
                                    </ng-container>
                                </div>
                                </div>
                                <div class="col-xl-3 col-12"></div>
                            </div>
                            <br /><br /><br />
                            <button *ngIf="migrationAllDone" nbButton nbStepperPrevious> {{ 'global_action_previous' | lbTranslateFromKey: { lang: locale.language } }} </button>
                            <button *ngIf="migrationAllDone" (click)="close()" nbButton nbStepperNext>{{ 'global_action_terminate' | lbTranslateFromKey: { lang: locale.language } }}</button>
                        </nb-step>

                    </nb-stepper>
                </nb-card-body>
            </nb-card>
        </div>
    </div>

</div>
