<nb-card class="list-card">
    <nb-card-header class="text-center"> {{ title ? title : 'Confirmation' }}</nb-card-header>
    <nb-card-body>
        <div class="textContainer">
            {{ text }}
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button class="cancel align-center" nbButton status="danger" (click)="cancel()">Annuler</button>
        <button nbButton status="success" (click)="submit()">Valider</button>
    </nb-card-footer>
</nb-card>
