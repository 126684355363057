import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { SsoInformation } from "../interfaces/sso/sso-information";
import { Observable, of } from "rxjs";
import { HttpService } from "./utils/http.service";
import { catchError, first, switchMap } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SSOService {

    constructor(
        private configService: ConfigService,
        private httpService: HttpService
    ) { }

    public getInfos(domainName: string): Observable<SsoInformation> {
        if (domainName) {
            return this.httpService.get(
                this.configService.httpUrl.sso.getInformations, { subDomain: domainName }, null
            ).pipe(
                switchMap((response: SsoInformation) => {
                    return of(response)
                }),
                first(),
                catchError( err => {
                    console.log('error while getting operator infos')
                    console.log( err )
                    return of(null)
                })
            )
        }
        else {
            return of(null)
        }
    }
}
