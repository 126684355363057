import { Component, Inject, OnInit } from '@angular/core'
import { LoadingService } from '../../services/utils/loading.service'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'

@Component( {
    selector: 'ngx-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
} )
export class LoaderComponent implements OnInit {

    public show: boolean = false
    public showLoader: boolean = false

    constructor (
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        private loadingService: LoadingService
    ) {
    }

    ngOnInit (): void {
        this.loadingService.$show.subscribe( ( val: {show: boolean, withoutLoader: boolean} ) => {
            this.show = val.show
            this.showLoader = val.show ? !val.withoutLoader : false
        } )
    }

}
