import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpService } from './http.service'
import { ConfigService } from '../config.service'

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
      private httpService: HttpService,
      private configService: ConfigService
  ) { }

  public getAllKeys (): Observable<string[]> {
    return this.httpService.get( this.configService.httpUrl.cache.getAllKeys, null, null ) as Observable<string[]>
  }

  public getKeysValues (keys: string[]): Observable<any> {
    return this.httpService.post( this.configService.httpUrl.cache.getKeysValues, {keys: keys}, null, null )
  }

  public setKeysValues (keys: {[key: string]: any}): Observable<any> {
    return this.httpService.put( this.configService.httpUrl.cache.setKeysValues, {keys: keys}, null, null )
  }

  public deleteKeys (keys: string[], useRegexp?: boolean): Observable<any> {
    return this.httpService.delete( this.configService.httpUrl.cache.deleteKeys, {keys: keys, useRegexp: useRegexp}, null, null )
  }

  public deleteAllKeys (): Observable<any> {
    return this.httpService.delete( this.configService.httpUrl.cache.deleteAllKeys, null, null, null )
  }
}
