<nb-card class="clickable">
    <nb-card-header>Qr Code</nb-card-header>
    <nb-card-body class="configurationCodePopupBody">
        <div class="url">
            {{ url }}
            <nb-icon class="iconCopyData" [ngxCopyToClipBoardTitle]="'URL copié'" [ngxCopyToClipBoard]="url" icon="copy" pack="font-awesome"></nb-icon>
        </div>
        <qr-code value="{{ url }}" size="300" errorCorrectionLevel="M"></qr-code>
    </nb-card-body>
    <nb-card-footer (click)="ref.close()" class="configurationCodePopupFooter">
        <button nbButton>Fermer</button>
    </nb-card-footer>
</nb-card>
