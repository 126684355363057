import { Observable, of } from 'rxjs'
import { Injectable } from '@angular/core'

import { SsoInformation } from '../interfaces/sso/sso-information'
import { WindowService } from '../services/utils/window.service'
import { SSOService } from '../services/sso.service'

@Injectable()
export class SsoInfoResolver  {
    constructor(
        private ssoService: SSOService,
        private windowService: WindowService
    ) { }

    resolve = (): Observable<SsoInformation> => {
        const domain = this.windowService.getHostname()
        if (domain) {
            return this.ssoService.getInfos(domain)
        } else {
            return of(null)
        }
    }
}
