import { Component, EventEmitter, Input, Output } from '@angular/core'
import 'devextreme/ui/html_editor/converters/markdown'

@Component({
  selector: 'ngx-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss']
})
export class MarkdownEditorComponent{

  @Input() public set value ( val ) {
    if ( val !== this._formatedValue && val !== this._value ) {
      this._value = this.getHtmlValue ( val )
      this._formatedValue = this.getRealValue ( val )
    }
  }
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>()
  public _value: string
  public _formatedValue: string
  popupVisible: boolean = false

  toolbarButtonOptions :any = {
    text: 'Afficher la source',
    stylingMode: 'text',
    onClick: () => { this.popupVisible = true }
  }

  constructor() {}

  getRealValue ( val ) {
    let res = val + ''
    res = res.replace(/<br>/gi, '\n')
    res = res.replace( /([a-zA-Z0-9 \-\_\.\{\}\[\]]+)\n============/gi, '# $1 \n' )
    res = res.replace( /([a-zA-Z0-9 \-\_\.\{\}\[\]]+)\n=======/gi, '# $1 \n' )
    res = res.replace( /([a-zA-Z0-9 \-\_\.\{\}\[\]]+)\n-------/gi, '## $1 \n' )
    return res
  }

  getHtmlValue ( val ) {
    let res = val + ''
    res = res.replace( /## ([a-zA-Z0-9 \-\_\.\{\}\[\]]+) \n/gi, '$1\n-------' )
    res = res.replace( /# ([a-zA-Z0-9 \-\_\.\{\}\[\]]+) \n/gi, '$1\n=======' )
    res = res.replace( /# ([a-zA-Z0-9 \-\_\.\{\}\[\]]+) \n/gi, '$1\n============' )
    res = res.replace(/\n\n\n/gi, '\n<br>\n\n')
    return res
  }

  emit() {
    this._formatedValue = this.getRealValue( this._value )
    this.onChange.emit( this._formatedValue )
  }
}
