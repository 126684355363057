import { Pipe, PipeTransform } from '@angular/core'
import { LbTranslationService } from 'lb-utils-front/dist'
import { CompaniesPlacesQueuesService } from '../services/default-resources/companies-places-queues.service'
import { Queue } from 'lb-types/dist'

@Pipe({
  name: 'queueNameFromId'
})
export class QueueNameFromIdPipe implements PipeTransform {

  constructor(
      private lbTranslationService: LbTranslationService,
      private companiesPlacesQueuesService: CompaniesPlacesQueuesService
  ) {}

  transform(obj: string): any {
    const queue: Queue = this.companiesPlacesQueuesService.getQueueById( obj )
    if ( queue ) {
      return this.lbTranslationService.translateFromObject( queue.name )
    } else {
      return '-'
    }
  }

}
