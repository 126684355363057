import { Component, Inject, Input } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { L10N_LOCALE, L10nLocale } from 'angular-l10n'
import { Langs } from 'lb-types'
import { LbTranslationService } from 'lb-utils-front/dist'

@Component({
  selector: 'ngx-select',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent {

  @Input() possibilities: { value: any, langs: Langs | string }[]
  @Input() selected: any
  @Input() title: string
  @Input() showSearch: boolean = true
  public filter: string = ''

  constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private lbTranslationService: LbTranslationService,
      protected ref: NbDialogRef<SelectDialogComponent>
  ) {}

  match ( possibility: { value: any, langs?: Langs | string } ) {
    const text = this.lbTranslationService.translateFromObject( possibility.langs, {lang: this.locale.language} )
    return text.toUpperCase().match(this.filter.toUpperCase())
  }

  cancel() {
    this.ref.close()
  }

  submit( value ) {
    this.ref.close( value )
  }

}
