import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import "devextreme/ui/html_editor/converters/markdown";
import { Langs } from "lb-types/dist";
import { translationVariablesType } from "../../../services/utils/translation-variables.service";
import { L10N_LOCALE, L10nLocale } from "angular-l10n";

@Component({
  selector: "ngx-input-to-translation",
  templateUrl: "./input-to-translation.component.html",
  styleUrls: ["./input-to-translation.component.scss"]
})
export class InputToTranslationComponent implements AfterViewInit {
  @Input() public edit: boolean;
  @Input() public showAddAndDelete: boolean;
  @Input() public showTitle: boolean;
  @Input() public showTextLength: boolean = false;
  @Input() public size: "small" | "medium" | "big" = "small";
  @Input() public constants: { prefix?: string | null; type: translationVariablesType; show: boolean };
  @Input() public data: any;
  @Input() public markDown: boolean = false;
  @Input() public title: string;
  @Output() onChange: EventEmitter<Langs> = new EventEmitter<Langs>();
  @Input() public isOpened = false;
  @Input() public checkValidity: boolean = false;
  @Input() public validationNorm: string;
  @Input() public iconCollapse = true;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit(): void {}

  emit(data) {
    this.data = JSON.parse(JSON.stringify(data));
    this.onChange.emit(this.data);
  }

  ngAfterViewInit() {}
}
