import { Directive, HostListener, Input } from '@angular/core'
import { Clipboard } from '@angular/cdk/clipboard'
import { NotificationsService } from '../services/utils/notifications.service'

@Directive( {
    selector: '[ngxCopyToClipBoard]'
} )
export class CopyToClipboardDirective {

    constructor (
        private clipboard: Clipboard,
        private notificationsService: NotificationsService
    ) {
    }

    @Input( 'ngxCopyToClipBoard' ) text: string
    @Input( 'ngxCopyToClipBoardTitle' ) title: string

    @HostListener( 'click' ) onClick () {
        this.clipboard.copy( this.text )
        let title = 'Le texte a été copié : '
        if ( this.title ) {
            title = this.title
        }
        this.notificationsService.showNotif( true, title, this.text )
    }
}
