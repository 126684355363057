import { Pipe, PipeTransform } from '@angular/core'
import { OperatorsService } from '../services/developer-resources/operators.service'
import { OperatorDto } from 'lb-types/dist'

@Pipe({
  name: 'endpointNameFromLoginId'
})
export class EndpointNameFromLoginIdPipe implements PipeTransform {

  constructor(
      private operatorsService: OperatorsService
  ) {}

  transform(operatorLoginId: string): any {
    const operatorDto: OperatorDto | null = this.operatorsService.getOperatorByLoginId( operatorLoginId )
    if ( operatorDto ) {
      return this.operatorsService.getOperatorName( operatorDto )
    } else {
      return '-'
    }
  }

}
