import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { DxHtmlEditorModule, DxPopupModule } from 'devextreme-angular'
import { MarkdownEditorComponent } from './markdown-editor.component'

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        DxHtmlEditorModule,
        DxPopupModule
    ],
    exports: [
        MarkdownEditorComponent
    ],
    declarations: [
        MarkdownEditorComponent
    ]
} )
export class MarkdownEditorModule {
}
