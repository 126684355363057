import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CompaniesPlacesQueuesRolesTreeComponent } from './companies-places-queues-roles-tree.component'
import { NbButtonModule, NbCardModule, NbIconModule, NbSelectModule, NbToggleModule, NbTreeGridModule } from '@nebular/theme'
import { RouterModule } from '@angular/router'
import { DirectivesModule } from '../../../../../../directives/directives.module'
import { LbPipesModule } from 'lb-utils-front/dist'

@NgModule( {
    imports: [
        CommonModule,
        RouterModule,
        NbIconModule,
        NbCardModule,
        NbButtonModule,
        NbTreeGridModule,
        NbSelectModule,
        NbToggleModule,
        DirectivesModule,
        LbPipesModule
    ],
    exports: [
        CompaniesPlacesQueuesRolesTreeComponent
    ],
    declarations: [
        CompaniesPlacesQueuesRolesTreeComponent
    ]
} )
export class CompaniesPlacesQueuesRolesTreeModule {
}
