import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

// THEME
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbListModule, NbProgressBarModule, NbSelectModule, NbStepperModule, NbTabsetModule } from '@nebular/theme'

// MODALS
import { SelectDialogComponent } from './select/select-dialog.component'
import { SelectMultiComponent } from './select-multi/select-multi.component'
import { ConfirmDialogComponent } from './confirm/confirm.component'
import { LbPipesModule } from 'lb-utils-front/dist'
import { MigrationToV2Component } from './migration-to-v2/migration-to-v2.component'
import { TimeModule } from '../global-components/fields/time/time.module'
import { RouterModule } from '@angular/router'
import { SelectCompanyPlaceQueueComponent } from './select-company-place-queue/select-company-place-queue.component'
import { DatatableModule } from '../global-components/datatable/datatable.module'
import { TimeConfigComponent } from './time-config/time-config.component'
import { InputToTranslationModule } from '../global-components/fields/input-to-translation/input-to-translation.module'
import { QrcodeComponent } from './qrcode/qrcode.component'
import { QrCodeModule } from 'ng-qrcode'
import { DirectivesModule } from '../directives/directives.module'

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        NbListModule,
        NbCardModule,
        NbButtonModule,
        NbInputModule,
        LbPipesModule,
        NbStepperModule,
        NbSelectModule,
        TimeModule,
        NbIconModule,
        RouterModule,
        NbProgressBarModule,
        NbTabsetModule,
        DatatableModule,
        InputToTranslationModule,
        QrCodeModule,
        DirectivesModule
    ],
    providers: [],
    bootstrap: [],
    declarations: [
        SelectDialogComponent,
        SelectMultiComponent,
        ConfirmDialogComponent,
        MigrationToV2Component,
        SelectCompanyPlaceQueueComponent,
        TimeConfigComponent,
        QrcodeComponent
    ],
    exports: [
        SelectDialogComponent,
        SelectMultiComponent,
        ConfirmDialogComponent,
        MigrationToV2Component,
        SelectCompanyPlaceQueueComponent,
        TimeConfigComponent,
        QrcodeComponent
    ]
} )
export class ModalsModule {
}
