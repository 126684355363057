<div class="ngTimeContainer" *ngIf="splittedDuration" [ngClass]="{'small': size === 'small', 'normal': size === 'normal', 'big': size === 'big' }">
    <ng-container *ngIf="display === 'portrait'">
        <div class="row withoutMargin" *ngIf="format.indexOf('day') >= 0">
            <div class="col-12 text-left firstCol lastCol">
                <input (change)="durationValueChange()" type="number" max="999" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.day">
                <span class="spanText" *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}jours</span>
                <span class="spanText" *ngIf="textFormat === 'short'">J</span>
            </div>
        </div>
        <div class="row withoutMargin" *ngIf="format.indexOf('hour') >= 0">
            <div class="col-12 text-left firstCol lastCol">
                <input (change)="durationValueChange()" type="number" max="23" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.hour">
                <span class="spanText" *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}heures</span>
                <span class="spanText" *ngIf="textFormat === 'short'">H</span>
            </div>
        </div>
        <div class="row withoutMargin" *ngIf="format.indexOf('minute') >= 0">
            <div class="col-12 text-left firstCol lastCol">
                <input (change)="durationValueChange()" type="number" max="60" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.min">
                <span class="spanText" *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}minutes</span>
                <span class="spanText" *ngIf="textFormat === 'short'">min</span>
            </div>
        </div>
        <div class="row withoutMargin" *ngIf="format.indexOf('second') >= 0">
            <div class="col-12 text-left firstCol lastCol">
                <input (change)="durationValueChange()" type="number" max="60" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.sec">
                <span class="spanText" *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}secondes</span>
                <span class="spanText" *ngIf="textFormat === 'short'">sec</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="display === 'landscape'">
        <div class="row withoutMargin landscape">
            <div class="col-12 firstCol lastCol">
                <span class="valueContainer" *ngIf="format.indexOf('day') >= 0">
                    <input (change)="durationValueChange()" type="number" max="999" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.day">
                    <span *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}jours</span>
                    <span *ngIf="textFormat === 'short'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}J</span>
                </span>
                <span class="valueContainer" *ngIf="format.indexOf('hour') >= 0">
                    <input (change)="durationValueChange()" type="number" max="23" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.hour">
                    <span *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}heures</span>
                    <span *ngIf="textFormat === 'short'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}H</span>
                </span>
                <span class="valueContainer" *ngIf="format.indexOf('minute') >= 0">
                    <input (change)="durationValueChange()" type="number" max="60" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.min">
                    <span *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}minutes</span>
                    <span *ngIf="textFormat === 'short'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}min</span>
                </span>
                <span class="valueContainer" *ngIf="format.indexOf('second') >= 0">
                    <input (change)="durationValueChange()" type="number" max="60" min="0" class="small" nbInput placeholder="-" [(ngModel)]="splittedDuration.sec">
                    <span *ngIf="textFormat === 'full'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}secondes</span>
                    <span *ngIf="textFormat === 'short'">{{ addText && addText.length > 0 ? addText + ' ' : ''}}sec</span>
                </span>
            </div>
        </div>
    </ng-container>
</div>
