import * as ms from 'ms'
import { timeDisplayConfig, timeDisplayConfigCondition } from '../../services/utils/time-config.service'

const bookedTicket0 = {
    "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain\" \"Vous êtes les prochains\"}}",
    "en_US" : "{{groupSize : \"1\"|\"You are the next one\" \"You are the next ones\"}}",
    "es_ES" : "{{groupSize : \"1\"|\"Tú eres el siguiente.\" \"Tú eres el siguiente.\"}}",
    "it_IT" : "{{groupSize : \"1\"|\"Tu sei il prossimo.\" \"Tu sei il prossimo.\"}}",
    "pt_PT" : "{{groupSize : \"1\"|\"Você é o próximo\" \"Você é o próximo\"}}",
    "de_DE" : "{{groupSize : \"1\"|\"Du bist der nächste.\" \"Du bist der nächste.\"}}",
    "zh_CN" : "{{groupSize : \"1\"|\"你是下一个\" \"你是下一个\"}}",
    "tr_TR" : "{{groupSize : \"1\"|\"Sırada sen varsın.\" \"Sırada sen varsın.\"}}",
    "nl_NL" : "{{groupSize : \"1\"|\"U bent de volgende\" \"U bent de volgende\"}}",
    "ja_JP" : "{{groupSize : \"1\"|\"あなたは以下の通りです\" \"あなたは以下の通りです\"}}",
    "zh_TW" : "{{groupSize : \"1\"|\"你是下一個\" \"你是下一個\"}}",
    "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
    "th_TH" : "{{groupSize : \"1\"|\"คุณคือ\" \"คุณคือ\"}}"
}
const bookedTicket1 = {
    "fr_FR" : "Bientôt votre tour, plus que...\n{{pos}}\npersonne avant vous",
    "en_US" : "You're almost there !\n{{pos}}\nperson before you",
    "nl_NL" : "Bijna uw beurt, nog slechts…\n{{pos}}\npersoon voor u",
    "ja_JP" : "ほとんどあなたの番、ちょうど..\n{{pos}}\n人待ち",
    "ko_KR" : "거의 다 되었습니다 !\n{{pos}}\n명이 앞에 있습니다",
    "zh_CN" : "即将为你办理退税服务，仅剩\n{{pos}}\n您前面还有人",
    "zh_TW" : "即将为你办理退税服务，仅剩\n{{pos}}\n在你之前的人",
    "th_TH" : "ใกล้จะถึงแล้ว !\n{{pos}}\nคนก่อนหน้าคุณ",
    "es_ES" : "Se acerca su turno; solo...\n{{pos}}\npersona antes de usted",
    "it_IT" : "Presto sarà il tuo turno, solo fra...\n{{pos}}\npersona prima di",
    "de_DE" : "Sie sind bald an der Reihe. Vor Ihnen ist/sind nur noch...\n{{pos}}\nPerson vor",
    "tr_TR" : "yakında sıra sizde, sadece …\n{{pos}}\nÖnünde biri var.",
    "pt_PT" : "Em breve a sua vez, mais do que ...\n{{pos}}\npessoa antes de você"
}
const bookedTicket2 = {
    "fr_FR" : "Bientôt votre tour, plus que...\n{{pos}}\npersonnes avant vous",
    "en_US" : "You're almost there !\n{{pos}}\npeople before you",
    "nl_NL" : "Bijna uw beurt, nog slechts…\n{{pos}}\npersonen voor u",
    "ja_JP" : "ほとんどあなたの番、ちょうど..\n{{pos}}\n人待ち",
    "ko_KR" : "거의 다 되었습니다 !\n{{pos}}\n명이 앞에 있습니다",
    "zh_CN" : "即将为你办理退税服务，仅剩\n{{pos}}\n您前面还有人",
    "zh_TW" : "即将为你办理退税服务，仅剩\n{{pos}}\n在你之前的人",
    "th_TH" : "ใกล้จะถึงแล้ว !\n{{pos}}\nคนก่อนหน้าคุณ",
    "es_ES" : "Se acerca su turno; solo...\n{{pos}}\npersona antes de usted",
    "it_IT" : "Presto sarà il tuo turno, solo fra...\n{{pos}}\npersona prima di",
    "de_DE" : "Sie sind bald an der Reihe. Vor Ihnen ist/sind nur noch...\n{{pos}}\nPerson vor",
    "tr_TR" : "yakında sıra sizde, sadece …\n{{pos}}\nÖnünde biri var.",
    "pt_PT" : "Em breve a sua vez, mais do que ...\n{{pos}}\npessoa antes de você"
}
const bookedTicketDateToday = {
    "fr_FR" : "Prochain passage estimé à {{date}}",
    "en_US" : "Next turn estimated at {{date}}",
    "es_ES" : "Proximo pase aproximadamente en {{date}}",
    "it_IT" : "Prossimo ingresso tra {{date}}",
    "de_DE" : "Nächster Einlass in {{date}}",
    "zh_CN" : "下次通过时间为 {{date}}",
    "tr_TR" : "Next turn estimated at {{date}}",
    "nl_NL" : "Next turn estimated at {{date}}",
    "ja_JP" : "Next turn estimated at {{date}}",
    "zh_TW" : "Next turn estimated at {{date}}",
    "ko_KR" : "Next turn estimated at {{date}}",
    "th_TH" : "Next turn estimated at {{date}}"
}
const bookedTicketDateTomorrow = {
    "fr_FR" : "Prochain passage estimé le {{date}}",
    "en_US" : "Next turn estimated the {{date}}",
    "es_ES" : "Proximo pase aproximadamente en {{date}}",
    "it_IT" : "Prossimo ingresso tra {{date}}",
    "de_DE" : "Nächster Einlass in {{date}}",
    "zh_CN" : "下次通过时间为 {{date}}",
    "tr_TR" : "Next turn estimated the {{date}}",
    "nl_NL" : "Next turn estimated the {{date}}",
    "ja_JP" : "Next turn estimated the {{date}}",
    "zh_TW" : "Next turn estimated the {{date}}",
    "ko_KR" : "Next turn estimated the {{date}}",
    "th_TH" : "Next turn estimated the {{date}}"
}
const bookedTicketTimeLeft = {
    "fr_FR" : "Prochain passage estimé dans {{date}}",
    "en_US" : "Next turn estimated in {{date}}",
    "es_ES" : "Proximo pase aproximadamente dentro {{date}}",
    "it_IT" : "Prossimo ingresso in {{date}}",
    "de_DE" : "Nächster Einlass im {{date}}",
    "zh_CN" : "下次通过时间为 {{date}}",
    "tr_TR" : "Next turn estimated in {{date}}",
    "nl_NL" : "Next turn estimated in {{date}}",
    "ja_JP" : "Next turn estimated in {{date}}",
    "zh_TW" : "Next turn estimated in {{date}}",
    "ko_KR" : "Next turn estimated in {{date}}",
    "th_TH" : "Next turn estimated in {{date}}"
}

const calledAndnoShowTickets = {
    "fr_FR" : "C'est votre tour, rendez-vous...\n{{ ticket.endpoint.name }}",
    "en_US" : "It's your turn! Please go to\n{{ ticket.endpoint.name }}",
    "it_IT" : "È il tuo turno, vai a...\n{{ ticket.endpoint.name }}",
    "es_ES" : "Es su turno, pase...\n{{ ticket.endpoint.name }}",
    "de_DE" : "Sie sind an der Reihe! Bitte gehen Sie zu/zur/zum...\n{{ ticket.endpoint.name }}",
    "zh_CN" : "现在为您办理退税\n{{ ticket.endpoint.name }}",
    "zh_TW/zh_Hant" : "輪到您了，您的預約\n{{ ticket.endpoint.name }}",
    "ko_KR" : "차례가 되었습니다! 이동할 장소\n{{ ticket.endpoint.name }}",
    "th_TH" : "ถึงลำดับของคุณแล้ว\n{{ ticket.endpoint.name }}",
    "ja_JP" : "それはあなたの番です.によって取得します。\n{{ ticket.endpoint.name }}",
    "tr_TR" : "sıra sizde, … geçiniz\n{{ ticket.endpoint.name }}",
    "nl_NL" : "Het is jouw beurt, ... krijgen door\n{{ ticket.endpoint.name }}",
    "zh_TW" : "輪到您了，您的預約\n{{ ticket.endpoint.name }}",
    "pt_PT" : "É sua vez! Por favor vá a\n{{ ticket.endpoint.name }}"
}
const inProgressTickets = {
    "fr_FR" : "Passage en cours",
    "en_US" : "In progress",
    "it_IT" : "Ingresso in corso",
    "es_ES" : "Pase en curso",
    "de_DE" : "Einlass läuft",
    "zh_CN" : "正在通过",
    "zh_TW/zh_Hant" : "進行中",
    "ko_KR" : "진행 중",
    "th_TH" : "กำลังดำเนินการ",
    "ja_JP" : "移行が続行される",
    "tr_TR" : "geçiş devam ediyor",
    "nl_NL" : "migratie blijft",
    "zh_TW" : "進行中",
    "pt_PT" : "Passagem em andamento!"
}
const doneTickets = {
    "fr_FR" : "Merci d'avoir utilisé notre service",
    "en_US" : "Thank you for using our service",
    "zh_CN" : "感谢使用我们的服务",
    "nl_NL" : "Bedankt om onze service te gebruiken",
    "tr_TR" : "hizmetimizi kullandığınız için teşekkürler",
    "zh_TW/zh_Hant" : "謝謝您",
    "ja_JP" : "ありがとうございました",
    "ko_KR" : "고맙습니다",
    "th_TH" : "ขอขอบคุณ",
    "es_ES" : "Gracias por usar nuestro servicio",
    "it_IT" : "Grazie per aver utilizzato il nostro servizio",
    "de_DE" : "Vielen Dank für die Nutzung unseres Service",
    "zh_TW" : "謝謝您",
    "pt_PT" : "Obrigado por usar o nosso serviço"
}
const endStateTickets: timeDisplayConfigCondition[] =[
    {
        type: 'DATE',
        state: 700,
        texts: doneTickets
    }, {
        type: 'DATE',
        state: 500,
        texts: inProgressTickets
    }, {
        type: 'DATE',
        state: 400,
        texts: calledAndnoShowTickets
    }
]

export const timeDisplayConfigs: { [configName: string]: timeDisplayConfig } = {
    configDate_firstAvailability: {
        cond: [{
            type: 'DATE',
            time: '#actual_fullDate',
            texts: {
                "fr_FR" : "Prochain passage estimé à {{date}}",
                "en_US" : "Next turn estimated at {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated at {{date}}",
                "nl_NL" : "Next turn estimated at {{date}}",
                "ja_JP" : "Next turn estimated at {{date}}",
                "zh_TW" : "Next turn estimated at {{date}}",
                "ko_KR" : "Next turn estimated at {{date}}",
                "th_TH" : "Next turn estimated at {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: {
                "fr_FR" : "Prochain passage estimé le {{date}}",
                "en_US" : "Next turn estimated the {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated the {{date}}",
                "nl_NL" : "Next turn estimated the {{date}}",
                "ja_JP" : "Next turn estimated the {{date}}",
                "zh_TW" : "Next turn estimated the {{date}}",
                "ko_KR" : "Next turn estimated the {{date}}",
                "th_TH" : "Next turn estimated the {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDate_viewSwitch: {
        cond: [{
            type: 'DATE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configDate_ticket: {
        cond: [ ...endStateTickets, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        } ],
        displayText: '',
        texts: {}
    },
    configDate_ticketOnlyBooked: {
        cond: [ {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        } ],
        displayText: '',
        texts: {}
    },
    configDate_availabilities: {
        cond: [{
            type: 'DATE',
            texts: {},
            dateConfig: {
                "format" : "HH:mm",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeft_firstAvailability: {
        cond: [{
            type: 'TIME_LEFT',
            texts: {
                "fr_FR" : "Prochain passage estimé dans {{date}}",
                "en_US" : "Next turn estimated in {{date}}",
                "es_ES" : "Proximo pase aproximadamente dentro {{date}}",
                "it_IT" : "Prossimo ingresso in {{date}}",
                "de_DE" : "Nächster Einlass im {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated in {{date}}",
                "nl_NL" : "Next turn estimated in {{date}}",
                "ja_JP" : "Next turn estimated in {{date}}",
                "zh_TW" : "Next turn estimated in {{date}}",
                "ko_KR" : "Next turn estimated in {{date}}",
                "th_TH" : "Next turn estimated in {{date}}"
            },
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeft_viewSwitch: {
        cond: [{
            type: 'TIME_LEFT',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeft_ticket: {
        cond: [ ...endStateTickets, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeft_ticketOnlyBooked: {
        cond: [ {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeft_availabilities: {
        cond: [{
            type: 'TIME_LEFT',
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalPeople_firstAvailability: {
        cond: [{
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} personnes dans la file.",
                "en_US" : "Currently, there are {{pos}} people in the line.",
                "es_ES" : "Actualmente hay {{pos}} gente en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} persone in fila.",
                "de_DE" : "Derzeit sind {{pos}} Leute in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} iemand in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalPeople_viewSwitch: {
        cond: [{
            type: 'NB_GLOBAL_PEOPLE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalPeople_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalPeople_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalTickets_firstAvailability: {
        cond: [{
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_GLOBAL_TICKET',
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} tickets dans la file.",
                "en_US" : "Currently, there are {{pos}} tickets in the line.",
                "es_ES" : "Actualmente hay {{pos}} entradas en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} biglietti in fila.",
                "de_DE" : "Derzeit sind {{pos}} fahrkarte in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} ticket in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "ko_KR" : "누군가가 현재 순서에 있습니다.",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalTickets_viewSwitch: {
        cond: [{
            type: 'NB_GLOBAL_TICKET',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalTickets_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_TICKET',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configNbGlobalTickets_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_TICKET',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointPeople_firstAvailability: {
        cond: [{
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} personnes dans la file.",
                "en_US" : "Currently, there are {{pos}} people in the line.",
                "es_ES" : "Actualmente hay {{pos}} gente en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} persone in fila.",
                "de_DE" : "Derzeit sind {{pos}} Leute in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} iemand in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointPeople_viewSwitch: {
        cond: [{
            type: 'NB_ENDPOINT_PEOPLE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointPeople_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointPeople_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointTickets_firstAvailability: {
        cond: [{
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_TICKET',
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} tickets dans la file.",
                "en_US" : "Currently, there are {{pos}} tickets in the line.",
                "es_ES" : "Actualmente hay {{pos}} entradas en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} biglietti in fila.",
                "de_DE" : "Derzeit sind {{pos}} fahrkarte in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} ticket in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "ko_KR" : "누군가가 현재 순서에 있습니다.",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointTickets_viewSwitch: {
        cond: [{
            type: 'NB_ENDPOINT_TICKET',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointTickets_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_TICKET',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configNbEndpointTickets_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_TICKET',
            texts: bookedTicket2
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalPeople_firstAvailability: {
        cond: [{
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} personnes dans la file.",
                "en_US" : "Currently, there are {{pos}} people in the line.",
                "es_ES" : "Actualmente hay {{pos}} gente en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} persone in fila.",
                "de_DE" : "Derzeit sind {{pos}} Leute in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} iemand in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'TIME_LEFT',
            texts: {
                "fr_FR" : "Prochain passage estimé dans {{date}}",
                "en_US" : "Next turn estimated in {{date}}",
                "es_ES" : "Proximo pase aproximadamente dentro {{date}}",
                "it_IT" : "Prossimo ingresso in {{date}}",
                "de_DE" : "Nächster Einlass im {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated in {{date}}",
                "nl_NL" : "Next turn estimated in {{date}}",
                "ja_JP" : "Next turn estimated in {{date}}",
                "zh_TW" : "Next turn estimated in {{date}}",
                "ko_KR" : "Next turn estimated in {{date}}",
                "th_TH" : "Next turn estimated in {{date}}"
            },
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalPeople_viewSwitch: {
        cond: [{
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: {}
        }, {
            type: 'TIME_LEFT',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalPeople_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalPeople_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalTickets_firstAvailability: {
        cond: [{
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} tickets dans la file.",
                "en_US" : "Currently, there are {{pos}} tickets in the line.",
                "es_ES" : "Actualmente hay {{pos}} entradas en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} biglietti in fila.",
                "de_DE" : "Derzeit sind {{pos}} fahrkarte in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} ticket in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "ko_KR" : "누군가가 현재 순서에 있습니다.",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'TIME_LEFT',
            texts: {
                "fr_FR" : "Prochain passage estimé dans {{date}}",
                "en_US" : "Next turn estimated in {{date}}",
                "es_ES" : "Proximo pase aproximadamente dentro {{date}}",
                "it_IT" : "Prossimo ingresso in {{date}}",
                "de_DE" : "Nächster Einlass im {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated in {{date}}",
                "nl_NL" : "Next turn estimated in {{date}}",
                "ja_JP" : "Next turn estimated in {{date}}",
                "zh_TW" : "Next turn estimated in {{date}}",
                "ko_KR" : "Next turn estimated in {{date}}",
                "th_TH" : "Next turn estimated in {{date}}"
            },
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalTickets_viewSwitch: {
        cond: [{
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: {}
        }, {
            type: 'TIME_LEFT',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalTickets_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbGlobalTickets_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointPeople_firstAvailability: {
        cond: [{
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} personnes dans la file.",
                "en_US" : "Currently, there are {{pos}} people in the line.",
                "es_ES" : "Actualmente hay {{pos}} gente en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} persone in fila.",
                "de_DE" : "Derzeit sind {{pos}} Leute in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} iemand in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'TIME_LEFT',
            texts: {
                "fr_FR" : "Prochain passage estimé dans {{date}}",
                "en_US" : "Next turn estimated in {{date}}",
                "es_ES" : "Proximo pase aproximadamente dentro {{date}}",
                "it_IT" : "Prossimo ingresso in {{date}}",
                "de_DE" : "Nächster Einlass im {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated in {{date}}",
                "nl_NL" : "Next turn estimated in {{date}}",
                "ja_JP" : "Next turn estimated in {{date}}",
                "zh_TW" : "Next turn estimated in {{date}}",
                "ko_KR" : "Next turn estimated in {{date}}",
                "th_TH" : "Next turn estimated in {{date}}"
            },
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointPeople_viewSwitch: {
        cond: [{
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: {}
        }, {
            type: 'TIME_LEFT',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointPeople_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointPeople_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointTickets_firstAvailability: {
        cond: [{
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} tickets dans la file.",
                "en_US" : "Currently, there are {{pos}} tickets in the line.",
                "es_ES" : "Actualmente hay {{pos}} entradas en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} biglietti in fila.",
                "de_DE" : "Derzeit sind {{pos}} fahrkarte in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} ticket in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "ko_KR" : "누군가가 현재 순서에 있습니다.",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'TIME_LEFT',
            texts: {
                "fr_FR" : "Prochain passage estimé dans {{date}}",
                "en_US" : "Next turn estimated in {{date}}",
                "es_ES" : "Proximo pase aproximadamente dentro {{date}}",
                "it_IT" : "Prossimo ingresso in {{date}}",
                "de_DE" : "Nächster Einlass im {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated in {{date}}",
                "nl_NL" : "Next turn estimated in {{date}}",
                "ja_JP" : "Next turn estimated in {{date}}",
                "zh_TW" : "Next turn estimated in {{date}}",
                "ko_KR" : "Next turn estimated in {{date}}",
                "th_TH" : "Next turn estimated in {{date}}"
            },
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointTickets_viewSwitch: {
        cond: [{
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: {}
        }, {
            type: 'TIME_LEFT',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointTickets_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configTimeLeftAndNbEndpointTickets_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'TIME_LEFT',
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndTimeLeft_firstAvailability: {
        cond: [{
            type: 'TIME_LEFT',
            time: ms('1h'),
            texts: {
                "fr_FR" : "Prochain passage estimé dans {{date}}",
                "en_US" : "Next turn estimated in {{date}}",
                "es_ES" : "Proximo pase aproximadamente dentro {{date}}",
                "it_IT" : "Prossimo ingresso in {{date}}",
                "de_DE" : "Nächster Einlass im {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated in {{date}}",
                "nl_NL" : "Next turn estimated in {{date}}",
                "ja_JP" : "Next turn estimated in {{date}}",
                "zh_TW" : "Next turn estimated in {{date}}",
                "ko_KR" : "Next turn estimated in {{date}}",
                "th_TH" : "Next turn estimated in {{date}}"
            },
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: {
                "fr_FR" : "Prochain passage estimé à {{date}}",
                "en_US" : "Next turn estimated at {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated at {{date}}",
                "nl_NL" : "Next turn estimated at {{date}}",
                "ja_JP" : "Next turn estimated at {{date}}",
                "zh_TW" : "Next turn estimated at {{date}}",
                "ko_KR" : "Next turn estimated at {{date}}",
                "th_TH" : "Next turn estimated at {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: {
                "fr_FR" : "Prochain passage estimé le {{date}}",
                "en_US" : "Next turn estimated the {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated the {{date}}",
                "nl_NL" : "Next turn estimated the {{date}}",
                "ja_JP" : "Next turn estimated the {{date}}",
                "zh_TW" : "Next turn estimated the {{date}}",
                "ko_KR" : "Next turn estimated the {{date}}",
                "th_TH" : "Next turn estimated the {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndTimeLeft_viewSwitch: {
        cond: [{
            type: 'TIME_LEFT',
            time: ms('1h'),
            texts: {}
        }, {
            type: 'DATE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configDateAndTimeLeft_ticket: {
        cond: [ ...endStateTickets, {
            type: 'TIME_LEFT',
            time: ms('1h'),
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndTimeLeft_ticketOnlyBooked: {
        cond: [ {
            type: 'TIME_LEFT',
            time: ms('1h'),
            texts: bookedTicketTimeLeft,
            dateConfig: {
                "format" : "TIME_LEFT",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalPeople_firstAvailability: {
        cond: [{
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} personnes dans la file.",
                "en_US" : "Currently, there are {{pos}} people in the line.",
                "es_ES" : "Actualmente hay {{pos}} gente en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} persone in fila.",
                "de_DE" : "Derzeit sind {{pos}} Leute in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} iemand in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: {
                "fr_FR" : "Prochain passage estimé à {{date}}",
                "en_US" : "Next turn estimated at {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated at {{date}}",
                "nl_NL" : "Next turn estimated at {{date}}",
                "ja_JP" : "Next turn estimated at {{date}}",
                "zh_TW" : "Next turn estimated at {{date}}",
                "ko_KR" : "Next turn estimated at {{date}}",
                "th_TH" : "Next turn estimated at {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: {
                "fr_FR" : "Prochain passage estimé le {{date}}",
                "en_US" : "Next turn estimated the {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated the {{date}}",
                "nl_NL" : "Next turn estimated the {{date}}",
                "ja_JP" : "Next turn estimated the {{date}}",
                "zh_TW" : "Next turn estimated the {{date}}",
                "ko_KR" : "Next turn estimated the {{date}}",
                "th_TH" : "Next turn estimated the {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalPeople_viewSwitch: {
        cond: [{
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: {}
        }, {
            type: 'DATE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalPeople_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalPeople_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_PEOPLE',
            nbGlobalPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalTickets_firstAvailability: {
        cond: [{
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} tickets dans la file.",
                "en_US" : "Currently, there are {{pos}} tickets in the line.",
                "es_ES" : "Actualmente hay {{pos}} entradas en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} biglietti in fila.",
                "de_DE" : "Derzeit sind {{pos}} fahrkarte in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} ticket in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "ko_KR" : "누군가가 현재 순서에 있습니다.",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: {
                "fr_FR" : "Prochain passage estimé à {{date}}",
                "en_US" : "Next turn estimated at {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated at {{date}}",
                "nl_NL" : "Next turn estimated at {{date}}",
                "ja_JP" : "Next turn estimated at {{date}}",
                "zh_TW" : "Next turn estimated at {{date}}",
                "ko_KR" : "Next turn estimated at {{date}}",
                "th_TH" : "Next turn estimated at {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: {
                "fr_FR" : "Prochain passage estimé le {{date}}",
                "en_US" : "Next turn estimated the {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated the {{date}}",
                "nl_NL" : "Next turn estimated the {{date}}",
                "ja_JP" : "Next turn estimated the {{date}}",
                "zh_TW" : "Next turn estimated the {{date}}",
                "ko_KR" : "Next turn estimated the {{date}}",
                "th_TH" : "Next turn estimated the {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalTickets_viewSwitch: {
        cond: [{
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: {}
        }, {
            type: 'DATE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalTickets_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbGlobalTickets_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_GLOBAL_TICKET',
            nbGlobalTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointPeople_firstAvailability: {
        cond: [{
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} personnes dans la file.",
                "en_US" : "Currently, there are {{pos}} people in the line.",
                "es_ES" : "Actualmente hay {{pos}} gente en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} persone in fila.",
                "de_DE" : "Derzeit sind {{pos}} Leute in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} iemand in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: {
                "fr_FR" : "Prochain passage estimé à {{date}}",
                "en_US" : "Next turn estimated at {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated at {{date}}",
                "nl_NL" : "Next turn estimated at {{date}}",
                "ja_JP" : "Next turn estimated at {{date}}",
                "zh_TW" : "Next turn estimated at {{date}}",
                "ko_KR" : "Next turn estimated at {{date}}",
                "th_TH" : "Next turn estimated at {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: {
                "fr_FR" : "Prochain passage estimé le {{date}}",
                "en_US" : "Next turn estimated the {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated the {{date}}",
                "nl_NL" : "Next turn estimated the {{date}}",
                "ja_JP" : "Next turn estimated the {{date}}",
                "zh_TW" : "Next turn estimated the {{date}}",
                "ko_KR" : "Next turn estimated the {{date}}",
                "th_TH" : "Next turn estimated the {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointPeople_viewSwitch: {
        cond: [{
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: {}
        }, {
            type: 'DATE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointPeople_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointPeople_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_PEOPLE',
            nbEndpointPeople: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointTickets_firstAvailability: {
        cond: [{
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le premier dans la file d'attente.\" \"Vous êtes les premiers\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the first one in the virtual line\" \"You are the first one in the virtual line\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el primero en la cola.\" \"Eres el primero en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il primo in fila.\" \"Vous êtes les premiers\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Du bist der Erste in der Reihe.\" \"Du bist der Erste in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是第一个排队的人。\" \"你是第一个排队的人。\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıraya gelen ilk kişi sin.\" \"Sıraya gelen ilk kişi sin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de eerste die in de rij staat.\" \"Jij bent de eerste die in de rij staat.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは列に並んでいる最初の人です。\" \"あなたは列に並んでいる最初の人です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 라인의 첫 번째입니다.\" \"당신은 라인의 첫 번째입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณเป็นคนแรกในสาย\" \"คุณเป็นคนแรกในสาย\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: {
                "fr_FR" : "{{groupSize : \"1\"|\"Vous êtes le prochain dans la file d'attente.\" \"Vous êtes les prochains dans la file d'attente.\"}}",
                "en_US" : "{{groupSize : \"1\"|\"You are the next one in the line.\" \"You are the next one in the line.\"}}",
                "es_ES" : "{{groupSize : \"1\"|\"Eres el siguiente en la cola.\" \"Eres el siguiente en la cola.\"}}",
                "it_IT" : "{{groupSize : \"1\"|\"Sei il prossimo in fila.\" \"Sei il prossimo in fila.\"}}",
                "de_DE" : "{{groupSize : \"1\"|\"Sie stehen als nächstes in der Reihe.\" \"Sie stehen als nächstes in der Reihe.\"}}",
                "zh_CN" : "{{groupSize : \"1\"|\"你是下一个排队的人\" \"你是下一个排队的人\"}}",
                "tr_TR" : "{{groupSize : \"1\"|\"Sıradaki kişi sensin.\" \"Sıradaki kişi sensin.\"}}",
                "nl_NL" : "{{groupSize : \"1\"|\"Jij bent de volgende.\" \"Jij bent de volgende.\"}}",
                "ja_JP" : "{{groupSize : \"1\"|\"あなたは次です。\" \"あなたは次です。\"}}",
                "zh_TW" : "{{groupSize : \"1\"|\"您是隊列中的第一個。\" \"您是隊列中的第一個。\"}}",
                "ko_KR" : "{{groupSize : \"1\"|\"당신은 다음입니다.\" \"당신은 다음입니다.\"}}",
                "th_TH" : "{{groupSize : \"1\"|\"คุณต่อไป\" \"คุณต่อไป\"}}"
            }
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: {
                "fr_FR" : "Il y a actuellement {{pos}} tickets dans la file.",
                "en_US" : "Currently, there are {{pos}} tickets in the line.",
                "es_ES" : "Actualmente hay {{pos}} entradas en la fila.",
                "it_IT" : "Attualmente ci sono {{pos}} biglietti in fila.",
                "de_DE" : "Derzeit sind {{pos}} fahrkarte in der Schlange.",
                "zh_CN" : "当前队列中有人。",
                "tr_TR" : "Geçerli sırada biri var.",
                "nl_NL" : "Er is {{pos}} ticket in de huidige volgorde.",
                "ja_JP" : "現在の順序で誰かがいます。",
                "zh_TW" : "按當前順序有人。",
                "ko_KR" : "누군가가 현재 순서에 있습니다.",
                "th_TH" : "มีใครบางคนอยู่ในลําดับปัจจุบัน"
            }
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: {
                "fr_FR" : "Prochain passage estimé à {{date}}",
                "en_US" : "Next turn estimated at {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated at {{date}}",
                "nl_NL" : "Next turn estimated at {{date}}",
                "ja_JP" : "Next turn estimated at {{date}}",
                "zh_TW" : "Next turn estimated at {{date}}",
                "ko_KR" : "Next turn estimated at {{date}}",
                "th_TH" : "Next turn estimated at {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: {
                "fr_FR" : "Prochain passage estimé le {{date}}",
                "en_US" : "Next turn estimated the {{date}}",
                "es_ES" : "Proximo pase aproximadamente en {{date}}",
                "it_IT" : "Prossimo ingresso tra {{date}}",
                "de_DE" : "Nächster Einlass in {{date}}",
                "zh_CN" : "下次通过时间为 {{date}}",
                "tr_TR" : "Next turn estimated the {{date}}",
                "nl_NL" : "Next turn estimated the {{date}}",
                "ja_JP" : "Next turn estimated the {{date}}",
                "zh_TW" : "Next turn estimated the {{date}}",
                "ko_KR" : "Next turn estimated the {{date}}",
                "th_TH" : "Next turn estimated the {{date}}"
            },
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointTickets_viewSwitch: {
        cond: [{
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: {}
        }, {
            type: 'DATE',
            texts: {}
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointTickets_ticket: {
        cond: [ ...endStateTickets, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
    configDateAndNbEndpointTickets_ticketOnlyBooked: {
        cond: [ {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 0,
            texts: bookedTicket0
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 1,
            texts: bookedTicket1
        }, {
            type: 'NB_ENDPOINT_TICKET',
            nbEndpointTickets: 10,
            texts: bookedTicket2
        }, {
            type: 'DATE',
            time: '#actual_fullDate',
            texts: bookedTicketDateToday,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }, {
            type: 'DATE',
            texts: bookedTicketDateTomorrow,
            dateConfig: {
                "format" : "AUTO_COMPLETE",
                "textFormat" : "full",
                "round" : null,
                "intervalConfig" : null
            }
        }],
        displayText: '',
        texts: {}
    },
}


