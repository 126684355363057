import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public isDefined(value : any) : boolean{
    return (value !== undefined && value !== null)
  }

  public isDefinedAndNotEmpty(value : any[]) : boolean{
    return (this.isDefined(value) && value.length > 0)
  }
}
