import { CompaniesPlacesQueuesService } from '../services/default-resources/companies-places-queues.service'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'

import { CompaniesPlacesAndQueues } from '../interfaces/companiesPlacesAndQueues/companiesPlacesAndQueues'

@Injectable()
export class CompaniesPlacesQueuesResolver  {
    constructor ( private companiesPlacesQueuesService: CompaniesPlacesQueuesService ) {
    }

    resolve = (): Observable<CompaniesPlacesAndQueues> => this.companiesPlacesQueuesService.getCompaniesPlacesAndQueues()
}
