<nb-card class="list-card">
    <nb-card-header class="text-center">
        {{ title ? title : 'Choisissez une valeur' }}
        <div class="selectSearch">
            <input type="text" nbInput fullWidth placeholder="Rechercher dans la liste" (keyup)="filter = $event.target.value">
        </div>
    </nb-card-header>
    <nb-card-body>
        <nb-list *ngIf="selected && selected.length >= 0" class="action-list">
            <nb-list-item class="text-left" *ngFor="let possibility of possibilities" [ngClass]="{selected: isSelected( possibility.value ), hidden: (filter && filter !== '' && !match(possibility) )}" (click)="select( possibility.value )">
                <span *ngIf="(possibility.langs | lbTypeOf) === 'object'">{{ possibility.langs | lbTranslateFromObject:{lang: locale.language} | lbCapitalize }}</span>
                <span *ngIf="(possibility.langs | lbTypeOf) === 'string'">{{ possibility.langs }}</span>
                <span *ngIf="(possibility.name | lbTypeOf) === 'object'">{{ possibility.name | lbTranslateFromObject:{lang: locale.language} | lbCapitalize }}</span>
                <span *ngIf="(possibility.name | lbTypeOf) === 'string'">{{ possibility.name }}</span>
            </nb-list-item>
        </nb-list>
    </nb-card-body>
    <nb-card-footer>
        <button class="cancel align-center" nbButton status="danger" (click)="cancel()">Annuler</button>
        <button nbButton status="success" (click)="submit()">Valider</button>
    </nb-card-footer>
</nb-card>
