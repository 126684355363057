import { Pipe, PipeTransform } from '@angular/core'
import { Endpoint } from 'lb-types/dist'
import { EndpointsService } from '../services/default-resources/endpoints.service'
import { LbTranslationService } from 'lb-utils-front/dist'

@Pipe({
  name: 'endpointNameFromId'
})
export class EndpointNameFromIdPipe implements PipeTransform {

  constructor(
      private endpointsService: EndpointsService,
      private lbTranslationService: LbTranslationService
  ) {}

  transform(endpointId: string, queueId: string, lang?: string): any {
    const endpoint: Endpoint | null = this.endpointsService.getEndpointById( queueId, endpointId )
    if ( endpoint ) {
      return this.lbTranslationService.translateFromObject( endpoint.name, {lang: lang} )
    } else {
      return '-'
    }
  }

}
